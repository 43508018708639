<template>
  <div class="container mt-1 mb-5" v-if="item">
    <b-alert v-model="showNewAssignAlert" variant="success" dismissible>
      <strong>A new assigned location was automatically created for you!</strong>
      <span class="d-block mt-2">
      Want to make more observations at this location for repeat monitoring? Instead of recreating it, please note:
    </span>
      <ul class="mb-0">
        <li>You can save time by selecting this location from the 'Select Location' dropdown on datasheets that offer
          location selection
        </li>
        <li>You may view your
          <b-link v-if="item && item.project"
                  :to="{name: 'ProjectAssignedLocations', params:{id:item.project.urlField}}"><u>assigned locations</u>
          </b-link>
          for this project at anytime
        </li>
      </ul>
    </b-alert>
    <div class="observation-details-header flex-shrink-1 d-flex flex-wrap bg-white px-3 py-2">
      <h2 class="ml-0 mt-0 mb-0">Observation Details</h2>
      <div class="text-right float-right">
        <div class="btn-group">
          <div class="mr-2 mt-2">
            <router-link
              v-if="item && item.project"
              :to="{name:'ProjectShow', params:{id:item.project.urlField}}"
            >
              <i class="fa fa-arrow-right mr-1"></i>To Project
            </router-link>
          </div>
          <div class="ml-2">
            <b-button
              class="btn btn-primary"
              id="CreateNewObservationButton"
              title="Add a new observation"
              alt="Add a new observation"
              v-if="item && item.project && isProjectRoleMember(item.project.id)"
              variant=""
              @click="clickAddDataLink"
              style="max-width:150px;"
            >
              <i class="fa fa-plus mr-1"/>
              <span class="label-text HideResponsive">
              New
            </span>
            </b-button>
          </div>

          <div class="ml-1">
            <router-link
              class="btn btn-warning"
              v-if="item && canEdit"
              alt="Edit this observation"
              title="Edit this observation"
              :to="{ name: 'ObservationEdit', params: { id: item.id} }"
            >
              <i class="fa fa-pencil" style="color:white;"></i>
              <span class="ml-1 label-text HideResponsive">Edit</span>
            </router-link>
          </div>

          <div class="ml-1 mr-3">
            <b-button
              v-if="canEdit"
              variant="danger"
              @click="onDeleteObservationClick($event)"
              alt="Delete this observation"
              title="Delete this observation"
            >
              <i class="fa fa-trash "></i>
              <span class="ml-1 label-text HideResponsive">Delete</span>
            </b-button>
          </div>

          <ShareNetwork
            v-if="item && item.project"
            class="btn btn-secondary icon-facebook rounded-left"
            network="facebook"
            tag="button"
            :url="currentUrl"
            title="CitSci.org - Observation Details "
            description=" Observation Details "
            quote=""
            facebbo-user="Citsci"
            hashtags="citsci,citizenscience"
            style="max-width:40px; max-height:40px;"
          >
            <font-awesome-icon :icon="['fab', 'facebook']" size="lg"/>
          </ShareNetwork>
          <ShareNetwork
            v-if="item && item.project"
            class="btn btn-secondary"
            network="twitter"
            tag="button"
            :url="currentUrl"
            :title="twitterProjectName"
            description=" Observation Details "
            style="max-width:40px;"
          >
          <font-awesome-icon :icon="['fab', 'x-twitter']" size="lg" />
          </ShareNetwork>
        </div>
      </div>
    </div>
    <b-container>
      <b-alert
        :show="error.length > 0"
        variant="danger"
      >
        {{ error }}
      </b-alert>
      <div class="my-0">
        <div>
          <b-row class="mx-0 my-0 mb-4">
            <b-col class="col-lg-7 col-sm-12">
              <b-row class="google-map-obs-details">
                <GmapMap v-if="locationData"
                         :imagePath="'/assets/images/m'"
                         ref="mapRef"
                         :center="{lat:locationData.latitude, lng:locationData.longitude}"
                         :zoom="11"
                         :options="mapOptions"
                         map-type-id="terrain"
                         style="height: 300px; margin-top:0px; padding-top:0px;">
                  <GmapMarker
                    :position="{lat:locationData.latitude, lng:locationData.longitude}"
                  >
                  </GmapMarker>
                </GmapMap>
              </b-row>
            </b-col>
            <b-col class="pr-0 col-lg-5 col-sm-12">
              <b-row>
                <div class="col-lg-12 col-12 pt-3" style="z-index: 1;">
                  <b-carousel
                    v-if="item && item.project"
                    id="carousel-1"
                    class="my-custom-carousel"
                    v-model="slide"
                    :interval="0"
                    controls
                    indicators
                    background="#ababab"
                    img-width="400"
                    img-height="300"
                    style="text-shadow: 1px 1px 2px #333; border: solid 1px grey; height:300px; margin-top:-14px; z-index: 1; overflow:hidden;"
                    @sliding-start="onSlideStart"
                    @sliding-end="onSlideEnd"
                  >
                    <b-carousel-slide
                      :class="'' + (img.current ? ' selected' : '')"
                      v-for="(img, index) in images"
                      :key="img.path + '-' + index"
                      :img-src="img.path"
                    ></b-carousel-slide>
                  </b-carousel>
                </div>
                <div style="z-index: 999; margin-top:-44px; margin-left:auto; margin-right:23px;">
                  <b-button
                    v-if="canEdit"
                    class="btn btn-primary mt-1"
                    @click="$refs.addPhotoChoose.click()"
                    alt="Add photos to this observation"
                    title="Add photos to this observation"
                  >
                    <input
                      v-if="canEdit"
                      class="d-none"
                      @change="onFileUploadChange"
                      accept="image/*"
                      type="file"
                      ref="addPhotoChoose"
                    />
                    <span class="btn-font-awesome mr-2">
                    <font-awesome-icon icon="plus-circle"/>
                    <i class="fa fa-1x fa-spin fa-spinner ml-1" v-if="isLoading || isLoadingLocationUpdate"></i>
                      <!--<VueLoader v-if="isLoading || isLoadingLocationUpdate" style="max-height:18px;" class="d-inline"/>-->
                  </span
                  ><span class="d-none d-md-inline">Add Photos</span>
                  </b-button>
                </div>
              </b-row>
            </b-col>
          </b-row>

          <div>
            <p class="p-down" style="line-height:0.5;">
              <b-row class="row mb-2" v-if="item && item.project">
                <b-col class="d-none d-md-table-cell col-md-4 col-lg-3 mb-2 ">
                  Project:
                </b-col>
                <b-col class="col-md-8 col-lg-9 mb-2 ">
                <span class="">
                  <router-link
                    :to="{name:'ProjectShow', params:{id:item.project.urlField}}"
                    class="clearBoth font-weight-normal "
                  >
                    {{ item.project.name }}
                  </router-link>
                </span>
                </b-col>
              </b-row>

              <b-row class="row mb-2" v-if="item && item.project">
                <b-col class="d-none d-md-table-cell col-md-4 col-lg-3 mb-2">
                  Location:
                </b-col>
                <b-col
                  class="col-md-8 col-lg-9 mb-2"
                  v-if="!editField.isLocationEdit"
                >
                  <router-link
                    :to="{name:'LocationShow', params:{id: item.location.id}}"
                    v-if="item && item.location && item.location.name"
                    class="clearBoth font-weight-normal "
                  >
                    {{ item && item.location.name }}
                  </router-link>
                  <span
                    class="ml-1 d-none d-md-inline"
                    v-if="item && item.location && item.location.latitude && item && item.location && item.location.longitude"
                  >
                  ({{ item && item.location && item.location.latitude }}, {{
                      item
                      && item.location && item.location.longitude
                    }})
                  <b-button
                    variant="secondary"
                    class="d-none"
                    size="sm"
                    v-if="!editField.isObservedDateEdit && isProjectRoleManager(item.project.id)"
                    title="Edit Observation Location"
                    alt="Edit Observation Location"
                    @click="onEditTopDetails('location', item )"
                  >
                    <font-awesome-icon
                      icon="pencil-alt"
                      size="1x"
                      class="mx-auto"
                    />
                    Edit Location
                  </b-button>
                </span>
                </b-col>
                <b-col v-if="editField.isLocationEdit">
                  <b-col class="d-flex pl-0">
                    <b-form-input
                      id="locationName"
                      class="inline mr-2"
                      v-model="locationModel.locationName"
                      placeholder="Location Name"
                      type="text"
                    ></b-form-input>
                    <b-form-input
                      id="latitude"
                      class="inline mr-2"
                      v-model="locationModel.latitude"
                      placeholder="Latitude: Ex. 41.567256"
                      type="text"
                    ></b-form-input>
                    <b-form-input
                      id="longitude"
                      class="inline mr-2"
                      v-model="locationModel.longitude"
                      placeholder="Longitude: Ex. -104.2368"
                      type="text"
                    ></b-form-input>
                    <b-col cols="1" class="inline mr-2 pl-0">
                      <b-button
                        title="Update"
                        alt="Update"
                        @click="onSubmitData(item, locationModel, 'location' )"
                      >
                        Submit
                      </b-button>
                    </b-col>
                    <b-col cols="1" class="ml-4">
                      <b-button
                        title="Cancel Editing"
                        alt="Cancel Editing"
                        @click="onCancelMainDetailsEditing('location')"
                      >
                        Cancel
                      </b-button>
                    </b-col>
                  </b-col>
                </b-col>
              </b-row>

              <ObservationDateTime
                :dateTime="item.observedAt"
                :canEdit="false"
                :observationId="item.id"
                v-if="item.observedAt"
              >
              </ObservationDateTime>

              <b-row class="row mb-2" v-if="item && item.project">
                <div class="d-none d-md-table-cell col-md-4 col-lg-3 mb-2">
                  Observed By:
                </div>
                <div class="col-md-8 col-lg-9 mb-2">
                  <div v-for="observer in item.observers">
                    {{ observer.user.displayName }}
                  </div>
                </div>
              </b-row>

              <b-row>
                <div class="col-md-8 col-lg-9 mb-2">
                  <b-badge
                    pill
                    variant="info"
                    v-show="item.isPrivate"
                    v-b-popover.hover.right="'Only the Observer and Project Manager(s) can view this observation!'"
                    placement="right"
                  >Private Observation</b-badge>
                </div>
              </b-row>

              <b-row v-if="(item && item.comments)" class="row mb-2">
                <b-col class="col-md-4 col-lg-3 mb-2">Comments:</b-col>
                <b-col class="col-md-8 col-lg-9 mb-2 limited-description">
                  <span class="limited-description">{{ item.comments }}</span>
                </b-col>
              </b-row>
            </p>

            <div class="mt-4" v-if="item && item.project">
              <ObservationRecordList :records="item.records"/>
            </div>

            <b-row v-if="false">
              <b-col class="text-left">
                <b-button
                  v-if="canEdit"
                  variant="tertiary"
                  @click="onAddModeDataClick"
                >
                  Append Data
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div
                  v-if="showAddModeData && canEdit"
                  class="mt-5 px-3 pt-3 pb-3 blue-box"
                >
                  <button type="button" class="close" @click="onAddModeDataClick">
                    <span>×</span>
                  </button>
                  <p>
                    Would you like to add a site or organism measurement?
                    <span class="d-inline-block width-one">
                    <b-form-select
                      v-model="addMoreDataQ1"
                      :options="[{value: 1, text: 'Site measurement'}, {value: 2, text: 'Organism measurement'}]"
                      class="mb-3"
                    />
                  </span>
                  </p>
                  <div v-if="showForm">
                    <p>
                      1. Measurement name:
                      <span class="d-inline-block">
                      <b-form-input
                        v-model="addMoreDataMN"
                        type="text"
                        placeholder="ex. Temperature"
                      ></b-form-input>
                    </span>
                    </p>
                    <p>
                      2. Value Type:
                      <span class="px-2"
                      ><font-awesome-icon icon="question-circle"
                      /></span>
                      <span class="d-inline-block width-two">
                      <b-form-select
                        v-model="addMoreDataValueType"
                        :options="addMoreDataValueTypeList"
                        class="mb-3"
                      />
                    </span>
                    </p>

                    <p v-if="displayImage">
                      3. Select an image
                      <input
                        class="d-none"
                        @change="onFileUploadNewRecord"
                        type="file"
                        accept="image/*"
                        ref="FileUploadNewRecord"
                      />
                      <b-button
                        variant="secondary"
                        @click="$refs.FileUploadNewRecord.click()"
                        v-if="previewNewRecordImage == null"
                      ><span class="btn-font-awesome mr-2"
                      ><font-awesome-icon icon="plus-circle"/></span
                      >Add Image
                      </b-button
                      >
                      <b-img
                        fluid
                        width="100"
                        v-if="previewNewRecordImage != null && previewNewRecordImage.length > 0"
                        :src="previewNewRecordImage"
                      ></b-img>
                      <b-button
                        v-if="previewNewRecordImage != null"
                        variant="secondary"
                        @click="onCancelImageUpload"
                      >Remove Image
                      </b-button
                      >
                    </p>

                    <p v-if="displayDropdown">
                      3. Add options
                      <span>
                      <span v-for="(field, index) in dropDownList" :key="index">
                        <b-form-input
                          class="w-75 d-inline-block mt-2"
                          type="text"
                          v-model="dropDownList[index].fieldVal"
                          placeholder=""
                        ></b-form-input>
                        <span class="ml-3" v-if="true"
                        ><a href="#" @click="removeField($event, index)"
                        ><font-awesome-icon icon="minus-circle"/></a
                        ></span>
                      </span>
                      <span class="ml-1"
                      ><a href="#" @click="addNewFile"
                      ><font-awesome-icon icon="plus-circle"/></a
                      ></span>
                    </span>
                    </p>

                    <p v-if="displayRadio">
                      3. Add options
                      <span>
                      <span v-for="(field, index) in chooseList" :key="index">
                        <b-form-input
                          class="w-75 d-inline-block mt-2"
                          type="text"
                          v-model="chooseList[index].fieldVal"
                          placeholder=""
                        ></b-form-input>
                        <span class="ml-3" v-if="true"
                        ><a href="#" @click="removeField($event, index)"
                        ><font-awesome-icon icon="minus-circle"/></a
                        ></span>
                      </span>
                      <span class="ml-1"
                      ><a href="#" @click="addNewFile"
                      ><font-awesome-icon icon="plus-circle"/></a
                      ></span>
                    </span>
                    </p>

                    <p v-if="displayValue">
                      3. Value:
                      <span class="d-inline-block">
                      <b-form-input
                        v-model="addMoreDataValue"
                        type="number"
                        step="0.1"
                        placeholder="ex. 36"
                      ></b-form-input>
                    </span>
                    </p>
                    <p v-if="displayUnits">
                      4. Units:
                      <span class="d-inline-block">
                      <b-form-input
                        v-model="addMoreDataUnits"
                        type="text"
                        placeholder="ex. Celsius"
                      ></b-form-input>
                    </span>
                    </p>
                    <p v-if="displayText">
                      3. Enter Text:
                      <b-form-textarea
                        v-model="textAreaValue"
                        placeholder="Enter something"
                        :rows="3"
                        :max-rows="6"
                      ></b-form-textarea>
                    </p>
                    <p v-if="displayDateTime">
                      3. Date and time:
                      <date-picker
                        name="date"
                        v-model="dateValue"
                        :config="options"
                      ></date-picker>
                    </p>
                    <p class="text-center">
                      <b-button
                        variant="secondary"
                        @click="onSubmitAddModeDataClick"
                      >
                        Submit
                      </b-button>
                    </p>
                  </div>
                  <div v-if="!showForm">
                    <p class="text-center">
                      Add additional organism data coming soon.
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>

      <div v-if="images && images.length > 0">
        <h2>Observation Image Gallery</h2>
        <p class="p-down">
          Below is a gallery of all photos submitted for this observation,
          including images in response to specific questions and images appended
          to the observation that apply broadly to the observation as a whole. The
          carousel at top right also shows all of these same observation images in
          carousel fashion.
        </p>
        <b-card-group columns class="mt-4">
          <b-card
            v-for="(img, index) in images"
            :img-src="img.path"
            :key="img.path + '-' + index"
            img-alt="Image"
            img-top
          ><!-- :key="index"   title="Card title that wraps to a new line" -->
            <!--<b-card-text>
              This is a wider card with supporting text below as a natural lead-in to additional content.
              This content is a little bit longer.
            </b-card-text>-->
          </b-card>
        </b-card-group>
      </div>
    </b-container>
    <div class="mt-0 mb-5">
      <VueLoader v-if="isLoading || isLoadingLocationUpdate"/>
      <b-modal
        ref="confirmationModal"
        id="confirmation-modal"
        title="Confirmation"
        hide-footer
      >
        <p class="my-4">
          Are you sure you want to permanently delete the selected element?
        </p>
        <div class="float-right">
          <b-button class="btn btn-blue" @click="onYesClick">Yes</b-button>
          <b-button @click="onNoClick">No</b-button>
        </div>
      </b-modal>
    </div>
  </div>

</template>

<script>

import VueLoader from "../../../components/layout/Loader";
import DatePicker from "vue-bootstrap-datetimepicker";
import moment from "moment";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import {mapActions, mapGetters} from "vuex";
import VueSocialSharing from "vue-social-sharing";
import {BCarousel} from "bootstrap-vue";
import ObservationDateTime from '../../../components/observation/ObservationDateTime.vue'
import ObservationRecordList from "../../../components/observation/ObservationRecordList"

export default {
  components: {
    VueLoader,
    DatePicker,
    VueSocialSharing,
    BCarousel,
    ObservationDateTime,
    ObservationRecordList
  },

  provide() {
    return {parentValidator: null};
  },

  props: {
    isNewAutoAssign: {
      type: Boolean,
      default: false,
    }
  },

  data() {

    let tableItems = [];
    let user = JSON.parse(localStorage.getItem("sessionData"));

    return {

      mapOptions: {
        streetViewControl: false,
        fullscreenControl: false,
        scaleControl: false,
        zoomControl: true,
        mapTypeControl: true
      },
      locationData: null,

      showNewAssignAlert: this.isNewAutoAssign,
      options: {
        format: "YYYY/MM/DD h:mm A",
        showClose: true
      },
      locationModel: {
        latitude: "",
        longitude: "",
        locationName: ""
      },
      twitterProjectName: "",
      facebookProjectName: "",
      editField: {
        isLocationEdit: false
      },
      showEditDate: false,
      slide: 0,
      sliding: null,
      deleteRecordId: 0,
      orderDeleted: -1,
      recordImage: null,
      previewNewRecordImage: null,
      previewEditRecordImage: null,
      recordIndex: -1,
      parentValidator: null,
      isLoadingShortUrl: true,
      questions: [],
      currentUrl: window.location.href,
      selected: null,
      textInputVal: "",
      editDateValue: "",
      showAddModeData: false,
      addMoreDataQ1: 1,
      addMoreDataMN: "",
      addMoreDataValueType: "",
      addMoreDataValueTypeList: [
        {value: "", text: "--Select--"},
        {value: "number", text: "Numerical"},
        {value: "text", text: "Text (Single Line)"},
        {value: "textarea", text: "Text (Multiline)"},
        {value: "datetime", text: "Date and time"},
        {value: "radio", text: "Radio"},
        {value: "dropdown", text: "Dropdown"},
        {value: "image", text: "Image"}
      ],
      addMoreDataValue: "",
      addMoreDataUnits: "",
      textAreaValue: "",
      dateValue: "",
      showForm: true,
      loggedInUser: user,
      lat: 45,
      lng: 27,
      tableItems: [],
      edit: false,
      displayValue: false,
      displayUnits: false,
      displayText: false,
      displayDateTime: false,
      displayImage: false,
      displayRadio: false,
      displayDropdown: false,
      images: [],
      bigImageUrl: "/assets/images/generic_img.png",
      files: "",
      recordImageUpload: null,
      dropDownList: [
        {
          fieldVal: ""
        }
      ],
      chooseList: [
        {
          fieldVal: ""
        }
      ],
      editActive: false
    };
  },

  computed: {
    ...mapGetters({
      isLoading: "observation/show/isLoading",
      isLoadingLocationUpdate: "location/show/isLoading",
      item: "observation/show/retrieved",
      error: "observation/show/error",
      deletedSuccess: "observation/show/deletedSuccess",
      userData: "user/profile/sessionData"
    }),
    canEdit() {
      if (this.item && this.item.project) {
        return (
          this.isProjectRoleManager(this.item.project.id) ||
          this.isObserver(this.item)
        );
      } else {
        return false
      }
    },
    observedAtFormated() {
      if (this.item && this.item.observedAt) {
        return this.getDisplayDateTime(this.item.observedAt);
      }
      return "";
    }
  },

  watch: {
    /**
     * @function item - function that is called when this.item is changed
     * @param newValue
     */
    item(newValue) {

      // Collect all the photos
      this.images = [];
      // From observation resources
      newValue.files.forEach((item, index) => {
          this.images.push({
            path: item.file.path,
            current: this.images.length === 0,
          });
      });


      // From photo observation records
      newValue.records.forEach(rec => {
        if (rec.recordType === 'image') {
          rec.files.forEach((file)=>{
            this.images.push({
              path: file.path,
              current: this.images.length === 0,
            });
          })
        }

        if(rec.recordType === 'organism'){
          rec.records.forEach((subRecord)=>{
            subRecord.files.forEach((file)=>{
              this.images.push({
                path: file.path,
                current: this.images.length === 0,
              });
            })
          })
        }


      })

    },

    /**
     * listen to item.records changes
     * @param newValue
     */
    "item.records"(newValue) {
      if (
        typeof newValue != "undefined" &&
        newValue instanceof Array &&
        newValue.length > 0
      ) {
        this.questions = [];

        newValue.forEach((recordItem, recordIndex) => {
          if (recordItem.recordType === "organism") {
            this.questions.push({
              questionId: recordItem.id,
              question_text: this.getOrganismName(recordItem.organism),
              type: recordItem.recordType,
              edit: false,
              answers: [],
              subRecords: []
            });

            recordItem.records.forEach(
              (organismRecordItem, organismRecordIndex) => {
                let subRecord = this.prepareForDisplayRecords(
                  organismRecordItem
                );
                if (subRecord) {
                  this.questions[this.questions.length - 1].subRecords.push({
                    observation__field: subRecord.question_text,
                    observation__answer: subRecord
                  });
                }

              }
            );
          } else if (recordItem.parentRecord !== null) { // all records that are not organisms & which have a parent record
            let newRecord = this.prepareForDisplayRecords(recordItem);
            if (newRecord) {
              this.questions.push(this.prepareForDisplayRecords(recordItem));
            }
          }
        });

        this.tableItems = [];
        this.questions.forEach((questionItem, questionIndex) => {
          let answer = "";

          if (
            ["radio", "dropdown", "organism"].indexOf(questionItem.type) !== -1
          ) {
            questionItem.answers.forEach((answerItem, answerIndex) => {
              if (answerItem.selected) {
                answer = answerItem.value;
              }
            });
          } else {
            if (questionItem.answers && questionItem.answers.length > 0) {
              answer = questionItem.answers[0].value;
            }
          }

          this.tableItems.push({
            observation__field: questionItem.question_text,
            observation__answer: questionItem
          });
        });
      }
    },

    /**
     * @function addMoreDataQ1 - function that is called when this.addMoreDataQ1 is changed
     * @param value
     */
    addMoreDataQ1(value) {
      if (value === 2) {
        this.showForm = false;
      } else {
        this.showForm = true;
      }
    },

    /**
     * @function addMoreDataValueType - function that is called when this.addMoreDataValueType is changed
     * @param value
     */
    addMoreDataValueType(value) {
      this.previewNewRecordImage = null;

      if (value === "number") {
        this.displayValue = true;
        this.displayUnits = true;
        this.displayText = false;
        this.displayDateTime = false;
        this.addMoreDataValue = 0;
        this.displayImage = false;
        this.displayRadio = false;
        this.displayDropdown = false;
      } else if (value === "text" || value === "textarea") {
        this.displayText = true;
        this.displayValue = false;
        this.displayUnits = false;
        this.displayDateTime = false;
        this.displayImage = false;
        this.displayRadio = false;
        this.displayDropdown = false;
      } else if (value === "datetime") {
        this.displayText = false;
        this.displayValue = false;
        this.displayUnits = false;
        this.displayDateTime = true;
        this.displayImage = false;
        this.displayRadio = false;
        this.displayDropdown = false;
      } else if (value === "image") {
        this.displayText = false;
        this.displayValue = false;
        this.displayUnits = false;
        this.displayDateTime = false;
        this.displayImage = true;
        this.displayRadio = false;
        this.displayDropdown = false;
      } else if (value === "dropdown") {
        this.displayText = false;
        this.displayValue = false;
        this.displayUnits = false;
        this.displayDateTime = false;
        this.displayImage = false;
        this.displayRadio = false;
        this.displayDropdown = true;
      } else if (value === "radio") {
        this.displayText = false;
        this.displayValue = false;
        this.displayUnits = false;
        this.displayDateTime = false;
        this.displayImage = false;
        this.displayRadio = true;
        this.displayDropdown = false;
      } else {
        this.displayText = false;
        this.displayValue = false;
        this.displayUnits = false;
        this.displayDateTime = false;
        this.displayImage = false;
        this.displayRadio = false;
        this.displayDropdown = false;
      }
    },

    /**
     * @function images - function that is called when this.images is changed
     * @param val
     */
    images(val) {
      if (val.length > 0) {
        this.bigImageUrl = val[0].path;
      } else {
        this.bigImageUrl = "";
      }
    }
  },

  methods: {
    ...mapActions({
      getObservation: "observation/show/getObservation",
      deleteObservation: "observation/show/deleteObservation",
      resetError: "observation/show/resetError",
      setError: "observation/show/setError",
      uploadFile: "observation/show/uploadObservationImage",
      createRecord: "observation/show/createRecord",
      observationUpdate: "observation/show/observationUpdate",
      mainObservationDetailsUpdate:
        "observation/show/mainObservationDetailsUpdate",
      updateLocationInfo: "location/show/updateLocationInfo"
    }),

    /**
     * @function onEditTopDetails
     * @param detailsName
     * @param currItem
     * Function gets called when the main observation details will be in the editing mode
     */
    onEditTopDetails(detailsName, currItem) {
      if (detailsName === "date") {
        this.editField.isObservedDateEdit = true;
        let d = new Date(this.getDisplayDateTime(currItem.observedAt));
        this.editDateValue = new Date(
          this.getDisplayDateTime(currItem.observedAt)
        );
      } else if (detailsName === "location") {
        this.editField.isLocationEdit = true;
        this.locationModel.locationName = currItem.location.name;
        this.locationModel.longitude = currItem.location.longitude;
        this.locationModel.latitude = currItem.location.latitude;
      }
    },

    /**
     * @function onCancelMainDetailsEditing
     * @param detailsName
     * Function gets called when we cancel the main observation details edit mode
     */
    onCancelMainDetailsEditing(detailsName) {
      if (detailsName === "date") {
        this.editField.isObservedDateEdit = false;
        this.editDateValue = "";
      } else if (detailsName === "location") {
        this.editField.isLocationEdit = false;
        this.locationModel = {
          locationName: "",
          latitude: "",
          longitude: ""
        };
      }
    },

    /**
     * @function onSlideStart
     * @param slide
     * Event Gets fired when slide for the carousel starts
     */
    onSlideStart(slide) {
      this.sliding = true;
    },

    /**
     * @function onSlideEnd
     * @param slide
     * Event Gets fired when slide for the carousel ends
     */
    onSlideEnd(slide) {
      this.sliding = false;
    },


    /**
     * @function onSubmitData
     * @param currItem
     * @param modalVal
     * @param detailsName
     */
    onSubmitData(currItem, modalVal, detailsName) {
      let observationMainData;
      if (detailsName === "date") {
        this.item.observedAt = new Date(this.editDateValue);
      } else if (detailsName === "location") {
        this.item.location.name = this.locationModel.locationName;
        let validCoordinates = this.isValidCoordinates(
          this.locationModel.latitude,
          this.locationModel.longitude,
          this.item
        );

        if (!validCoordinates) {
          return;
        }
        const data = {
          id: this.item.location.id,
          locationInfo: this.item.location
        };
        this.updateLocationInfo(data).then(result => {
          debugger;
          this.item.location = result;
          this.onCancelMainDetailsEditing(detailsName);
        });
      }
      if (detailsName !== "location") {
        this.mainObservationDetailsUpdate({
          id: this.item.id,
          observation: this.item
        }).then(data => {
          if (data && typeof data == "object") {
            this.onCancelMainDetailsEditing(detailsName);
          }
        });
      }
    },
    /**
     * @function prepareForDisplayRecords
     * @param item
     * @returns {Array}
     */
    prepareForDisplayRecords(item) {
      if (item.recordType === "text" || item.recordType === "textarea") {
        return this.prepareItemText(item);
      }

      if (item.recordType === "dropdown") {
        return this.prepareItemDropdown(item);
      }

      if (item.recordType === "radio") {
        return this.prepareItemRadio(item);
      }

      if (item.recordType === "number") {
        return this.prepareItemNumber(item);
      }

      if (item.recordType === "datetime") {
        return this.prepareItemDateTime(item);
      }

      if (
        item.recordType === "image" &&
        item.fileObject &&
        item.fileObject.path
      ) {

        // Prevent image records from being shown more than once
        // - image sub-records appear more than once (e.g. under an organism)
        let img = this.images.filter(img => {
          if (img.path === item.fileObject.path) {
            return img
          }
        })
        if (img && img.length < 1) {
          this.images.push({
            path: item.fileObject.path,
            current: true
          });
        }

        return this.prepareItemImage(item);
      }

      return null;
    },

    /**
     * @function onAddModeDataClick - click handler for add more data button
     */
    onAddModeDataClick() {
      this.showAddModeData = !this.showAddModeData;
    },

    /**
     * @function onSubmitAddModeDataClick - click handler for submit button
     */
    onSubmitAddModeDataClick() {
      let type = "text";
      let value = "";
      let unit = "";
      let question_text = "";
      let record = null;

      let lastOrderNumber = 0;
      this.item.records.forEach((record, recordIndex) => {
        if (lastOrderNumber < record.orderNumber) {
          lastOrderNumber = record.orderNumber;
        }
      });

      if (this.addMoreDataValueType === "") {
        return;
      } else if (this.addMoreDataValueType === "number") {
        if (isNaN(this.addMoreDataValue)) {
          // not a number
          return;
        }

        this.addMoreDataValue = parseFloat(this.addMoreDataValue).toFixed(1);
        record = this.prepareAddNumberRecord(lastOrderNumber);
      } else if (
        this.addMoreDataValueType === "text" ||
        this.addMoreDataValueType === "textarea"
      ) {
        record = this.prepareAddTextRecord(lastOrderNumber);
      } else if (this.addMoreDataValueType === "datetime") {
        record = this.prepareAddDateTimeRecord(lastOrderNumber);
      } else if (this.addMoreDataValueType === "dropdown") {
        record = this.prepareAddDropdownRecord(lastOrderNumber);
      } else if (this.addMoreDataValueType === "radio") {
        record = this.prepareAddDropdownRecord(lastOrderNumber);
      } else if (this.addMoreDataValueType === "image") {
        this.prepareAddImageRecord(lastOrderNumber, value);

        return;
      }

      this.createRecord(record).then(data => {
        this.addMoreDataQ1 = 1;
        this.addMoreDataMN = "";
        this.addMoreDataValueType = "";
        this.addMoreDataValue = "";
        this.addMoreDataUnits = "";
        this.textAreaValue = "";
        this.dateValue = "";
        this.dropDownList = [
          {
            fieldVal: ""
          }
        ];
        this.chooseList = [
          {
            fieldVal: ""
          }
        ];
      });
    },

    /**
     * @function prepareAddTextRecord
     * @param lastOrderNumber
     * @returns {{orderNumber: *, field: {dataType: string}, records: Array, recordType: string, observation: *, label: string, value: string}}
     */
    prepareAddTextRecord(lastOrderNumber) {
      return {
        label: this.addMoreDataMN,
        field: {
          dataType: "text"
        },
        orderNumber: lastOrderNumber + 1,
        recordType: this.addMoreDataValueType,
        records: [],
        value: this.textAreaValue,
        observation: this.item["@id"]
      };
    },

    /**
     * @function prepareAddNumberRecord
     * @param lastOrderNumber
     * @returns {{orderNumber: *, field: {dataType: string}, records: Array, recordType: string, observation: *, label: string, value: *}}
     */
    prepareAddNumberRecord(lastOrderNumber) {
      return {
        label:
          this.addMoreDataMN +
          (this.addMoreDataUnits.length
            ? " (" + this.addMoreDataUnits + ")"
            : ""),
        field: {
          dataType: "number"
        },
        orderNumber: lastOrderNumber + 1,
        recordType: "number",
        records: [],
        value: this.addMoreDataValue,
        observation: this.item["@id"]
      };
    },

    /**
     * @function prepareAddDateTimeRecord
     * @param lastOrderNumber
     * @returns {{orderNumber: *, field: {dataType: string}, records: Array, recordType: string, observation: *, label: string, value: Date}}
     */
    prepareAddDateTimeRecord(lastOrderNumber) {
      let dateObj = this.dateForServer(this.dateValue);

      return {
        label: this.addMoreDataMN,
        field: {
          dataType: "datetime"
        },
        orderNumber: lastOrderNumber + 1,
        recordType: "datetime",
        records: [],
        value: dateObj,
        observation: this.item["@id"]
      };
    },

    /**
     * @function prepareAddImageRecord
     * @param lastOrderNumber
     */
    prepareAddImageRecord(lastOrderNumber) {
      this.uploadFile({fd: this.recordImageUpload}).then(data => {
        this.createRecord({
          label: this.addMoreDataMN,
          field: {
            dataType: "file"
          },
          orderNumber: lastOrderNumber + 1,
          recordType: "image",
          records: [],
          fileObject: data["@id"],
          value: null,
          observation: this.item["@id"]
        }).then(data => {
          this.addMoreDataQ1 = 1;
          this.addMoreDataMN = "";
          this.addMoreDataValueType = "";
          this.addMoreDataValue = "";
          this.addMoreDataUnits = "";
          this.textAreaValue = "";
          this.dateValue = "";
        });
      });
    },

    /**
     * @function prepareAddDropdownRecord
     * @param lastOrderNumber
     * @returns {*}
     */
    prepareAddDropdownRecord(lastOrderNumber) {
      let options = [];
      let recordType = "";

      if (this.displayDropdown) {
        this.dropDownList.forEach((item, index) => {
          options.push({
            dataType: "text",
            value: item.fieldVal,
            orderNumber: index
          });
        });

        recordType = "dropdown";
      } else if (this.displayRadio) {
        this.chooseList.forEach((item, index) => {
          options.push({
            dataType: "text",
            value: item.fieldVal,
            orderNumber: index
          });
        });

        recordType = "radio";
      } else {
        return null;
      }

      return {
        label: this.addMoreDataMN,
        field: {
          dataType: "set",
          optionsSet: {
            optionsValues: options
          }
        },
        orderNumber: lastOrderNumber + 1,
        recordType: recordType,
        records: [],
        observation: this.item["@id"]
      };
    },

    /**
     *
     * @returns {void|*}
     */
    onFileUploadNewRecord(file) {
      const _files = file.target.files || file.dataTransfer.files;
      const fileTypesAllowed = ["jpg", "jpeg", "png"];
      let object = {};

      if (!_files.length) return;

      const filename = _files[0].name;

      if (
        filename.lastIndexOf(".") <= 0 ||
        fileTypesAllowed.indexOf(
          filename
            .split(".")
            .pop()
            .toLowerCase()
        ) === -1
      ) {
        return this.setError(
          "Please select a valid file type: jpg, jpeg, png"
        );
      }

      this.recordImageUpload = new FormData();
      this.recordImageUpload.append("file", _files[0], _files[0].name);
      this.recordImageUpload.append("actionType", "observation_image");

      const fileReader = new FileReader();

      fileReader.onload = e => {
        this.previewNewRecordImage = e.target.result;
      };

      fileReader.readAsDataURL(_files[0]);
    },

    /**
     * @function addNewFile
     * @param evt
     */
    addNewFile(evt) {
      evt.stopImmediatePropagation();
      evt.preventDefault();

      if (this.displayRadio) {
        this.chooseList.push({fieldVal: ""});
      } else if (this.displayDropdown) {
        this.dropDownList.push({fieldVal: ""});
      }
    },

    /**
     * @function removeField
     * @param evt
     * @param index
     */
    removeField(evt, index) {
      evt.stopImmediatePropagation();
      evt.preventDefault();

      if (this.displayRadio) {
        this.chooseList.splice(index, 1);
      } else if (this.displayDropdown) {
        this.dropDownList.splice(index, 1);
      }
    },

    /**
     * @function onFileUploadChange - change handler for the file input type
     * @param {object} file - event object
     */
    onFileUploadChange(file) {
      const _files = file.target.files || file.dataTransfer.files;
      const fileTypesAllowed = ["jpg", "jpeg", "png"];
      let object = {};

      if (!_files.length) return;

      const filename = _files[0].name;

      if (
        filename.lastIndexOf(".") <= 0 ||
        fileTypesAllowed.indexOf(
          filename
            .split(".")
            .pop()
            .toLowerCase()
        ) === -1
      ) {
        return this.setError(
          "Please select a valid file type: jpg, jpeg, png"
        );
      }

      let fd = new FormData();
      fd.append("file", _files[0], _files[0].name);
      fd.append("actionType", "observation_image");

      this.uploadFile({fd: fd}).then(data => {
        let observation = {files: []};

        this.item.files.forEach((item, index) => {
          observation.files.push(item["@id"]);
        });

        observation.files.push({
          file: data["@id"]
        });
        this.observationUpdate({id: this.item.id, observation: observation});
      });
    },

    /**
     * @function prepareItemText
     * @param item
     * @returns {{question_text: *, questionId: *, edit: boolean, answers: {id: *, value: *, selected: boolean}[], type: *}}
     */
    prepareItemText(item) {
      return {
        questionId: item.id,
        question_text: item.label,
        type: item.recordType,
        edit: false,
        answers: [
          {
            id: item.id,
            value: item.value,
            selected: true
          }
        ]
      };
    },

    /**
     * @function prepareItemDropdown
     * @param item
     * @returns {{question_text: *, questionId: *, edit: boolean, answers: Array, type: *}}
     */
    prepareItemDropdown(item) {
      return {
        questionId: item.id,
        question_text: item.label,
        type: item.recordType,
        edit: false,
        answers: [
          {
            id: item.optionValue ? item.optionValue.id : "",
            value: item.optionValue ? item.optionValue.value : item.value,
            selected: true
          }
        ]
      };
    },

    /**
     * @function prepareItemRadio
     * @param item
     * @returns {{question_text: *, questionId: *, edit: boolean, answers: Array, type: *}}
     */
    prepareItemRadio(item) {
      return {
        questionId: item.id,
        question_text: item.label,
        type: item.recordType,
        edit: false,
        answers: [
          {
            id: item.optionValue.id,
            value: item.optionValue.value,
            selected: true
          }
        ]
      };
    },

    /**
     * @function prepareItemNumber
     * @param item
     * @returns {{question_text: *, questionId: *, edit: boolean, answers: {id: *, value: *, selected: boolean}[], type: *}}
     */
    prepareItemNumber(item) {
      return {
        questionId: item.id,
        question_text: item.label,
        type: item.recordType,
        edit: false,
        answers: [
          {
            id: item.id,
            value: item.value,
            selected: false
          }
        ]
      };
    },

    /**
     * @function prepareItemDateTime
     * @param item
     * @returns {{question_text: *, questionId: *, edit: boolean, answers: {id: *, value: *, selected: boolean}[], type: *}}
     */
    prepareItemDateTime(item) {
      return {
        questionId: item.id,
        question_text: item.label,
        type: item.recordType,
        edit: false,
        answers: [
          {
            id: item.id,
            value: item.value,
            selected: false
          }
        ]
      };
    },

    /**
     * @function prepareItemImage
     * @param item
     * @returns {{question_text: *, questionId: *, edit: boolean, answers: {id: *, value: Array, selected: boolean}[], type: *}}
     */
    prepareItemImage(item) {
      return {
        questionId: item.id,
        question_text: item.label,
        type: item.recordType,
        edit: false,
        answers: [
          {
            id: item.id,
            value:
              item.fileObject && item.fileObject.path
                ? item.fileObject.path
                : "",
            selected: true
          }
        ]
      };
    },

    /**
     * @function onDeleteObservationClick - when the user click on delete observation button
     * @param event
     */
    onDeleteObservationClick(event) {
      event.stopImmediatePropagation();
      event.preventDefault();
      this.resetError();

      this.$refs.confirmationModal.show();
    },

    /**
     * @function clickAddDataLink - function that handle the Add Data button press. Check if user is logged in.
     *    If the user is not logged in display a popup with a message if it is redirecting the user to the datasheet
     */
    clickAddDataLink() {
      let datasheetId = this.item.datasheet['@id'].replace("/datasheets/", "");

      if (JSON.parse(localStorage.getItem("sessionData")) == null) {
        this.$root.$emit("bv::show::modal", "not-logged-in-modal");
        return;
      } else {
        this.$router.push({
          name: "DatasheetView",
          params: {id: datasheetId}
        });
      }
    },

    /**
     * @function onYesClick - when the user click on Yes button on delete confirmation
     */
    onYesClick() {
      this.deleteObservation({id: this.item.id}).then(data => {
        if (typeof data === "boolean" && data === false) {
          this.$refs.confirmationModal.hide();
        } else {
          this.$router.push({
            name: "ProjectShow",
            params: {id: this.item.project.urlField}
          });
        }
      });
    },

    /**
     * @function onNoClick - when the user click on No button on delete confirmation
     */
    onNoClick() {
      this.$refs.confirmationModal.hide();
    },

    /**
     * @function onDismissedClick - when the user dismiss error alert, we reset error message
     */
    onDismissedClick() {
      this.resetError();
    },

    /**
     * @function onCancelImageUpload
     * @param evt
     */
    onCancelImageUpload(evt) {
      if (this.previewNewRecordImage != null) {
        this.previewNewRecordImage = null;
      } else if (this.previewEditRecordImage != null) {
        this.previewEditRecordImage = null;
      }
    },

    /**
     *
     */
    onEditActiveChange() {
      this.editActive = !this.editActive;
    },

    /**
     * @function getOrganismName
     * @param organismRecordSelectedSpecies
     * @returns {string}
     */
    getOrganismName(organismRecordSelectedSpecies) {
      return organismRecordSelectedSpecies
        ? organismRecordSelectedSpecies.customName
          ? organismRecordSelectedSpecies.customName
          : organismRecordSelectedSpecies.canonicalName &&
          organismRecordSelectedSpecies.scientificName
            ? organismRecordSelectedSpecies.canonicalName +
            " (" +
            organismRecordSelectedSpecies.scientificName +
            ")"
            : ""
        : "";
    },

    /**
     * @function locationDetailsShow
     * @param routeName
     * @param routeParam
     */
    locationDetailsShow(routeName, routeParam) {
      this.$router.push({name: routeName, params: {id: routeParam}});
    },

    /**
     * @function getDisplayDateTime - For now, we've decided to not deal with
     *  timezone so this function will remove the timezone portion of the date
     *  (+), if it exists in the value to avoid incorrect timezone conversion
     * @param {string/object} value - the date value to use, can be either
     *  String or Object (will assume to be a Date object)
     * @param {String} the date&time using moment (without timezone)
     */
    getDisplayDateTime(value) {
      if (value) {
        let index = -1;
        let type = typeof value;

        // Tried using .equals() and .compareTo() function but got runtime
        // error so have to use ==
        if (type == "string") index = value.search(/\+/);
        else if (type == "object") value = value.toISOString();

        if (index == -1) {
          return moment(value).format("ddd MMMM DD, YYYY h:mm:ss a");
        } else {
          let new_date = value.substring(0, index);
          return moment(new_date).format("ddd MMMM DD, YYYY h:mm:ss a");
        }
      }
      return "";
    }
  },

  created() {
    this.$root.$on("editActiveChange", this.onEditActiveChange);
    this.resetError();
    this.getObservation({id: this.$route.params.id}).then(data => {
      if (data) {
        this.locationData = data.location;
        // this.locationData.latitude = Math.round(locationData.latitude * 10000) / 10000
        // this.locationData.longitude = Math.round(locationData.longitude * 10000) / 10000

        const commonTweetText =
          " observation via @Citsci #PoweredbyCitSci #CitizenScience #citsci " +
          this.currentUrl;
        this.twitterProjectName = !data.project.twitterLink
          ? "Check out this" + commonTweetText
          : data.project.twitterLink.indexOf("@") === 0
            ? data.project.twitterLink
            : "@" + data.project.twitterLink;
        if (data.project.twitterLink) {
          this.twitterProjectName =
            "Check out this " + this.twitterProjectName + commonTweetText;
        }
      } else {
        // this.$router.push({name: "NotFound"});
      }
      /*
      TODO: Once finalized, what to do put for facebook uncomment this and put this
      this.facebookProjectName = data.project.facebookLink;*/
    });

    /*let shortUrl = this.bitlyURL(
      "https://" + "citsci.org" + "/observations/show/" + this.$route.params.id
    );

    if (!shortUrl) {
      this.currentUrl = window.location.href;
      this.isLoadingShortUrl = false;
    } else {
      shortUrl.then(responseJson => {
        this.currentUrl =
          responseJson.status_code === 200
            ? responseJson.data.url
            : window.location.href;
        this.isLoadingShortUrl = false;
      });
    }*/
  }
};

</script>

<style scoped lang="scss">
.text-blue {
  color: #00c8f8;
  font-size: 22px;
}

.font-14 {
  font-size: 14px;
  font-weight: bold;
}

.text-red {
  color: #d9534f;
  font-size: 22px;
  margin-right: 4px;
}

.social {
  color: #ffffff;

  .button-social.social-button {
    height: 21px;
    padding: 2px 8px;
    font-size: 12px;

    &.facebook__design__flat {
      font-weight: bold;
    }
  }
}

.submitted {
  height: 36px;
  background: #F8F9FA;
  color: #6C757D;
  margin-left: 20px;
}

.btn-delete-special {
  color: #000000;
  background: rgba(217, 83, 79, 0.3);
  margin-right: -210px;

  &:hover {
    .text-red {
      color: #ffffff;
    }
  }
}

.blue-box {
  border: solid 2px #125EA2;
  background-color: #D9EBF9;
  width: 100%;
}

.width-one {
  width: 150px;
}

.width-two {
  width: 250px;
}

.preview-image {
  width:100%;
  height: auto;
}

.small-images {
  width: 100px;
  height: auto;

  &.selected {
    border: 1px solid #00c8f8;
  }
}

.btn-blue {
  .btn-font-awesome {
    font-size: 20px;
  }
}

.icon-twitter { background-color: #1b95e0; }
.icon-twitter:hover { background-color: #263960; }
.icon-twitter::before {}

.icon-facebook { background-color: #3b5998; }
.icon-facebook:hover { background-color: #263960; }
.icon-facebook::before {}

.social-text {
  margin: 2px;
  font-weight: bold;
}
.paddingRight40px {
  padding-right: 40px;
}
.my-custom-carousel {
  //.img-fluid in above
  //max-width: 100%;
  //max-height: 300px !important;

  //height: 300px;
  width: 100%;
  background-position: center;
  background-attachment: fixed;
  background-position: center;
  text-align: center;
  //background-size: cover;
  //background-color: #00B0F0;
}

.paddingTop20px {
  padding-top: 20px;
}

.marginBottom34px {
  margin-top: -34px;
}


.removeBtnSetting {
  font-size:20px;
  color:red;
}
.removeBtnSetting:hover {
  /*font-size:20px;
  color:#ffffff;*/
}

.observation-detail-label {
  color: grey;
  font-size: 1.3rem;
  line-height: 1.3;
}

.observation-detail-description {
  width: 100%;
  padding-left: 2px;
  padding-right: 8px;
  color: grey;
  font-size: 1.3rem;
  line-height: 1.3;
}

.label-text {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #ffffff;
}
.clearBoth {
  clear: both;
}
.google-map-obs-details > .container {
  margin-top: 0px;
  padding-top: 0px;
}
.paddingLeft32px {
  padding-left: 32px;
}
.google-map-obs-details .vue-map-container,
.google-map-obs-details .vue-map-container .vue-map {
  width: 100%;
}

.display-content {
  display: contents;
}


</style>
