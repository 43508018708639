import fetch from '../../../../utils/fetch'
import {urlBuilder} from "../../../../helpers/url_builder_helper";

const getInitialState = () => {
  return {
    locations: [],
    isLoading: false,
    error: null
  }
}

const types = {
  SET_LOCATIONS_LOADING: 'SET_LOCATIONS_LOADING',
  GET_LOCATIONS_START: 'GET_LOCATIONS_START',
  ADD_LOCATIONS: "ADD_LOCATIONS",
  GET_LOCATIONS_ERROR: "GET_LOCATIONS_ERROR",
  STOP_LOADING: "STOP_LOADING",
  RESET_ERROR: 'RESET_ERROR',
  RESET: 'RESET',
}

const state = getInitialState();

const getters = {
  locations(state) {
    if (state.locations) {
      return state.locations
    }  else {
      return null
    }
  },
  isLoading(state) { return state.isLoading },
  error(state) { return state.error },
}

function fetchLocationBlock({commit}, {url, ctx}) {
  commit(types.GET_LOCATIONS_START)
  return fetch(url + `${urlBuilder(ctx)}`)
    .then(response => response.json())
    .then((data) => {
      commit(types.STOP_LOADING)
      commit(types.ADD_LOCATIONS, data['hydra:member'])
      if (ctx.currentPage === 1) return data
    })
    .catch((e) => {
      commit(types.STOP_LOADING)
      commit(types.GET_LOCATIONS_ERROR, e.message)
    })
}

const actions = {
  /**
   * @function get- retrieve predefined locations for a datasheet
   * @param commit
   * @param datasheetId
   */
  get({commit}, datasheetId) {
    commit(types.RESET)
    const maxFetch = 500;
    const ctx = {currentPage: 1, perPage: maxFetch, sortDesc: false, sortBy: 'name'};
    let url = '/datasheets/' + datasheetId + '/locations';
    let totalItems = null;
    fetchLocationBlock({commit}, {url, ctx}).then(async (data) => {
      totalItems = data['hydra:totalItems'];
      if (totalItems > maxFetch) {
        for (let i = 1; i < (totalItems / maxFetch); i++) {
          const ctx = {currentPage: i + 1, perPage: maxFetch, sortDesc: false, sortBy: 'name'};
          await fetchLocationBlock({commit}, {url, ctx})
        }
      }
    });
  },
}

const mutations = {
  [types.GET_LOCATIONS_ERROR](state, error) {
    state.error = error
  },
  [types.GET_LOCATIONS_START](state) {
    state.isLoading = true;
  },
  [types.STOP_LOADING](state) {
    state.isLoading = false;
  },
  [types.ADD_LOCATIONS](state, locations) {
    state.locations = [...state.locations, ...locations];
  },
  [types.RESET](state) {
    Object.assign(state, getInitialState());
  },
}

export default {
  namespaced: true,
  state: getInitialState(),
  getters: getters,
  actions: actions,
  mutations: mutations
}
