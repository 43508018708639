import ProjectList from '../components/project/List'
import ProjectCreate from '../components/project/Create'
import ProjectShow from '../components/project/view/index.vue'
import ProjectManageMembers from '../components/project/ManageMembers'
//import ProjectAcceptInvitation from '../components/project/ProjectAcceptInvitation'

import ProjectHome from '../components/project/view/home'
import ProjectObservations from '../components/project/view/observations'
import ProjectLocations from '../components/project/view/locations'
import ProjectMap from '../components/project/view/map'
import ProjectDownloads from '../components/project/view/downloads'
import ProjectResources from '../components/project/view/resources'
import ProjectForum from '../components/project/view/forum'
import ProjectForumDiscussion from '../components/project/view/forum/discussion/index.vue'
import ProjectAnalyses from '../components/project/view/analyses'
import ProjectResults from '../components/project/view/results'
import ProjectEdit from '../components/project/view/edit'
import ProjectDatasheets from '../components/project/view/datasheets'
import ProjectMembers from '../components/project/view/members'
import ProjectAssignedLocations from '../components/project/view/assigned_locations/index.vue'
import ProjectZooniverse from '../components/project/view/zooniverse'
import ProjectAirtable from '../components/project/view/airtable/index.vue'
import ProjectSciStarter from '../components/project/view/scistarter'
import ProjectVera from '../components/project/view/vera'
import ProjectInvite from '../components/project/view/invite'


export default [
  { name: 'ProjectList', path: '/projects/', component: ProjectList, meta: { requiresAuth: false } },
  { name: 'ProjectCreate', path: '/projects/create', component: ProjectCreate, meta: { requiresAuth: true } },
  { name: 'ProjectManageMembers', path: '/projects/:id/manage-members', component: ProjectManageMembers },
  /* { name: 'ProjectAcceptInvitation', path: '/projects/:id/invite/:token', component: ProjectAcceptInvitation },*/
  {
    name: 'ProjectShow', path: '/projects/:id', component: ProjectShow, meta: { requiresAuth: false }, redirect: {name: 'ProjectHome'},
    children: [
      {
        path: '',
        name: 'ProjectHome',
        component: ProjectHome
      },
      {
        path: 'observations',
        name: 'ProjectObservations',
        component: ProjectObservations,
      },
      {
        path: 'locations',
        name: 'ProjectLocations',
        component: ProjectLocations,
      },
      {
        path: 'map',
        name: 'ProjectMap',
        component: ProjectMap,
      },
      {
        path: 'downloads',
        name: 'ProjectDownloads',
        component: ProjectDownloads,
      },
      {
        path: 'resources',
        name: 'ProjectResources',
        component: ProjectResources,
      },
      {
        path: 'forum',
        name: 'ProjectForum',
        component: ProjectForum,
        props: true,
      },
      {
        path: 'forum/:post',
        name: 'ProjectForumDiscussion',
        component: ProjectForumDiscussion,
        props: true,
      },
      {
        path: 'forum/:post/:reply',
        name: 'ProjectForumDiscussionReply',
        component: ProjectForumDiscussion,
        props: true,
      },
      {
        path: 'analyses',
        name: 'ProjectAnalyses',
        component: ProjectAnalyses,
      },
      {
        path: 'results',
        name: 'ProjectResults',
        component: ProjectResults,
      },
      {
        path: 'edit',
        name: 'ProjectEdit',
        component: ProjectEdit,
      },
      {
        path: 'datasheets',
        name: 'ProjectDatasheets',
        component: ProjectDatasheets,
      },
      {
        path: 'members',
        name: 'ProjectMembers',
        component: ProjectMembers,
      },
       {
        path: 'members/locations',
        name: 'ProjectAssignedLocations',
        component: ProjectAssignedLocations,
      },
      {
        path: 'zooniverse',
        name: 'ProjectZooniverse',
        component: ProjectZooniverse,
      },
      {
        path: 'airtable',
        name: 'ProjectAirtable',
        component: ProjectAirtable,
      },
      {
        path: 'scistarter',
        name: 'ProjectSciStarter',
        component: ProjectSciStarter,
      },
      {
        path: 'vera',
        name: 'ProjectVera',
        component: ProjectVera,
      },
      {
        path: 'invite/:token',
        name: 'ProjectInvite',
        component: ProjectInvite,
      },
      {
        path: '*',
        name: 'Project404',
        component: ProjectHome
      },
    ]
  },
]
