import Vue from 'vue';
import App from './App.vue';
import router from './router';
import IdleVue from 'idle-vue';

// import google maps key
import {
  GOOGLE_MAPS_KEY,
  OIDC_GOOGLE_CLIENT_ID,
  OIDC_GOOGLE_PROVIDER_URL, OIDC_GOOGLE_REDIRECT_URI,
  OIDC_SCISTARTER_CLIENT_ID, OIDC_SCISTARTER_PROVIDER_URL, OIDC_SCISTARTER_REDIRECT_URI,
  OIDC_AIRTABLE_CLIENT_ID, OIDC_AIRTABLE_PROVIDER_URL, OIDC_AIRTABLE_REDIRECT_URI, OIDC_AIRTABLE_SCOPES
} from './config/_entrypoint'



// import constants
import { constants } from './constants/'

// import helpers
import { helpers } from './helpers/'

// import vuex store
import store from './store'

// import styles
import 'pretty-checkbox/src/pretty-checkbox.scss'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "leaflet/dist/leaflet.css";


// vue multiselect stylesheet
import 'vue-multiselect/dist/vue-multiselect.min.css'

// import toggle button
import ToggleButton from 'vue-js-toggle-button'

// Pretty Checkbox
import PrettyCheckbox from 'pretty-checkbox-vue'

// import vue modal
import VModal from 'vue-js-modal'

// import vue google maps
import * as VueGoogleMaps from 'vue2-google-maps'

// import vue multiselect component
import Multiselect from 'vue-multiselect'

// import CKEDITOR plugin for forum
import CKEditor from '@ckeditor/ckeditor5-vue'

// import vue csv import component
import VueCsvImport from 'vue-csv-import'

// import underscore js library
import underscore from 'vue-underscore'

// import vue sharing component
import VueSocialSharing from 'vue-social-sharing'

// import Vue Carousel Component
import { CarouselPlugin } from 'bootstrap-vue'

import 'babel-polyfill'

// import Highcharts Plugin
import HighchartsVue from 'highcharts-vue'

// import AOS (animate on scroll)
import AOS from 'aos'
import 'aos/dist/aos.css'

// import JSON to CSV plugin
import JsonCSV from 'vue-json-csv'

import vLinkify from 'vue-linkify-v2'

// import general components
import sponsors from './components/layout/BootstrapVueSponsors'
import header from './components/layout/HeaderBanner'
import featureComingSoon from './components/layout/FeatureComingSoon'
import loginMessageModal from './components/layout/LoginMessageModal'
import projectMemberModal from './components/layout/NotProjectMemberModal'
import notVerifiedModal from './components/layout/NotVerifiedModal'
import addLocationModal from './components/layout/AddLocationModal'
import confirmationPopUp from './components/layout/ConfirmationPopUp'

// Font awesome icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faSpinner, faPencilAlt, faBinoculars, faMapMarkerAlt, faImage, faComment, faExclamationTriangle, faQuestionCircle,
  faEnvelope, faDownload, faUsers, faPlusCircle, faMinusCircle, faUserPlus, faEllipsisH, faClone, faCube, faCubes, faClipboardList,
  faHome, faToggleOn, faChevronDown, faLightbulb, faChartLine, faChartBar, faChartArea, faComments, faCheckCircle, faTint, faLeaf, faEye, faTrash,
  faPen, faPrint, faPlusSquare, faHeart, faTimesCircle, faSave, faCalendarAlt, faAddressBook, faUser, faTimes, faArrowCircleRight,
  faSitemap, faHourglass, faStar, faHandHoldingHeart, faMapMarkedAlt, faPoll, faUserFriends, faBars, faFileAlt, faCogs, faBan, faTasks,
  faInfo, faQuestion, faSync } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope as faEnvelopeRegular, faCommentDots, faHandshake, faClone as faCloneRegular, faLightbulb as faLightbulbRegular,faEdit, faClock } from '@fortawesome/free-regular-svg-icons'
import { faCreativeCommonsShare, faBloggerB, faFacebook, faAndroid, faApple, faXTwitter, faFacebookSquare, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons'

library.add( faSpinner, faPencilAlt, faBinoculars, faMapMarkerAlt, faImage, faComment, faExclamationTriangle, faQuestionCircle,
  faEnvelope, faDownload, faUsers, faPlusCircle, faMinusCircle, faUserPlus, faEllipsisH, faClone, faCube, faCubes, faClipboardList,
  faHome, faToggleOn, faChevronDown, faLightbulb, faChartLine, faChartBar, faChartArea, faComments, faCheckCircle, faTint, faLeaf, faEye, faTrash,
  faPen, faPrint, faPlusSquare, faHeart, faTimesCircle, faSave, faCalendarAlt, faAddressBook, faUser, faTimes, faArrowCircleRight,
  faSitemap, faHourglass, faStar, faHandHoldingHeart, faMapMarkedAlt, faPoll, faUserFriends, faBars, faFileAlt, faCogs, faBan, faTasks,
  faInfo, faQuestion, faSync, faEnvelopeRegular, faCommentDots, faHandshake, faCloneRegular, faLightbulbRegular,
  faEdit, faClock, faCreativeCommonsShare, faBloggerB, faFacebook, faAndroid, faApple, faXTwitter, faFacebookSquare, faYoutube, faInstagram )

// register components
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('app-sponsors', sponsors)
Vue.component('app-header', header)
Vue.component('app-feature-coming-soon', featureComingSoon)
Vue.component('app-login-message-modal', loginMessageModal)
Vue.component('app-not-member-modal', projectMemberModal)
Vue.component('app-not-verified-modal', notVerifiedModal)
Vue.component('app-add-location-modal', addLocationModal)
Vue.component('app-confirmation-pop-up', confirmationPopUp)
Vue.component('multiselect', Multiselect)
Vue.component('vue-csv-import', VueCsvImport)
Vue.component('ShareNetwork', VueSocialSharing)
Vue.component('downloadCsv', JsonCSV)

Vue.use(localStorage)
import VeeValidate from './validators';
Vue.use(VeeValidate, { fieldsBagName: 'formFields' })
Vue.use(Vuetify)
Vue.use(FontAwesomeIcon)
Vue.use(BootstrapVue)
Vue.use(ToggleButton)
Vue.use(PrettyCheckbox)
Vue.use(VueGoogleMaps, { load: { key: GOOGLE_MAPS_KEY } })
Vue.use(VModal)
Vue.use(underscore)
Vue.use(CKEditor)
Vue.use(VueSocialSharing)
Vue.use(CarouselPlugin)
Vue.use(HighchartsVue)
Vue.use(AOS)
Vue.use(vLinkify)
// Table Idle tracker: 30 minutes
const idleOptions = { idleTime: 55 * 60000, store: store}
Vue.use(IdleVue, idleOptions)

// add global filter for vue upload component
Vue.filter('formatSize', function (size) {
  if (size > 1024 * 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
  } else if (size > 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
  } else if (size > 1024 * 1024) {
    return (size / 1024 / 1024).toFixed(2) + ' MB'
  } else if (size > 1024) {
    return (size / 1024).toFixed(2) + ' KB'
  }
  return size.toString() + ' B'
})



/**
 * Add all constants into this for all object instances
 */
Vue.mixin({
  data: function() {
    if (!constants)
      return

    let instanceVariables = {};

    for(const constKey in constants) {
      if (constants.hasOwnProperty(constKey)) {
        for (const propKey in constants[constKey]) {
          if (constants[constKey].hasOwnProperty(propKey)) {
            instanceVariables[propKey] = constants[constKey][propKey]
          }
        }
      }
    }

    if (!helpers) {
      return instanceVariables
    }

    for(const helperKey in helpers) {
      if (helpers.hasOwnProperty(helperKey)) {
        for (const helperMethodKey in helpers[helperKey]) {
          if (helpers[helperKey].hasOwnProperty(helperMethodKey)) {
            instanceVariables[helperMethodKey] = helpers[helperKey][helperMethodKey]
          }
        }
      }
    }

    return instanceVariables
  }
})

// The root url of our application
export const appRootUrl = `${window.location.origin}`

// import Open-ID client library
import { OidcClient, Log, WebStorageStateStore } from 'oidc-client-ts'

// Log level is set for the OpenID client plugin
Log.setLogger(console)
Log.setLevel(Log.INFO)

// Configuration for Google OpenID Connect
// const googleOIDCSettings = {
//   authority: OIDC_GOOGLE_PROVIDER_URL,
//   client_id: OIDC_GOOGLE_CLIENT_ID,
//   redirect_uri: OIDC_GOOGLE_REDIRECT_URI,
//   response_type: 'code',
//   scope: 'openid email profile',
//   skipUserInfo: true,
//   automaticSilentRenew: false,
//   automaticSilentSignin: false,
//   // This is necessary to fetch the refresh_token for Google OpenID Connect
//   extraQueryParams: { access_type: 'offline', },
//   userStore: new Oidc.WebStorageStateStore({ store: window.sessionStorage }),
//   stateStore: new Oidc.WebStorageStateStore({ store: window.sessionStorage }),
//   staleStateAge: 60,
// }
// export const googleOIDC = new Oidc.OidcClient(googleOIDCSettings)
//
// // Configuration for SciStarter OpenID ConnectSorry
// const sciStarterOIDCSettings = {
//   authority: OIDC_SCISTARTER_PROVIDER_URL,
//   client_id: OIDC_SCISTARTER_CLIENT_ID,
//   redirect_uri: OIDC_SCISTARTER_REDIRECT_URI,
//   response_type: 'code',
//   scope: 'openid profile email participation',
//   skipUserInfo: true,
//   automaticSilentRenew: false,
//   automaticSilentSignin: false,
//   userStore: new Oidc.WebStorageStateStore({ store: window.sessionStorage }),
//   stateStore: new Oidc.WebStorageStateStore({ store: window.sessionStorage }),
//   staleStateAge: 60,
// }
// export const sciStarterOIDC = new Oidc.OidcClient(sciStarterOIDCSettings)

// Configuration for Airtable OpenID ConnectSorry
const airTableOAuthSettings = {
  authority: OIDC_AIRTABLE_PROVIDER_URL,
  client_id: OIDC_AIRTABLE_CLIENT_ID,
  redirect_uri: OIDC_AIRTABLE_REDIRECT_URI,
  response_type: 'code',
  scope: OIDC_AIRTABLE_SCOPES,
  skipUserInfo: true,
  automaticSilentRenew: false,
  automaticSilentSignin: false,
  stateStore: new WebStorageStateStore({ store: window.localStorage }),
  staleStateAgeInSeconds: 600,
  metadata: {"issuer":"https://airtable.com","authorization_endpoint":OIDC_AIRTABLE_PROVIDER_URL,"token_endpoint":" https://airtable.com/oauth2/v1/token"},
}

export const airTableOAuth = new OidcClient(airTableOAuthSettings)

//credit to @Bill Criswell for this filter
Vue.filter('truncate', function (text, stop, clamp) {
    return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
})

new Vue({
  el: '#app',
  store,
  router,
  render: h => h(App),
  mounted() {
    AOS.init()
  },
})
