import VeeValidate from 'vee-validate';

const validatorArrayMax = {
    getMessage(field, max) {
      // will be added to default locale messages.
      return `You may not add more than ${max}`
    },
    validate(value, {max}) {
      // Returns a Boolean or a Promise that resolves to a boolean.
      console.log(value)
      if(!Array.isArray(value)){
        console.log("Not Array")
        return false
      }
      const len = value.length 
      const returnValue = value.length <= max
      console.log("Is Array")
      console.log(returnValue)
      console.log(len)
      console.log(max)
      return returnValue
    }
  };

VeeValidate.Validator.extend('max_array',validatorArrayMax,{paramNames:['max']});

const validatorArrayMin = {
    getMessage(field, min) {
      // will be added to default locale messages.
      return `You must add more than ${min}`
    },
    validate(value, min) {
      // Returns a Boolean or a Promise that resolves to a boolean.
      if(!Array.isArray(value)){
        return false
      }
      return (value.length >= min)
    }
  };

VeeValidate.Validator.extend('min_array',validatorArrayMin,{paramNames:['min']});

export default VeeValidate
