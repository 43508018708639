// START import your helper file

import * as userHelper from './user_helper'
import * as dateFormatHelper from './date_format_helper'
import * as bitlyVueHelper from './bitly_vue_helper'
import * as sendDataToHeader from './send_data_to_header_helper'
import * as ellipsize from './ellipsize_helper'
import * as urlBuilder from './url_builder_helper'
import * as clickLoggedInUrl from './check_loggedin_helper'
import * as fileMimeTypeHelper from './file_mime_type_helper'

export const helpers = {
  // START export your example constants
  userHelper,
  dateFormatHelper,
  bitlyVueHelper,
  sendDataToHeader,
  ellipsize,
  urlBuilder,
  clickLoggedInUrl,
  fileMimeTypeHelper
}
