import SubmissionError from '../../../../error/SubmissionError'
import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

export const retrieveDatasheet = ({ commit }, id) => {
  commit(types.DATASHEET_RETRIEVE_TOGGLE_LOADING)

  return fetch(id)
    .then(response => response.json())
    .then((data) => {
      commit(types.DATASHEET_RETRIEVE_TOGGLE_LOADING)
      commit(types.DATASHEET_RETRIEVE_SET_RETRIEVED, data)
    })
    .catch((e) => {
      commit(types.DATASHEET_RETRIEVE_TOGGLE_LOADING)
      commit(types.DATASHEET_RETRIEVE_SET_ERROR, e.message)
    })
}

export const updateDatasheet = ({ commit, state }, datasheetDetails) => {
  commit(types.DATASHEET_UPDATE_SET_ERROR, '')
  commit(types.DATASHEET_UPDATE_TOGGLE_LOADING)
  commit(types.DATASHEET_UPDATE_SET_VIOLATIONS, '')

  return fetch(state.datasheet['@id'], {
    method: 'PUT',
    headers: new Headers({ 'Content-Type': 'application/ld+json' }),
    body: JSON.stringify({
      name: datasheetDetails.name,
      instructions: datasheetDetails.instructions,
      projection: datasheetDetails.projection,
      published: datasheetDetails.published
    })
  })
    .then(response => response.json())
    .then((data) => {
      commit(types.DATASHEET_UPDATE_TOGGLE_LOADING)
      commit(types.DATASHEET_UPDATE_SET_UPDATED, data)
    })
    .catch((e) => {
      commit(types.DATASHEET_UPDATE_TOGGLE_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.DATASHEET_UPDATE_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.DATASHEET_UPDATE_SET_ERROR, e.errors._error)

        return
      }

      // eslint-disable-next-line
      commit(types.DATASHEET_UPDATE_SET_ERROR, e.errors._error)
    })
}

export const updateDatasheetLocationFormat = ({ commit }, datasheet) => {
  commit(types.DATASHEET_RECORD_TOGGLE_LOADING)
  commit(types.DATASHEET_UPDATE_SET_VIOLATIONS, '')

  return fetch('/datasheets/' + datasheet.id, {
    method: 'PATCH',
    body: JSON.stringify({
      locationFormat: datasheet.locationStatus,
      allowCustomLocation: datasheet.locationStatus !== 1 })
  })
    .then(response => response.json())
    .then((data) => {
      if (data) {
        commit(types.DATASHEET_RECORD_TOGGLE_LOADING)
        commit(types.DATASHEET_LOCATIONS_SET_UPDATED, data)
      }
    })
    .catch((e) => {
      commit(types.DATASHEET_RECORD_TOGGLE_LOADING)
      commit(types.DATASHEET_UPDATE_SET_VIOLATIONS, e.errors)
    })

}

export const updateDatasheetLocations = ({ commit, state }, datasheet) => {

  if (state.datasheet && state.datasheet['@id']) {
    commit(types.DATASHEET_CHECK_LOCATION_EXISTENCE_SET_ERROR, '')
    commit(types.DATASHEET_RECORD_TOGGLE_LOADING)
    commit(types.DATASHEET_UPDATE_SET_VIOLATIONS, '')

    if (datasheet && datasheet.locations) {
      datasheet.locations.forEach((location, index) => {
        if (location.edited) {
          delete location["@id"]
          delete location["@type"]
          delete location.edited
        }

        if (location && location['@type'] === "DatasheetsLocations") {
          datasheet.locations[index] = location['@id']
        } else if (location && location['@type'] === "Area") {
          datasheet.locations[index] = {
            location: location['@id']
          }
        } else {
          datasheet.locations[index] = {
            location: location
          }
        }
      })
    }

    // Datasheet locations array is patched
    return fetch('/datasheets/' + datasheet.id, {
      method: 'PATCH',
      body: JSON.stringify({
        locations: datasheet.locations
      })
    })
      .then(response => response.json())
      .then((data) => {
        commit(types.DATASHEET_RECORD_TOGGLE_LOADING)
        commit(types.DATASHEET_LOCATIONS_SET_UPDATED, data)
      })
      .catch((e) => {
        commit(types.DATASHEET_RECORD_TOGGLE_LOADING)

        if (e instanceof SubmissionError) {
          commit(types.DATASHEET_UPDATE_SET_VIOLATIONS, e.errors)
          // eslint-disable-next-line
          commit(types.DATASHEET_CHECK_LOCATION_EXISTENCE_SET_ERROR, e.errors._error)

          return
        }

        // eslint-disable-next-line
        commit(types.DATASHEET_CHECK_LOCATION_EXISTENCE_SET_ERROR, e.errors._error)
      })

  } else {
    commit(types.DATASHEET_CHECK_LOCATION_EXISTENCE_SET_ERROR, "Server error")
  }
}

export const updateDatasheetDateFormat = ({ commit, state }, dateFormat) => {
  commit(types.DATASHEET_UPDATE_SET_ERROR, '')
  commit(types.DATASHEET_RECORD_TOGGLE_LOADING)
  commit(types.DATASHEET_UPDATE_SET_VIOLATIONS, '')

  return fetch(state.datasheet['@id'], {
    method: 'PATCH',
    headers: new Headers({ 'Content-Type': 'application/ld+json' }),
    body: JSON.stringify({
      dateFormat: dateFormat === 1 ? "date_hour_minutes" : dateFormat === 2 ? "datetime" : "date"
    })
  })
    .then(response => response.json())
    .then(() => {
      commit(types.DATASHEET_RECORD_TOGGLE_LOADING)
    })
    .catch((e) => {
      commit(types.DATASHEET_RECORD_TOGGLE_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.DATASHEET_UPDATE_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.DATASHEET_UPDATE_SET_ERROR, e.errors._error)

        return
      }

      // eslint-disable-next-line
      commit(types.DATASHEET_UPDATE_SET_ERROR, e.errors._error)
    })
}

export const updateDatasheetPublished = ({ commit, state }, published) => {
  commit(types.DATASHEET_UPDATE_SET_ERROR, '')
  commit(types.DATASHEET_RECORD_TOGGLE_LOADING)
  commit(types.DATASHEET_UPDATE_SET_VIOLATIONS, '')

  return fetch(state.datasheet['@id'], {
    method: 'PATCH',
    headers: new Headers({ 'Content-Type': 'application/ld+json' }),
    body: JSON.stringify({
      published: published
    })
  })
    .then(response => response.json())
    .then(data => {
      commit(types.DATASHEET_RECORD_TOGGLE_LOADING)
      commit(types.DATASHEET_PUBLISHED_SET_UPDATED, data)
    })
    .catch((e) => {
      commit(types.DATASHEET_RECORD_TOGGLE_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.DATASHEET_UPDATE_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.DATASHEET_UPDATE_SET_ERROR, e.errors._error)

        return
      }

      // eslint-disable-next-line
      commit(types.DATASHEET_UPDATE_SET_ERROR, e.errors._error)
    })
}

export const updateDatasheetRecords = ({ commit, state }, values) => {
  commit(types.DATASHEET_RECORD_SET_ERROR, '')
  commit(types.DATASHEET_RECORD_TOGGLE_LOADING)
  commit(types.DATASHEET_RECORD_SET_VIOLATIONS, '')

  const datasheetDetails = {}
  datasheetDetails.records = values.records

  if (!values.published || values.records.length === 0) {
    datasheetDetails.published = false
  }
  console.log('patch ds records')

  return fetch(state.datasheet['@id'],{
    method: 'PATCH',
    body: JSON.stringify(datasheetDetails)
  })
    .then((response) => {
      commit(types.DATASHEET_RECORD_TOGGLE_LOADING)
      return response.json()
    })
    .then((data) => {
      commit(types.DATASHEET_RECORD_SET_CREATED, data)
    })
    .catch((e) => {
      commit(types.DATASHEET_RECORD_TOGGLE_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.DATASHEET_RECORD_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.DATASHEET_RECORD_SET_ERROR, e.errors._error)
        return
      }

      commit(types.DATASHEET_RECORD_SET_ERROR, e.message)
    })
}

export const updateDatasheetSubRecords = ({ commit }, values) => {
  commit(types.DATASHEET_RECORD_SET_ERROR, '')
  commit(types.DATASHEET_RECORD_TOGGLE_LOADING)
  commit(types.DATASHEET_RECORD_SET_VIOLATIONS, '')

  console.log('patch ds subrecords')

  return fetch(values.id, {
    method: 'PATCH',
    headers: new Headers({ 'Content-Type': 'application/ld+json' }),
    body: JSON.stringify({ records: values.records })
  })
    .then(response => response.json())
    .then((data) => {
      commit(types.DATASHEET_RECORD_TOGGLE_LOADING)
      commit(types.DATASHEET_UPDATE_RECORDS_SET_UPDATED, data)
    })
    .catch((e) => {
      commit(types.DATASHEET_RECORD_TOGGLE_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.DATASHEET_RECORD_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.DATASHEET_RECORD_SET_ERROR, e.errors._error)
        return
      }

      commit(types.DATASHEET_RECORD_SET_ERROR, e.message)
    })
}

export const updateDatasheetRecordDetails = ({ commit }, values) => {
  commit(types.DATASHEET_UPDATE_RECORD_DETAILS_SET_ERROR, '')
  commit(types.DATASHEET_RECORD_TOGGLE_LOADING)
  commit(types.DATASHEET_UPDATE_SET_VIOLATIONS, '')

  console.log('patch ds record details')

  return fetch(values['@id'], {
    method: 'PATCH',
    headers: new Headers({ 'Content-Type': 'application/ld+json' }),
    body: JSON.stringify(values)
  })
    .then(response => response.json())
    .then((data) => {
      commit(types.DATASHEET_RECORD_TOGGLE_LOADING)
      commit(types.DATASHEET_TEXT_RECORD_SET_UPDATED, data)
    })
    .catch((e) => {
      commit(types.DATASHEET_RECORD_TOGGLE_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.DATASHEET_UPDATE_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.DATASHEET_UPDATE_RECORD_DETAILS_SET_ERROR, e.errors._error)

        return
      }

      // eslint-disable-next-line
      commit(types.DATASHEET_UPDATE_RECORD_DETAILS_SET_ERROR, e.errors)
    })
}

export const setDatasheetRecordInactive = ({ commit }, values) => {
  commit(types.DATASHEET_UPDATE_RECORD_DETAILS_SET_ERROR, '')
  commit(types.DATASHEET_RECORD_TOGGLE_LOADING)
  commit(types.DATASHEET_UPDATE_SET_VIOLATIONS, '')

  return fetch(values.id, {
    method: 'PATCH',
    headers: new Headers({ 'Content-Type': 'application/ld+json' }),
    body: JSON.stringify({isActive: false})
  })
    .then(response => response.json())
    .then((data) => {
      commit(types.DATASHEET_RECORD_TOGGLE_LOADING)
      // Treat this as a deleted record, don't set the record in the state
      // commit(types.DATASHEET_TEXT_RECORD_SET_UPDATED, data)
      return data
    })
    .catch((e) => {
      commit(types.DATASHEET_RECORD_TOGGLE_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.DATASHEET_UPDATE_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.DATASHEET_UPDATE_RECORD_DETAILS_SET_ERROR, e.errors._error)

        return
      }

      // eslint-disable-next-line
      commit(types.DATASHEET_UPDATE_RECORD_DETAILS_SET_ERROR, e.errors)
    })
}

export const addSelectedSpeciesToOrganismRecord = ({ commit }, values) => {
  commit(types.DATASHEET_UPDATE_SET_ERROR, '')
  commit(types.DATASHEET_RECORD_TOGGLE_LOADING)
  commit(types.DATASHEET_UPDATE_SET_VIOLATIONS, '')

  return fetch(values.id, {
    method: 'PATCH',
    headers: new Headers({ 'Content-Type': 'application/ld+json' }),
    body: JSON.stringify({
      allowAnyOrganism: values.allowAnyOrganism,
      organisms: values.organisms
    })
  })
    .then(response => response.json())
    .then((data) => {
      commit(types.DATASHEET_RECORD_TOGGLE_LOADING)
      commit(types.DATASHEET_ORGANISM_RECORD_SPECIES_SELECTED, data)
    })
    .catch((e) => {
      commit(types.DATASHEET_RECORD_TOGGLE_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.DATASHEET_UPDATE_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.DATASHEET_UPDATE_SET_ERROR, e.errors._error)

        return
      }

      // eslint-disable-next-line
      commit(types.DATASHEET_UPDATE_SET_ERROR, e.errors._error)
    })
}

export const retrieveOrganisms = ({ commit }, pagination) => {
  let queryString = ''

  if (pagination.page) {
    queryString += '?page=' + pagination.page
  }

  if (pagination.sortBy) {
    const sort = pagination.descending ? 'desc' : 'asc'

    queryString += pagination.page ? '&' : '?'
    queryString += 'order[' + pagination.sortBy + ']=' + sort
  }

  if (pagination.search) {
    queryString += pagination.page || pagination.sortBy ? '&' : '?'
    queryString += 'lowerScientificName=' + pagination.search + '&lowerCanonicalName=' + pagination.search
    //queryString += 'scientificName=' + pagination.search + '&canonicalName=' + pagination.search
  }

  const apiEndpoint = '/organisms' + queryString

  commit(types.DATASHEET_RETRIEVE_ORGANISMS_TOGGLE_LOADING)
  commit(types.DATASHEET_RETRIEVE_ORGANISMS_SET_ERROR, '')

  fetch(apiEndpoint)
    .then(response => response.json())
    .then(data => {
      commit(types.DATASHEET_RETRIEVE_ORGANISMS_TOGGLE_LOADING)
      commit(types.DATASHEET_RETRIEVE_ORGANISMS_SET_ITEMS, data['hydra:member'])
      commit(types.DATASHEET_RETRIEVE_ORGANISMS_SET_VIEW, data['hydra:view'])
    })
    .catch((e) => {
      commit(types.DATASHEET_RETRIEVE_ORGANISMS_TOGGLE_LOADING)
      commit(types.DATASHEET_RETRIEVE_ORGANISMS_SET_ERROR, e.message)
    })
}

export const retrieveLocations = ({ commit, state }) => {
  if (state.datasheetProject) {
    const apiEndpoint = state.datasheetProject['@id'] + '/locations?order[name]=asc&itemsPerPage=2000&page=1'

    commit(types.DATASHEET_RETRIEVE_LOCATIONS_TOGGLE_LOADING)
    commit(types.DATASHEET_RETRIEVE_LOCATIONS_SET_ERROR, '')

    return fetch(apiEndpoint)
      .then(response => response.json())
      .then(data => {
        commit(types.DATASHEET_RETRIEVE_LOCATIONS_TOGGLE_LOADING)
        commit(types.DATASHEET_RETRIEVE_LOCATIONS_SET_RETRIEVED, data['hydra:member'])
      })
      .catch((e) => {
        commit(types.DATASHEET_RETRIEVE_LOCATIONS_TOGGLE_LOADING)
        commit(types.DATASHEET_RETRIEVE_LOCATIONS_SET_ERROR, e.message)
      })
  }
}

export const checkLocationExistence = ({ commit }, location) => {

  const apiEndpoint = '/projects/' + location.projectId + '/locations?name=' + location.name + '&latitude=' + location.latitude + '&longitude=' + location.longitude
  commit(types.DATASHEET_CHECK_LOCATION_EXISTENCE_TOGGLE_LOADING)
  commit(types.DATASHEET_CHECK_LOCATION_EXISTENCE_SET_ERROR, '')

  return fetch(apiEndpoint)
    .then(response => response.json())
    .then(data => {
      commit(types.DATASHEET_CHECK_LOCATION_EXISTENCE_TOGGLE_LOADING)
      commit(types.DATASHEET_CHECK_LOCATION_EXISTENCE_SET_RETRIEVED, data)
    })
    .catch((e) => {
      commit(types.DATASHEET_CHECK_LOCATION_EXISTENCE_TOGGLE_LOADING)
      commit(types.DATASHEET_CHECK_LOCATION_EXISTENCE_SET_ERROR, e.message)
    })
}




export const checkLocationExistence2 = ({ commit, state }, {newLocation, locations}) => {
  commit(types.DATASHEET_CHECK_LOCATION_EXISTENCE_TOGGLE_LOADING)
  commit(types.DATASHEET_CHECK_LOCATION_EXISTENCE_SET_ERROR, '')
  // check local copy of locations first
  const hasLocation = locations.some((location)=>{
    return location.name === newLocation.name
    && location.latitude === newLocation.latitude
    && location.longitude === newLocation.longitude
  })
  if (hasLocation) {
    commit(types.DATASHEET_CHECK_LOCATION_EXISTENCE_TOGGLE_LOADING)
    commit(types.DATASHEET_CHECK_LOCATION_EXISTENCE_SET_ERROR, "This location has already been added to the datasheet.")
    return true;
  }

  // const apiEndpoint = '/areas?name=' + newLocation.name + '&latitude=' + newLocation.latitude + '&longitude=' + newLocation.longitude
  const apiEndpoint = state.datasheetProject['@id'] + '/locations?name=' + newLocation.name + '&latitude=' + newLocation.latitude + '&longitude=' + newLocation.longitude
  // check server for locations to get ID
  return fetch(apiEndpoint)
    .then(response => response.json())
    .then(data => {
      commit(types.DATASHEET_CHECK_LOCATION_EXISTENCE_TOGGLE_LOADING)
      commit(types.DATASHEET_CHECK_LOCATION_EXISTENCE_SET_RETRIEVED, data)
      if (data && data['hydra:member'] && data['hydra:member'].length > 0){
        return data['hydra:member'][0]
      } else {
        return false
      }
    })
    .catch((e) => {
      commit(types.DATASHEET_CHECK_LOCATION_EXISTENCE_TOGGLE_LOADING)
      commit(types.DATASHEET_CHECK_LOCATION_EXISTENCE_SET_ERROR, e.message)
      return false;
    })
}




export const fetchDatasheet = ({ commit }, id) => {
  commit(types.DATASHEET_SET_LOADING);
  return fetch('/datasheets/' + id)
    .then(response => response.json())
    .then((datasheet) => {
      commit(types.DATASHEET_SET_RETRIEVED, datasheet);
    })
    .catch((e) => {
      commit(types.DATASHEET_SET_ERROR, e.message);
    })
}

export const uploadDatasheet = ({ commit, state }, datasheet) => {
  if(state.datasheetIsLoading || state.datasheetIsUploading){
    console.log("Datasheet was loading or uploading when upload was triggered! This may lead to backend bugs.")
  }

  console.log('upload ds')

  commit(types.DATASHEET_SET_UPLOADING);
  return fetch(datasheet['@id'], {
    method: 'PUT',
    body: JSON.stringify(datasheet)
  })
    .then(response => response.json())
    .then((data) => {
      commit(types.DATASHEET_SET_RETRIEVED, data)
    })
    .catch((e) => {
      if (e instanceof SubmissionError) {
        commit(types.DATASHEET_SET_VIOLATIONS, {
          error: e.errors._error,
          violations: e.errors,
        });
        return;
      } else {
        commit(types.DATASHEET_SET_ERROR, e.message);
        return;
      }
    })
}
export const publishDatasheet = ({ commit, state, dispatch }) => {
  commit(types.DATASHEET_SET_PUBLISHING);

  dispatch('uploadDatasheet', {
    '@id': state.datasheet['@id'],
    published: !state.datasheet.published
  }).then(() => {
    commit(types.DATASHEET_SET_PUBLISHED);
  })
}
export const resetDatasheet = ({ commit }) => {
  commit(types.DATASHEET_RESET);
}
