<template>
  <div class="card-body pl-3" v-if="project">
    <div class="row">
      <div class="col-lg-9" style="border-right: solid 1px #dfdfdf">
        <!--<h2 class="mt-0">Welcome to {{ project.name }}</h2>-->

        <div class="mb-3 d-block">
          <h2 class="mt-1 mb-1">Welcome to {{ project.name }}</h2>
          {{ project.description }}
          <!-- <b-button v-b-modal.learn-more-modal size="sm" pill variant="tertiary">Learn More</b-button> -->
        </div>
        <div v-if="project.goals" class="mb-3">
          <h3>Goals</h3>
          {{ project.goals }}
          <!-- <b-button v-b-modal.learn-more-modal size="sm" pill variant="tertiary">Learn More</b-button> -->
        </div>
        <div v-if="project.tasks" class="mb-3">
          <h3>Tasks</h3>
          {{ project.tasks }}
        </div>

        <b-button v-if="project.gettingStarted" v-b-modal.learn-more-modal size="md" variant="tertiary">
          <span>
            <strong>Get Started</strong> &nbsp;
          </span>
          <i class="fas fa-arrow-alt-circle-right"></i>
        </b-button>

        <div v-if="observationsTotal > 0" class="mt-4 d-block">
          <ProjectPhotoObservations v-if="isPublicOrMember"></ProjectPhotoObservations>
          <ProjectMapLeaflet></ProjectMapLeaflet>
        </div>
      </div>

      <!-- Side bar -->
      <div class="col-lg-3 order-1">
        <div class="">

          <div v-if="project && project.user">
            <b-row>
              <b-col>
                <div class="text-center" style="">
                  <h4>Project Owner</h4>
                </div>
              </b-col>
            </b-row>

            <ul class="list-unstyled">
              <b-card class="">
                <b-media tag="li">
                  <template v-slot:aside>
                    <b-img :src="project.user.picture && project.user.picture.path
                        ? project.user.picture.path
                        : '/assets/images/default_avatar.png'
                      " rounded="circle" width="35" alt="placeholder"></b-img>
                  </template>
                  <h5 class="mt-0 mb-1">{{ project.user.displayName }}</h5>
                  <b-link @click="onContactManager">
                    <i class="fa fa-envelope"></i>&nbsp;Contact Manager
                  </b-link>
                </b-media>
              </b-card>
            </ul>
          </div>

          <!-- Org Affiliations -->
          <div class="m-n1" v-if="project.organizationProjects.length">
            <h4 class="text-center">Hub Affiliations</h4>
            <div>
              <router-link class='btn btn-primary mt-0 mb-2 d-block w-75 mx-auto'
                v-for="organization in project.organizationProjects.filter(op => op.approved).map(op => op.organization)"
                :key="organization.id" :to="{ name: 'OrganizationShow', params: { name: organization.url } }"
                title="Visit this Hub">
                <i class="fa fa-users" aria-hidden="true"></i> {{ organization.name }}
              </router-link>
            </div>
            <hr />
          </div>

          <!-- Download Project Dataset -->
          <div v-if="isPublicOrMember">
            <b-row>
              <b-col>
                <div class="text-center" style="">
                  <h4>Project Dataset</h4>
                </div>
              </b-col>
            </b-row>
            <b-card class="text-center">
              <b-button class="btn btn-tertiary mt-0 mb-2 mx-auto" id="DownloadDatasetButton" title="Download Dataset"
                alt="Download Dataset" :to="{ name: 'ProjectDownloads', params: { id: $route.params.id } }">
                <span class="d-none d-lg-inline text-white">
                  <strong>Download Dataset</strong>&nbsp;
                </span>
                <i class="fas fa-cloud-download-alt text-white"></i>
              </b-button>
            </b-card>
          </div>

          <div v-if="project.website" class="mt-3">
            <b-row>
              <b-col>
                <div class="text-center" style="">
                  <h4>External Website</h4>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-card class="text-center">
                  <a :href="project.website" target="NewWindow">{{ project.website }} <i
                      class="fas fa-external-link-alt"></i></a>
                </b-card>
              </b-col>
            </b-row>

          </div>

          <div v-if="project.facebookLink || project.twitterLink || project.instagramLink" class="mt-3">
            <b-row>
              <b-col>
                <div class="text-center" style="">
                  <h4>Follow Us</h4>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-card class="text-center">
                  <div class="row justify-content-around">
                    <a v-if="project.facebookLink" :href="project.facebookLink" target="NewWindow">
                      <font-awesome-icon :icon="['fab', 'facebook']" size="3x" />
                    </a>
                    <a v-if="project.instagramLink" :href="project.instagramLink" target="NewWindow">
                      <font-awesome-icon :icon="['fab', 'instagram']" size="3x" />
                    </a>
                    <a v-if="project.twitterLink" :href="project.twitterLink" target="NewWindow">
                      <font-awesome-icon :icon="['fab', 'x-twitter']" size="3x" />
                    </a>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </div>

          <!-- Integrations v-if="project.sciStarterID>0" -->

          <div v-if="project.sciStarterID > 0" class="mt-5">
            <b-row>
              <b-col>
                <div class="text-center" style="">
                  <h4>Find Us On SciStarter</h4>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-container class="text-center p-0">
                  <b-link :href="scistarterURL" target="NewWindow">
                    <b-img src="/assets/images/scistarter-affiliate-1200x332.png" class="SciStarterLogoLink"
                      alt="Find us on SciStarter" title="Find us on SciStarter" width="200" fluid>
                    </b-img>
                  </b-link>
                </b-container>
              </b-col>
            </b-row>
          </div>

          <b-row class="mt-5">
            <b-col>
              <div class="text-center mb-0">
                <h4>Use Our Mobile App!</h4>
              </div>
            </b-col>
          </b-row>

          <b-row class="d-flex justify-content-around">
              <b-button pill variant="outline-secondary" size="md" style="width: 30%" class="flex-none d-flex align-items-center justify-content-around"
                title="Available on Google Play for Android" alt="Available on Google Play for Android"
                href="https://play.google.com/store/apps/details?id=com.nrel.citsci&hl=en_US&gl=US" target="NewWindow">
                <font-awesome-icon :icon="['fab', 'android']" size="lg" class="flex-none" />Android
              </b-button>
            <div style="width: 15%"  class="flex-none">
              <router-link :to="{ name: 'Apps' }" alt="Learn more about our apps" title="Learn more about our apps">
                <b-img src="/assets/images/citSci_app_icon.png"
                  style="margin-top: 0; max-width: 100%; height: auto"></b-img>
              </router-link>
            </div>
              <b-button pill variant="outline-secondary" style="width: 30%" size="md"  class="flex-none d-flex align-items-center justify-content-around"
                title="Available on the App Store for iOS" alt="Available on the App Store for iOS"
                href="https://apps.apple.com/us/app/citsci/id657196305" target="NewWindow">
                <font-awesome-icon :icon="['fab', 'apple']" size="lg" class="flex-none" />Apple
              </b-button>
          </b-row>
        </div>
      </div>
    </div>
    <InviteUserModal @refresh-table=""></InviteUserModal>
    <LearnMoreModal></LearnMoreModal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import ProjectPhotoObservations from "./ProjectPhotoObservations.vue";
import InviteUserModal from "../members/inviteUserModal.vue";
import ProjectMapLeaflet from "../map/leaflet.vue";
import LearnMoreModal from "./LearnMoreModal.vue";

export default {
  props: {},
  components: {
    ProjectPhotoObservations,
    InviteUserModal,
    LearnMoreModal,
    ProjectMapLeaflet,
  },
  data() {
    return {};
  },
  watch: {
    datasheetCreated(datasheet) {
      if (!datasheet) {
        return;
      }
      this.$router.push({
        name: "DatasheetCreator",
        params: { id: datasheet.id }
      });
    },
  },
  computed: {
    ...mapGetters({
      isVerified: "user/profile/isVerified",
      userDataSession: "user/profile/sessionData",
      project: "project/get/project",
      projectId: "project/get/id",
      resourcesCard: "project/resources/resources",
      resourceShowCard: "project/get/resourceShowCard",
      datasheetCreated: "project/show/datasheetCreated",
      isManager: "project/get/isManager",
      isPublicOrMember: "project/get/isPublicOrMember",
    }),
    ...mapState({
      isLoadingStats: state => state.project.get.stats.isLoading,
      observationsTotal: state => state.project.get.stats.observationsTotal,
    }),
    scistarterURL() {
      const urlArray = this.project.urlField.split("/");
      return `https://www.scistarter.org/${urlArray[urlArray.length - 1]}`;
    },
  },
  methods: {
    ...mapActions({
      createDatasheet: "project/show/createDatasheet",
      getMapTypes: "project/resources/getMapTypes",
    }),

    /**  @function onCreateDatasheet */
    onCreateDatasheet() {
      // TODO: Don't specify the default values here.
      const dataSheet = {
        name: "",
        instructions: "",
        project: "projects/" + this.project.id,
        projection: "geographic",
        dateFormat: "date_hour_minutes",
        allowCustomLocation: true,
        published: false
      };
      this.createDatasheet(dataSheet);
    },

    /**  @function onInviteNewUser - click handler for invite new user button */
    onInviteNewUser() {
      this.$bvModal.show('invite-user-modal')
    },

    /** @function onContactManager - click handler for contacting project manager */
    onContactManager() {
      if (!this.userDataSession) {
        this.$root.$emit("bv::show::modal", "not-logged-in-modal");
      } else if (!this.isVerified) {
        this.$bvModal.show("not-verified-modal");
      } else {
        this.$root.$emit("bv::show::modal", "contact-project-manager");
      }
    }
  }
};
</script>

<!-- TODO: Migrate any Home specific styles here. -->
<style lang="scss" scoped>
.card-bg-color {
  background-color: #f5f4e8;
}
</style>
