<template>
  <div class="d-flex" :title="'New citizen science observation by '+ formattedObservationCard.observerName"
       :alt="'New citizen science observation'">
    <b-card tag="article" class="ml-0 mr-1 text-center pb-4 mb-4" no-body>
      <b-card-body>
        <div class="d-flex card-content" :id="'card-content-'">
          <div class="my-0 card-image-container overflow-hidden">
            <b-link :to="{ name: 'ObservationShow', params: { id: formattedObservationCard.id } }"
                    :title="'Observation made on ' + ellipsizeCharacters(displayFormattedDate(formattedObservationCard.observedAt),24)"
                    :alt="'Observation made on ' + ellipsizeCharacters(displayFormattedDate(formattedObservationCard.observedAt),24)">
              <b-card-img :src="formattedObservationCard.observationPicture" alt="Image" :top="true" :fluid="true"
                          class="pt-1 px-1" />
            </b-link>
          </div>

          <b-row class="mt-3 mx-1 green-band">
            <b-row class="ml-1 mt-1 font-weight-bold name-holder">{{ formattedObservationCard.observerName }}</b-row>
            <b-row class="avatar-holder mr-1 mx-0">
              <b-img thumnbnail rounded="circle"
                     :src="formattedObservationCard.observerPicture ? formattedObservationCard.observerPicture : '/assets/images/default_avatar.png'"
                     class="avatar border-white" />
            </b-row>
          </b-row>

          <b-row class="text-left my-0 mt-3">
            <b-col cols="1" class="ml-3">
              <i class="fas fa-binoculars"></i>
            </b-col>
            <b-col cols="10" class="details">
              <b-link :to="{ name: 'ObservationShow', params: { id: formattedObservationCard.id } }"
                      :title="'Observation made on ' + ellipsizeCharacters(displayFormattedDate(formattedObservationCard.observedAt),24)"
                      :alt="'Observation made on ' + ellipsizeCharacters(displayFormattedDate(formattedObservationCard.observedAt),24)">
                {{ ellipsizeCharacters(displayFormattedDate(formattedObservationCard.observedAt),24) }}
              </b-link>
            </b-col>
          </b-row>

          <b-row class="text-left my-0">
            <b-col cols="1" class="ml-3">
              <i class="fas fa-th"></i>
            </b-col>
            <b-col cols="10" class="details">
              <b-link :to="{ name: 'ProjectShow', params: { id: formattedObservationCard.projectURL } }"
                      :title="'Observation made for the ' + formattedObservationCard.projectName + ' project'"
                      :alt="'Observation made for the ' + formattedObservationCard.projectName + ' project'">
                {{ ellipsizeCharacters(formattedObservationCard.projectName,23) }}
              </b-link>
            </b-col>
          </b-row>
          <b-row class="text-left my-0">
            <b-col cols="1" class="ml-3">
              <font-awesome-icon :icon="['fa', 'map-marker-alt']" />
            </b-col>
            <b-col cols="10" class="details">
              <b-link :to="{ name: 'LocationShow', params: { id: formattedObservationCard.locationId } }"
                      :title="'Observation made at '+ formattedObservationCard.locationName"
                      :alt="'Observation made at '+ formattedObservationCard.locationName">
                {{ ellipsizeCharacters(formattedObservationCard.locationName,24) }}
              </b-link>
            </b-col>
          </b-row>
        </div>

      </b-card-body>
    </b-card>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
  props: {
    isProjectPage: {
      type: Boolean,
      required: true,
      default: false,
    },
    observation: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      project: "project/get/project",
    }),

    // Computed getter for formatted observation card
    formattedObservationCard() {

      // Return our formatted observation card with images
      if (this.observation) {

        let observationCardTemplate = {}
        let bFoundImg = false

        // set the observation id
        observationCardTemplate.id = this.observation.id

        // set the default observation image and then look for the first observation image
        observationCardTemplate.observationPicture = '/assets/images/generic_img.png'
        this.observation.records.forEach(rec => {
          if (rec.recordType === 'image' && rec.files && rec.files[0] && rec.files[0].path && !bFoundImg) {
            observationCardTemplate.observationPicture = rec.files[0].path 
            bFoundImg = true
          }
        })

        // set the observer's name
        observationCardTemplate.observerName = this.observation.observers[0].user.displayName

        // set the default observer profile image
        observationCardTemplate.observerPicture = '/assets/images/default_avatar.png'
        bFoundImg = false
        this.observation.observers.forEach(mem => {
          if (!bFoundImg && mem.user.picture) {
            observationCardTemplate.observerPicture = mem.user.picture.path
            bFoundImg = true
          }
        })

        // set the observed data
        observationCardTemplate.observedAt = this.observation.observedAt

        // set the project name and urlfield
        if (!this.isProjectPage) {
          observationCardTemplate.projectName = this.observation.project.name
          observationCardTemplate.projectURL = this.observation.project.urlField
        } else {
          observationCardTemplate.projectName = this.project.name
          observationCardTemplate.projectURL = this.project.urlField
        }

        // set the location id and name
        observationCardTemplate.locationId = this.observation.location.id
        observationCardTemplate.locationName = this.observation.location.name

        // Return the observation card
        return observationCardTemplate
      }
      return null
    },
  },
};
</script>

<!-- TODO: Migrate any Home specific styles here. -->
<style lang="scss">

.section {
  width: 100%;

  &.featured-project {
    @media screen and (max-width: 1050px) {
      padding-right: 4rem!important;
      padding-left: 4rem!important;
    }
  }

  &.observations {
    @media screen and (max-width: 1050px) {
      padding-right: 4rem!important;
      padding-left: 4rem!important;
    }

    .card {
      margin: 0px !important;
    }

    .card-body {
      justify-content: center;
      min-height: auto;
    }
  }

  .card-image-container {
    min-height: 150px;
    max-height: 150px;
  }

  .card-body {
    max-width: 235px; /* 243px */
    max-height: 250px; /* 300px */
    min-height: 250px; /* 300px */
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;

    .card-title {
      display: none;
    }

    .card-content, .card-btn {
      flex-direction: column;
      overflow: hidden;
    }

    .card-img-top {
      padding-bottom: 6px;
    }

    .green-band {
      background: #A3B266;
      padding: 0;
      margin-top:0px!important;
      max-height: 27px;
      color: #fff;
      justify-content: space-between;

      & .name-holder {
        margin-top: 2px;
      }

      & > .avatar-holder {
        text-align: right;
        margin-top: -15px;
        display:inline;
        // Small devices (landscape phones, 576px and up)
        @media (min-width: 576px) { display: none; }

        // Medium devices (tablets, 768px and up)
        @media (min-width: 768px) { display:inline; }

        // Large devices (desktops, 992px and up)
        @media (min-width: 992px) { display:inline; }

        // Extra large devices (large desktops, 1200px and up)
        @media (min-width: 1200px) { display:inline; }
      }
    }
  }
}

</style>
