<template>
    <div>
            <div class='position-relative'>
                  <div class="forum-photo" :style="{backgroundImage: `url(${file.path})`}"> </div>
                  <div class="overlay d-flex flex-column justify-content-between align-items-end p-1">
                    <div v-if="file.status === 'UPLOADING'" class="flex-none">
                            <div class='btn btn-light' disabled> <i class='fa fa-spinner fa-spin '></i> </div>
                          </div>
                    <div v-else class="flex-none">
                            <div class='btn btn-danger' @click="removeUpload(file)"> <i class='fa fa-trash'></i> </div>
                          </div>
                          <div class="flex-none w-100">
                            <div v-if="file.status == 'QUE'" class="progress" style="height: 5px;">
                              <div class="progress-bar bg-warning" role="progressbar" style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <div v-else-if="file.status == 'UPLOADING'" class="progress" style="height: 5px;">
                              <div class="progress-bar" role="progressbar" style="width: 50%;" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <div v-else-if="file.status == 'SUCCESS'" class="progress" style="height: 5px;">
                              <div class="progress-bar bg-success" role="progressbar" style="width: 100%;" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                          </div>

                  </div>
              </div>
          </div>
</template>
<script>


import {
    mapActions,
    mapGetters
} from "vuex";

export default {
    name: "RecordImage",

    props: {
        file: {
          type: Object,
          required: true
        },
    },
    methods: {
            ...mapActions({
                removeUpload: "file/observationImages/removeUpload",
            }),
        },
}

</script>
<style lang="css" scoped>

.forum-photo {
      width: 100%;
      height: 150px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-color: #000000;
  }
  .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
  }
</style>