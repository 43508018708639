<template>
  <div v-if="projectId" class="mt-3 border-top border-dark pt-3">
    <div class="card-body">
      <div class="d-flex flex-row mb-3">
        <h3>Manage Members</h3>
        <div class="flex-shrink-1 text-right">
          <button class="btn btn-primary" @click="onCSVDownload">
            <i class="fa fa-download text-white"></i>
            Download List
          </button>
          <download-csv
            :data="CSVMembers"
            :name="CSVFilename"
            ref="CSVGenerator"
            style="display: none;"
          >
          </download-csv>
          <button class="btn btn-primary" @click="() => { this.$bvModal.show('new-discussion-modal') }">
            <i class="fa fa-envelope text-white"></i>
            Email All
          </button>
        </div>
      </div>
      <b-row align-h="between" class="mb-3">
        <b-col cols md="3" class="my-1">
          Showing {{ fromItem }} to {{ toItem }} of {{ totalItems }} members
        </b-col>

        <b-col sm="2" md="3" class="my-1">
          <b-pagination
            :total-rows="totalItems"
            :per-page="perPage"
            v-model="currentPage"
            class="my-0 pagination-holder"
            prev-text="Prev"
            next-text="Next"
            align="fill"
          />
        </b-col>

        <b-col sm="2" md="3" class="my-1">
          <b-form-group
            label="Per Page"
            label-align="right"
            label-cols-sm="7"
            label-cols-md="8"
            label-for="perPageSelect"
            class="mb-0"
          >
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
              align="right"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <b-table
      show-empty
      stacked="md"
      id="projectMembersTable"
      ref="projectMembersTable"
      :items="projectMembers"
      :fields="tableFields"
      :current-page="currentPage"
      :per-page="perPage"
      sort-icon-left
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
    >
      <template v-slot:cell(member.firstName)="row">
        {{ row.item.firstName }}
      </template>
      <template v-slot:cell(member.lastName)="row">
        {{ row.item.lastName }}
      </template>
      <template v-slot:cell(member.email)="row">
        {{ row.item.email }}
      </template>
      <template v-slot:cell(options)="row">
        <div class="options-holder float-right">
<!--          <b-btn-->
<!--            size=""-->
<!--            class="my-1 mr-1"-->
<!--            @click="onClickAssignLocations(row.item)"-->
<!--            alt="Assign User to Locations"-->
<!--            title="Assign User to Locations"-->
<!--          >-->
<!--            <font-awesome-icon icon="tasks" /> Locations-->
<!--          </b-btn>-->
          <b-btn
            size=""
            class="my-1 mr-1"
            @click="onClickEditRole(row.item)"
            alt="Edit this Member's Role in the Project"
            title="Edit this Member's Role in the Project"
          >
            <font-awesome-icon icon="pen" /> Edit Role
          </b-btn>
          <b-btn
            size=""
            class="my-1 mr-1"
            @click="onClickDelete(row.item)"
            alt="Remove this User from the Project"
            title="Remove this User from the Project"
          >
            <font-awesome-icon icon="times" /> Remove
          </b-btn>
        </div>
      </template>
      <template #table-busy>
        <VueLoader style="height: 200px" text="Loading Members..." />
      </template>
    </b-table>
    <div class="card-footer">
      <b-row align-h="between">
        <b-col cols md="3" class="my-1">
          Showing {{ fromItem }} to {{ toItem }} of {{ totalItems }} members
        </b-col>

        <b-col sm="2" md="3" class="my-1">
          <b-pagination
            :total-rows="totalItems"
            :per-page="perPage"
            v-model="currentPage"
            class="my-0 pagination-holder"
            prev-text="Prev"
            next-text="Next"
            align="fill"
          />
        </b-col>

        <b-col sm="2" md="3" class="my-1">
          <b-form-group
            label="Per Page"
            label-align="right"
            label-cols-sm="7"
            label-cols-md="8"
            label-for="perPageSelect"
            class="mb-0"
          >
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              align="right"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <b-modal id="deleteMemberModal" title="Delete" centered>
      <p class="my-4" v-if="selectedMember">
        Do you want to remove {{ selectedMember.displayName }} from the project?
      </p>

      <template slot="modal-footer">
        <div>
          <b-btn variant="primary" @click="onConfirmDelete()">Yes</b-btn>
          <b-btn @click="closeModal">No</b-btn>
        </div>
      </template>
    </b-modal>
    <b-modal id="editMemberModal" title="Edit Member Role" centered>
      <div class="radio-button" v-if="selectedMember">
        <b-form-group id="roleOption">
          <b-row class="mb-1">
            <b-col cols="4">Member: </b-col>
            <b-col cols="8">{{ selectedMember.displayName }}</b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="4">Member Role: </b-col>
            <b-col cols="8">
              <b-form-radio-group
                id="user-role"
                v-model="selectedMember.role"
                name="userRole"
              >
                <b-row>
                  <b-col>
                    <b-form-radio value="member">Contributor</b-form-radio>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-radio value="manager">Manager</b-form-radio>
                  </b-col>
                </b-row>
              </b-form-radio-group>
            </b-col>
          </b-row>
        </b-form-group>
      </div>

      <template slot="modal-footer">
        <div>
          <b-btn variant="primary" @click="onConfirmEditRole()">Save</b-btn>
          <b-btn @click="closeModal">Cancel</b-btn>
        </div>
      </template>
    </b-modal>
<!--    <AssignLocations-->
<!--      :projectId="projectId"-->
<!--      :passedUserData="selectedMember"-->
<!--      :passedDatasheets="datasheets"-->
<!--      @close-assign-locations="closeAssignLocationsModal"-->
<!--    >-->
<!--    </AssignLocations>-->
    <NewDiscussionModal
      :is-email-members="true"
      @submit="onEmailAllMembers">
    </NewDiscussionModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AssignLocations from "./assignLocations";
import VueLoader from "../../../layout/Loader.vue";
import NewDiscussionModal from "../forum/NewDiscussionModal.vue";

export default {
  name: "projectMembersManage",
  components: {
    NewDiscussionModal,
    AssignLocations,
    VueLoader
  },

  data() {
    return {
      pageOptions: [10, 25, 50, 100, 250, 500],
      isLoading: false,
      showModal: null,
      selectedMember: null,
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      sortBy: "approvedAt",
      sortDesc: true,
      sortDirection: "desc",
      tableFields: [
        {
          key: "approvedAt",
          label: "Date Joined",
          tdClass: "color-gray",
          formatter: val => {
            return this.displayDate(val);
          },
          sortable: true,
          sortDirection: "asc"
        },
        // { key: "displayName", label: "Member Name", sortable: true },
        {
          key: "member.firstName",
          label: "First Name",
          sortable: true,
          sortDirection: "asc"
        },
        {
          key: "member.lastName",
          label: "Last Name",
          sortable: true,
          sortDirection: "asc"
        },
        {
          key: "member.email",
          label: "Email Address",
          sortable: true,
          sortDirection: "asc"
        },
        {
          key: "role",
          label: "Role",
          formatter: val => {
            return this.displayRole(val);
          },
          sortable: true,
          sortDirection: "asc"
        },
        { key: "options", label: "Options", sortable: false, class: "text-right" }
      ],
      allMembers: [],
      isDownload: false
    };
  },

  watch: {
    allProjectMembers(newValue) {
      // Generate CSV download
      if (newValue && newValue.length > 0 && this.isDownload) {
        this.isDownload = false;
        this.allMembers = this.allMembers.concat(
          newValue.map(member => {
            return {
              approvedat: this.displayDate(member.approvedAt),
              firstname: member.firstName,
              lastname: member.lastName,
              email: member.email,
              role: member.role === "manager" ? "manager" : "contributor"
            };
          })
        );
        if (this.allMembers.length === this.totalItems) {
          this.$nextTick(() => {
            this.$refs.CSVGenerator.generate();
          });
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      membershipMap: "user/profile/membershipMap",
      allProjectMembers: "project/show/projectMembers",
      projectId: "project/get/id",
      datasheets: "project/get/publishedDatasheets"
    }),
    fromItem() {
      return this.totalItems > 0
        ? (this.currentPage - 1) * this.perPage + 1
        : 0;
    },
    toItem() {
      return this.totalItems < this.currentPage * this.perPage
        ? this.totalItems
        : this.currentPage * this.perPage;
    },
    CSVFilename() {
      let currentDate = new Date();
      return `projectmembers_${currentDate.getFullYear()}-${currentDate.toLocaleString(
        "en",
        { month: "short" }
      )}-${currentDate.toLocaleString("end", {
        day: "2-digit"
      })}_${currentDate.getHours()}${currentDate.getMinutes()}${currentDate.getSeconds()}.csv`;
    },
    CSVMembers() {
      if (this.allMembers.length > 0) {
        return JSON.parse(JSON.stringify(this.allMembers));
      } else {
        return [];
      }
    }
  },

  methods: {
    ...mapActions({
      fetchProjectMembers: "project/show/fetchProjectMembers",
      deleteProjectMember: "project/show/deleteProjectMember",
      updateProjectMember: "project/show/updateProjectMember"
    }),
    projectMembers(ctx) {
      ctx.sortBy = this.sortBy;
      let urlParams = `${this.urlBuilder(ctx)}&approved=true`;
      const data = { projectId: this.projectId, urlParams };
      return this.fetchProjectMembers(data).then(data => {
        this.totalItems = data["hydra:totalItems"];
        this.currentPage = ctx.currentPage || 1;
        // this.sortBy = ctx.sortBy || "";
        return data["hydra:member"].map(member => {
          return {
            ...member
          };
        });
      });
    },
    onClickAssignLocations(member) {
      this.selectedMember = { ...member };
      this.$bvModal.show("edit-member");
    },
    onClickEditRole(member) {
      this.selectedMember = { ...member };
      this.$bvModal.show("editMemberModal");
    },
    onClickDelete(member) {
      this.selectedMember = { ...member };
      this.$bvModal.show("deleteMemberModal");
    },
    closeAssignLocationsModal(data) {
      this.$bvModal.hide("edit-member");
      // Refresh the table when assigned locations are changed
      if (data) {
        this.$refs.projectMembersTable.refresh();
      }
      this.selectedMember = null;
    },
    closeModal() {
      this.$bvModal.hide("editMemberModal");
      this.$bvModal.hide("deleteMemberModal");
      this.selectedMember = null;
    },
    onConfirmEditRole() {
      let sendData = {
        projectId: this.projectId,
        id: this.selectedMember.id,
        role: this.selectedMember.role
      };
      this.updateProjectMember(sendData).then(() => {
        this.$refs.projectMembersTable.refresh();
      });
      this.closeModal();
    },
    onConfirmDelete() {
      this.deleteProjectMember({
        userData: this.selectedMember,
        projectId: this.projectId
      }).then(() => {
        this.$refs.projectMembersTable.refresh();
      });
      this.closeModal();
    },
    onInviteNewUser() {
      this.$root.$emit("bv::show::modal", "invite-new-user");
    },
    onEmailAllMembers() {
      // // Emit parent method
      // // this.$emit("email-all-members");
      // this.$router.push({
      //   name: "ProjectForum",
      //   params: { isEmailMembers: true }
      // });

    },
    async onCSVDownload() {
      // Generate CSV download of all project members
      // Make call to fetch all members
      // - if total members is large, fetch over multiple requests
      this.allMembers = [];
      let maxFetch = 600;
      if (this.totalItems > maxFetch) {
        for (let i = 0; i < this.totalItems / maxFetch; i++) {
          let ctx = {
            sortBy: this.sortBy,
            sortDesc: this.sortDesc,
            perPage: maxFetch,
            currentPage: i + 1
          };
          let urlParams = `${this.urlBuilder(ctx)}&approved=true`;
          const data = { projectId: this.projectId, urlParams };
          this.isDownload = true;
          await this.fetchProjectMembers(data);
        }
      } else {
        let ctx = {
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
          perPage: this.totalItems,
          currentPage: 1
        };
        let urlParams = `${this.urlBuilder(ctx)}&approved=true`;
        const data = { projectId: this.projectId, urlParams };
        this.isDownload = true;
        this.fetchProjectMembers(data);
      }
    }
  },
  mounted() {
    this.$root.$on("pendingMemberApproved", () => {
      // Reload the table when a project member join request is approved
      this.$refs.projectMembersTable.refresh()
    });
  }
};
</script>

<style></style>
