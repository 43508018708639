//
// using the store for this makes things more complicated,
// however it will allow GLOBAL uploads in the future & parellel uploads
// in the future
//

import SubmissionError from '../../../error/SubmissionError'
import fetch from '../../../utils/fetch'
import { isUploading } from '../project/show/getters'


const initialState = {
    filesQue: [],
    fileUploading: null,
    filesComplete: [],
    filesError: [],
}

const ACTION_TYPES = {
    // currently supported action types by this store
    observation_image: "observation_image",
}

const uploadTemplate = {
    status: null, // QUE, UPLOADING, ERROR, SUCCESS
    progress: 0, // 0-100
    //autoUpload: false, // upload immediately when added
    action_type: ACTION_TYPES.observation_image, // upload type

    path: null, // either local or server URL, used as the unique identifier regardless of status
    marker: null, // grouped together by marker
    observation: null, // grouped together by observation
    blob: null, // will be converted to approriate file for upload

    name: null, // file name from client or server

    '@id': null, // server side IRI
    id: null, // server side UUID
    size: null, // file size from server
    mime_type: null, // file mime type from server
    created_at: null, // server side created at
    updated_at: null, // server side updated at

    error: null, // error message
}

const FILE_STATUS = {
    QUE: "QUE",
    UPLOADING: "UPLOADING",
    ERROR: "ERROR",
    SUCCESS: "SUCCESS",
}


export const types = {
    ADD_FILE: "ADD_FILE",
    ADD_FILES: "ADD_FILES",
    START_UPLOAD: "START_UPLOAD",
    FINISH_UPLOAD: "FINISH_UPLOAD",
    ERROR_UPLOAD: "ERROR_UPLOAD",
    REMOVE_UPLOAD: "REMOVE_UPLOAD",
    CLEAR_UPLOADS: "CLEAR_UPLOADS"
}

const getters = {
    countQue(state){
        return state.filesQue.length
    },
    countComplete(state){
        return state.filesComplete.length
    },
    countError(state){
        return state.filesError.length
    },
    count(state){
        return state.filesQue.length + state.filesComplete.length + state.filesError.length
    }, 
    uploads(state){
        if(state.fileUploading){
            return [...state.filesComplete, state.fileUploading, ...state.filesQue]
        }
        return [...state.filesComplete, ...state.filesQue]
    },
    isUploading(state){
        if(state.fileUploading){
            return true
        }
        if(state.filesQue.length !== 0){
            return true
        }
    }
}

const mutations = {
    [types.ADD_FILES](state, files) {
        files.forEach((file) => {
            state.filesQue.push({
                ...uploadTemplate,
                ...file,
                status: FILE_STATUS.QUE,
            })
        })
    },
    [types.ADD_FILE](state, file) {
        state.filesQue.push({
            ...uploadTemplate,
            ...file,
            status: FILE_STATUS.QUE,
        })
    },
    [types.START_UPLOAD](state, file) {
        state.fileUploading = {
            ...uploadTemplate,
            ...file,
            status: FILE_STATUS.UPLOADING,
        }
    },
    [types.FINISH_UPLOAD](state, payload) {
        const uploadNew = {
            ...uploadTemplate,
            ...payload,
            status: FILE_STATUS.SUCCESS,
            progress: 100,
        }
        state.fileUploading = null
        state.filesComplete.push(uploadNew)
    },
    [types.ERROR_UPLOAD](state, payload) {
        const uploadError = {
            status: FILE_STATUS.ERROR,
            progress: 100,
            error: payload.error instanceof SubmissionError
                ? payload.errors._error
                : payload.error.message
        }
        state.filesComplete.push(uploadNew)
    },
    [types.REMOVE_UPLOAD](state, file) {
        state.filesQue = state.filesQue.filter((f) => f.path !== file.path)
        state.filesComplete = state.filesComplete.filter((f) => f.path !== file.path)
    },
    [types.CLEAR_UPLOADS](state) {
        console.log('CLEAR_UPLOADS')
        const currentState = state
        state.filesQue = [],
        state.fileUploading = null,
        state.filesComplete = [],
        state.filesError = [],

        currentState.filesQue.forEach((file) => {
            URL.revokeObjectURL(file.path) // frees up browser memory for image
        })
        currentState.filesError.forEach((file) => {
            URL.revokeObjectURL(file.path) // frees up browser memory for image
        })
    }
}

const actions = {
    // will take an array of objects or a single upload
    uploadFiles({commit, dispatch, state}, files){
        console.log('uploadFiles')
        if(!Array.isArray(files)){
            files = [files]
        }
        files = files.map((file)=>{return {
            ...uploadTemplate,
            ...file,
            stats: FILE_STATUS.QUE,
            path: URL.createObjectURL(file.blob),
        }})

        if(state.fileUploading === null){
            let firstUpload = files.shift()
            dispatch('uploadFile', firstUpload)
        }
        commit(types.ADD_FILES, files)
    },
    async uploadFile({ commit, dispatch, state }, file) {
        commit(types.START_UPLOAD, file)

        // build form data
        const formData = new FormData()
        formData.append('file', file.blob, file.name)
        formData.append('actionType', file.action_type)

        const fetchPromise = fetch('/file_objects', {
            method: 'POST',
            body: formData
        }).then((response) => {
            return response.json()
        }).then((data) => {
            commit(types.FINISH_UPLOAD, {...file, ...data })
        }).catch((error) => {
            commit(types.ERROR_UPLOAD, {...file, error: error })
        }).finally(() => {
            URL.revokeObjectURL(file.path) // frees up browser memory for image after uploaded
            if(state.filesQue.length > 0 ){
                let nextUpload = state.filesQue.shift()
                dispatch('uploadFile', nextUpload)
            }
        })
    },
    removeUpload({ commit, dispatch }, file) {
        if(!file['@id']){
            // file does not exist on server
            commit(types.REMOVE_UPLOAD, file)
            URL.revokeObjectURL(file.path) // frees up browser memory for image
            return;
        }
        dispatch('removeUploadFromServer', file)
    },
    async removeUploadFromServer({ commit }, file) {
        const fetchPromise = fetch(file['@id'], {
            method: 'DELETE'
        }).then((response) => {
            commit(types.REMOVE_UPLOAD, file)
        }).catch((error) => {
            commit(types.REMOVE_UPLOAD, file)
            commit(types.ERROR_UPLOAD, {...file, error: error })
        })
    },
    clearUploads: ({ commit }) => {
        commit(types.CLEAR_UPLOADS)
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters: getters,
    actions: actions,
    mutations: mutations,
}
