<template>
    <div v-html="cleanedHtml"></div>
</template>
<script>

import DOMPurify from 'dompurify';

export default {
    name: 'RichText',
    props: {
        unsafeHTML: {
            type: String,
            required: true,
        },
    },
    computed: {
        cleanedHtml() {
            return DOMPurify.sanitize(this.unsafeHTML, {
                USE_PROFILES: {
                    html: true
                }
            });
        },
    },
};

</script>
