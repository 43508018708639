import SubmissionError from '../../../../error/SubmissionError'
import fetch from '../../../../utils/fetch'

const getInitialState = () => {
  return {
    uploadIsLoading: false,
    uploadedFile: null,
    uploadError: null,
  }
}

const state = getInitialState();

const getters = {
  uploadIsLoading(state) {
    return state && state.uploadIsLoading
  },
  uploadError(state) {
    return state && state.uploadError
  },
  retrievedFile(state) {
    return state && state.uploadedFile
  },
}

const actions = {
  uploadFile({commit}, formData) {
    commit(types.FILE_UPLOAD_TOGGLE_LOADING)
    const recordId = formData.get('recordId')
    formData.delete('recordId')

    return fetch('/file_objects', {
      method: 'POST',
      body: formData
    })
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        if (data.path) {
          commit(types.FILE_UPLOAD_SET_RETRIEVED, {
            recordId: recordId,
            data: data
          })
        }
      })
      .catch((e) => {
        if (e instanceof SubmissionError) {
          commit(types.FILE_UPLOAD_SET_ERROR, e.errors._error)
          return
        }
        commit(types.FILE_UPLOAD_SET_ERROR, e.message)
      })
  },

  deleteFile({commit}, id) {
    commit(types.FILE_UPLOAD_TOGGLE_LOADING)
    return fetch('/file_objects/' + id, {method: 'DELETE',})
      .then((response) => {
        return response.status === 204
      })
      .then((isDeleted) => {
        // commit(types.FILE_UPLOAD_RESET)
        return isDeleted
      })
      .catch((e) => {
        if (e instanceof SubmissionError) {
          commit(types.FILE_UPLOAD_SET_ERROR, e.errors._error)
          return
        }
        commit(types.FILE_UPLOAD_SET_ERROR, e.message)
      })
  },
}

const types = {
  FILE_UPLOAD_TOGGLE_LOADING: 'FILE_UPLOAD_TOGGLE_LOADING',
  FILE_UPLOAD_SET_RETRIEVED: 'FILE_UPLOAD_SET_RETRIEVED',
  FILE_UPLOAD_SET_ERROR: 'FILE_UPLOAD_SET_ERROR',
  FILE_UPLOAD_RESET: 'FILE_UPLOAD_RESET',
  FILE_UPLOAD_RESET_ERROR: 'FILE_UPLOAD_RESET_ERROR',
}

const mutations = {
  [types.FILE_UPLOAD_TOGGLE_LOADING](state, value) {
    state.uploadIsLoading = value ? value : true
  },
  [types.FILE_UPLOAD_SET_RETRIEVED](state, file) {
    state.uploadedFile = file;
    state.uploadIsLoading = false;
  },
  [types.FILE_UPLOAD_SET_ERROR](state, error) {
    state.uploadError = error;
    state.uploadIsLoading = false;
  },
  [types.FILE_UPLOAD_RESET_ERROR](state) {
    state.uploadError = null;
    state.uploadIsLoading = false;
  },
  [types.FILE_UPLOAD_RESET](state) {
    Object.assign(state, getInitialState());
  },
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
}
