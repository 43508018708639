<template>
    <div class='bg-light border-bottom row no-gutters'>
        <RecordImage v-if="countPhotos > 0 && max === 1" :file="files[0]" class='col-lg-12 col-md-12 col-sm-12'>
        </RecordImage>
        <RecordImage v-else-if="max === 2" class='col-lg-6 col-md-6 col-sm-6' v-for="file in files" :key="file.id" :file="file">
        </RecordImage>
        <RecordImage  v-else class='col-lg-3 col-md-6 col-sm-6' v-for="file in files" :key="file.id":file="file">
        </RecordImage>
        <div v-if="countMorePhotos > 0" class="col" @drop.stop="event => dropFiles(event)" @dragover.stop.prevent="event => dragEnterFiles(event)" @dragleave.stop.prevent="event => dragLeaveFiles(event)">
            <div :ref="refName" class='d-flex flex-column justify-content-center py-3 px-1 h-100 forum-photo-add text-center' :class="{'isDragging':isDragging}">
                <label :for="refName" class="file-upload-label"></label>
                <input v-if="this.countMorePhotos > 1" :id="refName" type="file" class="file-upload-input" accept="image/*" multiple @input="event => inputFiles(event)"></input>
                <input v-else :id="refName" type="file" class="file-upload-input" accept="image/*" @input="event => inputFiles(event)"></input>
                <div class='flex-none'>
                    <div class=''>
                        <i class="fa fa-plus fa-2x pr-3"></i><i class='fa fa-image fa-2x'></i>
                    </div>
                    <div class='bold'>{{howManyPhotos}}</div>
                    <div class='small'> or drag & drop </div>
                </div>
            </div>
        </div>
        <div v-if="hasErrors" class="col-12">
            <i class="fa fa-warning mr-3"></i>
            <span v-if="triedUpload && this.isPreview">Photos are not uploaded in preview.</span>
            <span v-if="filesTooMany.length > 0">You may only upload {{this.max}} photos. 
                <span v-for="err in filesTooMany" :key="err"  class="text-danger" role="alert">
                {{err}},
                </span>
                <span v-if="filesTooMany.length > 1"> have not been included. </span>
                <span v-else> has not been included. </span>
            </span>
            <span v-if="filesWrongType.length > 0">Photos must be .jpg, .jpeg, or .png.
                <span v-for="err in filesWrongType" :key="err"  class="text-danger" role="alert">
                {{err}},
                </span>
                <span v-if="filesWrongType.length > 1"> are not photos. </span>
                <span v-else> is not a photo. </span>
            </span>
            <span v-if="filesTooLarge.length > 0">Photos must be less than {{formatBytes(sizeLimit)}} each. 
                <span v-for="err in filesTooLarge" :key="err"  class="text-danger" role="alert">
                {{err}},
                </span>
                <span v-if="filesTooLarge.length > 1"> are too large. </span>
                <span v-else> is too large. </span>
            </span>
        </div>
    </div>
  </template>
  
  <script>
  function getRandomIntInclusive(min, max) {
    const minCeiled = Math.ceil(min);
    const maxFloored = Math.floor(max);
    return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled); // The maximum is inclusive and the minimum is inclusive
  }

  import {
      mapActions,
      mapGetters
  } from "vuex";

  import RecordImage from "./RecordImage.vue";
  
  export default {
      name: "RecordImages",
      components: {
        RecordImage
      },
      props: {
          record: {
            type: Object,
            required: true
          },
          value: {
            type: Array,
            required: false,
          },
          isPreview: {
            type: Boolean,
            default: false
          },
      },
  
      provide () {
        return { parentValidator: this.$validator }
      },
      data() {
          return {
              refName: `upload${getRandomIntInclusive(1,1000)}`,
              sizeLimit: 1024 * 1024 * 10,
              acceptedTypes: ['image/png', 'image/jpeg', 'image/jpg'],
              filesWrongType: [],
              filesTooLarge: [],
              filesTooMany: [],
              triedUpload: false,
              isDragging: false,
          }
      },
      computed: {
          ...mapGetters({
              uploads: "file/observationImages/uploads",
          }),
          marker(){
              return this.record.marker || `this.record['@id']${getRandomIntInclusive(1,10000)}`;
          },
          files() {
              if(this.uploads.length === 0){
                  return []
              }
              return this.uploads.filter((upload) => {
                  return upload.marker === this.marker;
              });
          },
          isUploading(){
              return this.files.some((file) => {
                  return ["QUE", "UPLOADING"].includes(file.status);
              });
          },
          hasErrors(){
            return this.filesWrongType.length > 0 || this.filesTooLarge.length > 0 || this.filesTooMany.length > 0 || (this.triedUpload && this.isPreview);
          },
          isRequired(){
            const requiredValidator = this.record.validators.find((v) => {
              return v.validatorType === "isRequired";
            });
            return (requiredValidator && requiredValidator.value) ? true : false;
          },
          min(){
            const minValidator = this.record.validators.find((v) => {
              return v.validatorType === "min";
            });
            return (minValidator && minValidator.value) ? parseInt(minValidator.value) : 0;
          },
          max(){
            const maxValidator = this.record.validators.find((v) => {
              return v.validatorType === "max";
            });
            return (maxValidator && maxValidator.value) ? parseInt(maxValidator.value) : 1;
          },
          countPhotos(){
            return this.files.length;
          },
          countMorePhotos(){
            return this.max - this.countPhotos;
          },
          howManyPhotos() {
              if(this.isRequired && this.max === 1 && this.countPhotos === 0){
                return `Click to add exactly 1 photo`
              }
              else if(this.isRequired && this.countPhotos === 0){
                return `Click to add 1 to ${this.countMorePhotos} photos`
              } else if (this.max === 1){
                return `Click to add a photo`
              }
              else {
                return `Click to add up to ${this.countMorePhotos} photos`
              }
          },
        //   files: {
        //       get() {
        //           return this.vuexFiles;
        //       },
        //       set(files) {
        //           this.addFiles(files);
        //       }
        //   }
      },
  
      methods: {
          ...mapActions({
              uploadFiles: "file/observationImages/uploadFiles",
              removeUpload: "file/observationImages/removeUpload",
          }),
          dropFiles(event){
            event.preventDefault();
            if(!event || !event.dataTransfer || !event.dataTransfer.files){
              return;
            }
            this.checkFiles(event.dataTransfer.files);
          },
          inputFiles(event){
            if(!event || !event.target || !event.target.files ){
              return;
            }
            this.checkFiles(event.target.files);

          },
          dragEnterFiles(event){
            event.preventDefault();
            this.isDragging = true;
          },
          dragLeaveFiles(event){
            event.preventDefault();
            this.isDragging = false;
          },
          checkFiles(eventFiles){
            this.filesWrongType = [];
            this.filesTooLarge = [];
            this.filesTooMany = [];
            this.triedUpload = true;
            this.isDragging = false;
            // .filter((file) => this.shouldAddFile(file))
            const goodFiles = []
            // for checking if too many files
            let newFilesCount = 0;
            eventFiles.forEach((singleFile) => {
              const shouldAdd = this.shouldAddFile(singleFile, newFilesCount)
              newFilesCount++;
              if(!shouldAdd){
                return;
              }
              goodFiles.push({
                  blob: singleFile,
                  marker: this.marker
                })
            })
            if(this.isPreview){
              return
            }
            if(goodFiles.length === 0){
              return
            }
            this.uploadFiles(goodFiles);
          },
          shouldAddFile(fileBlob, newFilesCount){
            if(this.files.length + 1 + newFilesCount > this.max){
                this.filesTooMany.push(fileBlob.name);
                return false;
            }
            if(fileBlob.size > this.sizeLimit){
                    this.filesTooLarge.push(fileBlob.name);
                    return false;
                }
            if(!this.acceptedTypes.includes(fileBlob.type)){
                this.filesWrongType.push(fileBlob.name);
                return false;
            }
            return true
          },
          formatBytes(bytes, decimals = 2) {
            if (!+bytes) return '0 Bytes'

            const k = 1024
            const dm = decimals < 0 ? 0 : decimals
            const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

            const i = Math.floor(Math.log(bytes) / Math.log(k))

            return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
          },
      },
      watch: {
        files: function (newFiles){
          // change if removed or added
          // triggers for each image question, so might lead to performance issued with many questions.
          const newIds = newFiles.filter((file)=>{
            return file["@id"] !== null
          }).map((file) => {
              return  file["@id"]
            });
          this.record.files = newIds
          this.$emit('input', newIds);    
        },

      }
  };
  </script>
  
  <style lang="css" scoped>
    .forum-photo-add {
        border: 2px solid #dddddd;
        position: relative;
        height: 150px;
    }
    
    .forum-photo-add:hover {
        border: 2px solid #666666;
        transition: all .5s ease 0.1s
    }

  .file-upload-label {
    background: #fff;
    opacity: 0;
    font-size: 20em;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .file-upload-input {
    background: rgba(255, 255, 255, 0);
    overflow: hidden;
    position: fixed;
    width: 1px;
    height: 1px;
    z-index: -1;
    opacity: 0;
  }
  
  .isDragging {
    border: 2px solid #666666;
    transition: all .5s ease 0.1s
  }
  </style>
  