import fetch from '../../../../utils/fetch'

const getInitialState = () => {
  return {
    isLoading: false,
    error: null,
  }
}

const state = getInitialState();

const getters = {
  isLoading(state) {
    return state.isLoading
  },
  error(state) {
    return state.error
  },
}

const actions = {
  create({ commit, dispatch }, answers) {
    let projectId = answers.projectId
    if (!['f09a68fd-a085-4d6b-890a-5dbc6b440604', '8543085c-2690-4bd9-8fea-bea23f5d8133'].includes(projectId)) return
    commit(types.SET_PROJECT_JOIN_LOADING, true)
    return fetch(('/projects/' + projectId + '/join'), {
      method: 'POST',
      body: JSON.stringify(answers)
    })
      .then(response => response.json())
      .then((join) => {
        if (join) {
          dispatch('user/profile/fetchSession', null, {root: true})
          commit(types.SET_PROJECT_JOIN_LOADING, false)
          return true
        }
      })
      .catch((error) => {
        commit(types.SET_PROJECT_JOIN_ERROR, error.message)
      })
  },
  reset(commit) {
    commit(types.RESET)
  }
}

const types = {
  SET_PROJECT_JOIN_LOADING: 'SET_PROJECT_JOIN_LOADING',
  SET_PROJECT_JOIN_ERROR: 'SET_PROJECT_JOIN_ERROR',
  RESET: 'RESET',
}

const mutations = {
  [types.SET_PROJECT_JOIN_LOADING](state, value) {
    state.isLoading = value;
  },
  [types.SET_PROJECT_JOIN_ERROR](state, error) {
    state.error = error;
    state.isLoading = false;
  },
  [types.RESET](state) {
    Object.assign(state, getInitialState());
  },
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
}
