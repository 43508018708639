<template>
  <b-modal id="edit-project-resource"
           title="Edit Project Resource"
           ref="editProjectResource"
           size="md"
           button-size="sm"
           hide-header-close
           no-close-on-backdrop
           no-close-on-esc
           no-stacking
           centered
           @cancel="resetModal">

    <div class="container mb-2 p-0">
      <!-- Name, Type -->
      <b-card bg-variant="light">
        <b-form-group
          id="resource-name"
          label-cols-lg="3"
          content-cols-lg="9"
          label="Name:"
          label-class="font-weight-bold pt-0"
          label-for="input-name"
          description="Name of this Resource">
          <b-form-input id="input-name"
                        name="name"
                        v-model="editedResource && editedResource.name"
                        placeholder="Enter a name"
                        v-validate="'required|min:5|max:220'"
                        data-vv-as="Resource Name"
                        :class="{ 'is-invalid': errors.has('name') }"
                        aria-describedby="resource-name-input-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="resource-name-input-live-feedback">
            {{ errors.first("name") }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="resource-type"
          label-cols-lg="3"
          content-cols-lg="9"
          label="Type:"
          label-class="font-weight-bold pt-0"
          label-for="input-type"
          description="Is this Resource a File, a Link, or a Map Layer (hosted)?">
          <b-form-radio-group
            id="input-type"
            name="type"
            v-model="editedResource && editedResource.type"
            :options="resourceTypeOptions"
            v-validate="'required'"
            data-vv-as="Resource Type"
            :class="{ 'is-invalid': errors.has('type') }"
            aria-describedby="resource-type-input-live-feedback"
          ></b-form-radio-group>
          <b-form-invalid-feedback id="resource-type-input-live-feedback">
            {{ errors.first("type") }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group v-if="editedResource && editedResource.type === 'link'"
                      id="resource-url"
                      label-cols-lg="3"
                      content-cols-lg="9"
                      label="Link/URL:"
                      label-class="font-weight-bold pt-0"
                      label-for="input-url"
                      description="Link/URL for this Resource">
          <b-input-group>
            <b-form-input id="input-url"
                          name="url"
                          v-model="editedResource && editedResource.url"
                          placeholder="resource.url.com"
                          v-validate="'required|min:5|max:500'"
                          data-vv-as="Resource URL"
                          :class="{ 'is-invalid': errors.has('url') }"
                          aria-describedby="resource-url-input-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="resource-url-input-live-feedback">
              {{ errors.first("url") }}
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
        <b-form-group v-if="editedResource && editedResource.type === 'raster'"
                      id="raster-url"
                      label-cols-lg="3"
                      content-cols-lg="9"
                      label="Raster:"
                      label-class="font-weight-bold pt-0"
                      label-for="input-raster"
                      description="Raster URL for this Resource">
          <b-input-group>
            <b-form-input id="input-raster"
                          name="raster"
                          v-model="editedResource && editedResource.url"
                          placeholder="raster.url.com"
                          v-validate="'required|min:5|max:500'"
                          data-vv-as="Raster URL"
                          :class="{ 'is-invalid': errors.has('raster') }"
                          aria-describedby="resource-raster-input-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="resource-raster-input-live-feedback">
              {{ errors.first("raster") }}
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
        <b-form-group v-if="editedResource && editedResource.type === 'vector'"
                      id="resource-vector"
                      label-cols-lg="3"
                      content-cols-lg="9"
                      label="Map Layer:"
                      label-class="font-weight-bold pt-0"
                      label-for="input-vector"
                      description="Map Layer URL (hosted feature layer) for this Resource">
          <b-input-group>
            <b-form-input id="input-vector"
                          name="vector"
                          v-model="editedResource && editedResource.url"
                          placeholder="https://services9.arcgis.com/../US_Drought_Intensity_v1/FeatureServer/3"
                          v-validate="'required|min:5|max:500'"
                          data-vv-as="Vector Map URL"
                          :class="{ 'is-invalid': errors.has('vector') }"
                          aria-describedby="resource-vector-input-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="resource-vector-input-live-feedback">
              {{ errors.first("vector") }}
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
        <b-form-group v-if="editedResource && editedResource.type === 'file'"
                      id="resource-file"
                      label-cols-lg="3"
                      content-cols-lg="9"
                      label="File:"
                      label-class="font-weight-bold pt-0"
                      label-for="input-file"
                      description="Pick a file for this Resource">
          <b-form-file id="input-file"
                       ref="inputFile"
                       v-model="resourceFile"
                       :accept="fileExtensions.toString()"
                       placeholder="Choose a file or drop it here..."
                       drop-placeholder="Drop file here..."
          ></b-form-file>
          <!-- Hidden field used only for file validation purposes -->
          <b-form-input v-show="false"
                        v-if="resourceFile"
                        id="file"
                        name="file"
                        v-model="resourceFile && resourceFile.name"
                        v-validate="'required'"
                        data-vv-as="Resource File"
                        :class="{ 'is-invalid': errors.has('file') }"
                        aria-describedby="resource-file-input-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="resource-file-input-live-feedback">
            {{ errors.first("file") }}
          </b-form-invalid-feedback>
        </b-form-group>
        <div v-if="existingFile" class="mt-3">Current file: {{ existingFile ? existingFile : '' }}</div>
        <div v-if="resourceFile" class="mt-3">Replacement file: {{ resourceFile ? resourceFile.name : '' }}</div>
      </b-card>
      <b-card v-if="editedResource && editedResource.type === 'file'">
        <span>Note: If the document or resource you are uploading is already publicly available elsewhere, consider sharing the link (URL) rather than uploading it here.</span>
      </b-card>
      <b-card v-if="editedResource && editedResource.type === 'vector'">
        <span>
          Note: We let you add hosted feature layers - vector maps - that are hosted on
          ArcGIS.com. These map layers are made visible for your participants on the home
          page for your project on the map. These resources are listed as Map Layers. Your
          project members can toggle these view-only map layers on/off for exploration and context.
          If hosted on ArcGIS.com, and when described with colors for display, we will display these
          map layers on your home page map using the colors described for these maps.
        </span>
      </b-card>
    </div>
    <b-form-checkbox v-if="editedResource && editedResource.type === 'file' && resourceFile"
                     id="checkbox-permission"
                     v-model="permission"
                     name="checkbox-permission"
                     :class="{ shake: animateCheckbox, checkboxError: animateCheckbox }">
      I have permission to upload and share this document.
    </b-form-checkbox>
    <AddTags v-if="editedResource" :resource="this.resourceTags" :read-only=false></AddTags>
    <template slot="modal-footer">
      <b-btn variant="secondary" @click="() => { this.resetModal(); this.$refs.editProjectResource.hide() }">Cancel
      </b-btn>
      <b-btn variant="primary" @click="saveResource" :disabled="isFileUploading || isLoading">
        <b-spinner v-if="isFileUploading || isLoading" small></b-spinner>
        Save
      </b-btn>
    </template>
  </b-modal>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import AddTags from "./tagsAddMultiselect.vue";

export default {
  name: "EditProjectResourceModal",
  components: {AddTags},
  props: {
    resource: {
      type: Object | null,
      required: true,
    }
  },
  data() {
    return {
      editedResource: null,
      existingFile: null,
      resourceFile: null,
      resourceTypeOptions: [
        {text: 'File', value: 'file'},
        {text: 'Link / URL', value: 'link'},
        // {text: 'Raster', value: 'raster'},
        {text: 'Map Layer', value: 'vector'},
      ],
      permission: false,
      animateCheckbox: false,
      isFileUploading: false,
      resourceTags: {tags: []},
    }
  },
  watch: {
    resource(newVal) {
      if (newVal) {
        this.editedResource = {...newVal}
        if (this.editedResource.tags) {
          this.resourceTags = {tags: this.editedResource.tags}
        }
        if (newVal.file) {
          this.existingFile = newVal.file.filename
        }
      }
    },
    resourceFile(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal && oldVal !== null) {
          // Check for image/document extensions
          let bValidExtension = false
          this.fileExtensions.map(ext => ext.slice(1)).forEach((ext) => {
            if (this.resourceFile.type.indexOf(ext) > -1) {
              bValidExtension = true
            }
          })
          if (!bValidExtension) {
            alert('Please select an image or document');
            this.$refs['inputFile'].reset()
            return;
          }
          // Check for image less than 10mb
          if (this.resourceFile.size > 10 * 1000000) {
            alert('Please select a file not exceeding 10mb');
            this.$refs['inputFile'].reset()
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      fileExtensions: "project/images/resourceExtensions",
      project: "project/get/project",
      isLoading: "project/resources/isLoading",
    }),
  },
  methods: {
    ...mapActions({
      editResource: "project/resources/edit",
      uploadFile: "project/images/uploadImage",
    }),
    resetModal() {
      this.existingFile = null
      this.resourceFile = null
      this.permission = false
      this.animateCheckbox = false
      this.isFileUploading = false
    },
    editResourceCall() {
      // Resource edits are saved
      this.isFileUploading = false
      this.editedResource.tags = this.resourceTags.tags
      this.editResource(this.editedResource).then(result => {
        if (result) {
          this.resetModal()
          this.$refs.editProjectResource.hide()
          this.$emit('refresh-table', 'resource-table')
        }
      })
    },
    saveResource() {
      if ((this.editedResource && this.editedResource.type === 'file' && this.resourceFile) && !this.permission) {
        this.animateCheckbox = true
        setTimeout(() => {
          this.animateCheckbox = false
        }, 5000)
        return
      }
      this.$validator.validateAll().then(result => {
        if (result) {
          // Resource to be edited
          // - if URL, manually add https after removing (if exists)
          // File is uploaded and IRI is set
          if (this.editedResource.type === 'file') {
            if (this.resourceFile) {
              this.isFileUploading = true
              this.uploadFile({
                blob: this.resourceFile,
                name: this.resourceFile.name,
                type: 'project_resource'
              }).then(data => {
                this.editedResource.file = data['@id']
                this.editedResource.url = null
                this.editResourceCall()
              })
            } else {
              this.editedResource.file = this.editedResource.file['@id']
              this.editedResource.url = null
              this.editResourceCall()
            }
          } else {
            this.editedResource.file = null
            let userUrl = this.editedResource.url
            // console.log('URL: ', userUrl, userUrl.indexOf('http'))
            // if (this.editedResource.url.indexOf('http') === -1) {
            //   userUrl = 'http://' + this.editedResource.url
            // }
            this.editedResource.url = new URL(userUrl).href
            this.editResourceCall()
          }
        }
      })
    }
  }
}
</script>


<style class="scss" scoped>
.shake {
  animation: shake 0.82s;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.checkboxError {
  color: red;
}
</style>
