import upload from './upload'
import multiupload from './multiupload'
import observationImages from './observationImages'
export default {
  namespaced: true,
  modules: {
    upload,
    multiupload,
    observationImages,
  }
}
