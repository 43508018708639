<template>
  <div class="mb-3">
    <ProjectHeader></ProjectHeader>
    <div class="alert alert-danger" v-if="error">
      {{ error }}
    </div>
    <div class="container pt-3">
      <b-alert variant="success" show v-if="projectVuex && projectVuex.announcement">
        <i class="fa fa-flag mr-2" aria-hidden="true"></i>{{ projectVuex.announcement }}
      </b-alert>
      <b-card no-body header-tag="nav">
        <template #header>
          <ProjectNav></ProjectNav>
        </template>
        <router-view></router-view>
      </b-card>
    </div>
    <ContactProjectManager></ContactProjectManager>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ProjectHeader from "./header/index.vue";
import ProjectNav from "./nav.vue";
import ContactProjectManager from "../ProjectContactManager.vue";

export default {
  components: {
    ProjectHeader,
    ProjectNav,
    ContactProjectManager
  },
  provide() {
    return {
      parentValidator: this.$validator
    };
  },
  created() {
    // Prevent project re-fetch on tab switch
    if (!this.projectVuex || (this.projectVuex.urlField !== this.$route.params.id)) {
      this.getProject(this.$route.params.id);
    }
  },
  computed: {
    ...mapGetters({
      projectVuex: "project/get/project",
      error: "project/get/error"
    })
  },
  methods: {
    ...mapActions({
      getProject: "project/get/get"
    })
  }
};
</script>
<style lang="scss" scoped></style>
