
import { PROJECT_ROLE_MANAGER, PROJECT_ROLE_MEMBER, PROJECT_ROLE_PENDING, PROJECT_ROLE_NONE } from '../constants/project_users_roles'

export const isObserver = (observation) => {
  if(!observation || !observation.observers || observation.observers.length === 0){
    return false;
  }
  const sessionData = JSON.parse(localStorage.getItem('sessionData'))
  if (!sessionData || !sessionData.id){
    return false;
  }
  const observer = observation.observers.find((observer)=>{
    return (sessionData.id === observer.user.id);
  })
  if (observer === undefined){ return false }
  else { return true; }

}

/*****************
 * Get Project Role & isProjectRole functions will be most performant if moved into a getter or computed field....
 */

/**
 * Get the user's effective role in the project
 * @param {string} projectId - current user's membership info
 * @returns {string} - returns role
 */
const getProjectRole = (projectId) => {
  if(!projectId){
    return PROJECT_ROLE_NONE
  }
  //ToDo: store sessionData in VUEX
  const sessionData = JSON.parse(localStorage.getItem('sessionData'))
  if (!sessionData){
    return PROJECT_ROLE_NONE
  }
  const memberships = sessionData.memberships;
  if (!memberships || memberships.length === 0){
    return PROJECT_ROLE_NONE
  }
  const projectMembership = memberships.find((membership)=>{
    return (projectId === membership.projectId);
  })
  if (projectMembership === undefined){return PROJECT_ROLE_NONE}
  if (!projectMembership.approved){return PROJECT_ROLE_PENDING}
  if (projectMembership.role === PROJECT_ROLE_MEMBER){return PROJECT_ROLE_MEMBER}
  if (projectMembership.role === PROJECT_ROLE_MANAGER){return PROJECT_ROLE_MANAGER}
  console.error("getProjectRole error: invalid project role")
  return PROJECT_ROLE_NONE;
}



/**
 * Check if loggedin user is manager of a given project
 * @param {string} projectId - current user's membership info
 * @returns {boolean} - true if user is manager for the project false otherwise
 */
export const isProjectRoleManager = (projectId) => {
  return getProjectRole(projectId) === PROJECT_ROLE_MANAGER
}

/**
 * Check if loggedin user is member of a given project
 * @param {string} projectId - current user's membership info
 * @returns {boolean} - true if user is manager for the project false otherwise
 */
export const isProjectRoleMember = (projectId) => {
  const projectRole = getProjectRole(projectId)
  return projectRole === PROJECT_ROLE_MEMBER ||
    projectRole === PROJECT_ROLE_MANAGER

}
/**
 * Check if loggedin user is pending membership of a given project
 * @param {string} projectId - current user's membership info
 * @returns {boolean} - true if user is manager for the project false otherwise
 */
export const isProjectRolePending = (projectId) => {
  return getProjectRole(projectId) === PROJECT_ROLE_PENDING
}

/**
 * Check if loggedin user is not in a given project
 * @param {string} projectId - current user's membership info
 * @returns {boolean} - true if user is manager for the project false otherwise
 */
export const isProjectRoleNone = (projectId) => {
  return getProjectRole(projectId) === PROJECT_ROLE_NONE
}

/**
 * @function displayRole:
 * @param role
 * @returns {string}
 */
export const displayRole = (role) => {
  if (role === PROJECT_ROLE_MANAGER) {
    return PROJECT_ROLE_MANAGER.charAt(0).toUpperCase() + PROJECT_ROLE_MANAGER.slice(1)
  } else if (role === PROJECT_ROLE_MEMBER) {
    return 'Contributor'
  }
}


/**
 * @function isUserPostedDiscussionOrReply:
 * @param repliedOrStartedDiscussionUserId
 * @returns {boolean} - true if user has started discussion or  posted reply else it will be false
 */
export const isUserPostedDiscussionOrReply = ( repliedOrStartedDiscussionUserId ) => {
  let sessionData = JSON.parse(localStorage.getItem('sessionData'))

  if (!sessionData) {
    return false;
  }
  if( repliedOrStartedDiscussionUserId === sessionData['id'] ) {
    return true;
  }

  return false;
};

/**
 * @function isValidCoordinates
 * @param latitude
 * @param longitude
 * @param item
 * @returns {boolean}
 */

export const isValidCoordinates  = (latitude, longitude, item )  => {

  if (!isNaN(longitude) && !isNaN(latitude)) {

    const latVal = /^-?([0-8]?[0-9]|90)(\.)?[0-9]{1,6}$/;
    const lonVal = /^-?((1?[0-7]?|[0-9]?)[0-9]|180)(\.)?[0-9]{1,6}$/;

    if (latVal.test(latitude) && lonVal.test(longitude)) {

      item.location.longitude = parseFloat(longitude);
      item.location.latitude = parseFloat(latitude);

      return true;
    }
  }
  return false;
};
