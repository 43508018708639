<template>
  <div>
    <!--<h1>our impact</h1>-->
    <section class='intro'>
      <div class='introDescription' data-aos="zoom-in" data-aos-duration="1500" data-aos-delay='250'>
        <div class="container">
          Our impact is made possible
          <br/>
          <p>Because of You</p>
        </div>
      </div>
        <!--<img  data-aos="fade-left" data-aos-delay='1500' class='introImage' src='https://res.cloudinary.com/dsderm9xw/image/upload/v1622028117/3D%20Illustrations/3d-illustration4_usckww.png' alt='3d illustration of boy with a flag'/>-->
    </section>

    <div class="container pb-0" data-aos="fade-up">
        <h2>Our Impact</h2>

        <p>Our <b-link :to="'mission'"> mission</b-link> is to help others do great science by building the best
          available <i><strong>free</strong></i> tools for participatory projects. Most participatory science
          projects are underfunded and many lack funding altogether. The more we reduce technological and financial
          barriers, the better. We believe we can do science better, together.
        </p>

        <p>Communities and scientists use CitSci every day to grow their projects and advance scientific knowledge.</p>

        <p>Below we've highlighted accomplishments of some of the projects that have used CitSci over the years.
          We also want to recognize the many incredible students who've dedicated their time to making CitSci what
          it is today.</p>

        <h3>Consider Donating</h3>

        <p>The tools we provide are free, but the cost of building and maintaining them is not. Keeping our tools free is
          critical to advancing participatory science.</p>

        <p>It's incredible what communities and scientists can do together when they have the right tools for the job.</p>

        <p>Help keep CitSci free by making a <b-link :to="'donate'">donation</b-link> today.</p>

    </div>

    <div class="container pb-0" data-aos="fade-up">
      <h2>Student Success</h2>
      <div class="card-group">
        <div class="card">
          <div class="student-top-img overflow-hidden">
            <img class="h-100" src="/assets/images/staff/Brandon1.png" alt="Brandon Budnicki"/>
          </div>
          <div class="card-body">
            <h4 class="card-title mt-2">Brandon Budnicki</h4>
            <p class="card-text">Brandon is currently a PhD candidate in the Department of Ecosystem Science
              and Sustainability at Colorado State University. Brandon is a full-stack developer for CitSci and came to the team through his
              passion for computer programming that intersects with participatory science. He studying the
              social-technological factors influencing more widespread public participation in science.
            </p>
          </div>
        </div>
        <div class="card">
          <div class="student-top-img overflow-hidden">
            <img class="w-100" src="/assets/images/staff/DaniLinHunter.png" alt="Dani Lin-Hunter"/>
          </div>
          <div class="card-body">
            <h4 class="card-title mt-2">Dani Lin-Hunter</h4>
            <p class="card-text">Dani received an PhD in Ecology from Colorado State University in 2022.
              Dani assisted CitSci
              in many capacities over the years, from social media management to developing and implementing
              participant surveys to advancing justice, equity, diversity, and inclusion resources. Dani's
              PhD investigated how people talk about citizen science. She is currently a post-doc at North
              Carolina State University.
            </p>
          </div>
        </div>
        <div class="card">
          <div class="student-top-img overflow-hidden">
            <img class="w-100" src="/assets/images/staff/RinaHauptfeld.png" alt="Rina Hauptfeld"/>
          </div>
          <div class="card-body">
            <h4 class="card-title mt-2">Rina Hauptfeld</h4>
            <p class="card-text">Rina received a PhD in Ecology, Human Dimensions of Natural Resources, and
              Ecosystem Science and Sustainability from Colorado State University in 2023. Rina's dissertation
              centered on understanding
              the challenges of delegated local ecological monitoring. Rina She is currently a Fisheries
              Social Scientist at the United Nations Food and Agriculture Organization (FAO).
            </p>
          </div>
        </div>
        <div class="card">
          <div class="student-top-img overflow-hidden">
            <img class="w-100" src="/assets/images/staff/SeanGeer1.PNG" alt="Sean Geer"/>
          </div>
          <div class="card-body">
            <h4 class="card-title mt-2">Sean Geer</h4>
            <p class="card-text">Sean received an B.S. in Human Dimensions of Natural Resources from Colorado State
              University in 2023. Sean's internship with CitSci included testing protocols,
              developing student materials for future interns, participating in partner meetings,
              and networking with outside organizations.
              Sean is considering pursuing a master's degree in natural resources next.
              <b-link href="https://blog.citsci.org/2023/04/26/student-spotlight-conservation-planning-and-citizen-science/"
                target="NewWindow">
                Read More
              </b-link>
            </p>
            <!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
          </div>
        </div>
      </div>
    </div>

    <div class="container pb-0" data-aos="fade-up">
      <div class="card-group">
        <div class="card">
          <div class="student-top-img overflow-hidden">
            <img class="h-100" src="/assets/images/staff/meghan-peterson.png" alt="Meghan Peterson"/>
          </div>
          <div class="card-body">
            <h4 class="card-title mt-2">Meghan Peterson</h4>
            <p class="card-text">Meghan is a Master of Social Work and Master of Public Health student in
              the Colorado School of Public Health at Colorado State University. Meghan assisted CitSci
              by developing campus-based resources for the Leave No Trash Project as part of a class group project.
            <b-link href="https://blog.citsci.org/2023/04/26/student-spotlight-do-what-you-can-where-you-are/"
                target="NewWindow">
                Read More
            </b-link>
            </p>
          </div>
        </div>
        <div class="card">
          <div class="student-top-img overflow-hidden">
            <img class="w-100" src="/assets/images/staff/BrianFauver.jpg" alt="Dani Lin-Hunter"/>
          </div>
          <div class="card-body">
            <h4 class="card-title mt-2">Brian Fauver</h4>
            <p class="card-text">Brian received an M.S. in Human Dimensions of Natural Resources from Colorado
              State University in 2016. Brian's
              thesis focused on the economics of citizen science for natural resource managers. Brian is currently
              a NEPA Generalist specializing in Section 4(f), Section 6(f), and Environmental Justice at Colorado
              Department of Transportation.
            <b-link href="https://blog.citsci.org/2020/10/12/how-citizen-science-paved-the-way-for-this-students-career/"
                target="NewWindow">
                Read More
            </b-link>
            </p>
          </div>
        </div>
        <div class="card">
          <div class="student-top-img overflow-hidden">
            <img class="w-100" src="/assets/images/staff/Rachit1.png" alt="Rachit Dalal"/>
          </div>
          <div class="card-body">
            <h4 class="card-title mt-2">Rachit Dalal</h4>
            <p class="card-text">Rachit received an M.S. in Computer Science from Colorado State University in 2021. As
              a graduate assistant with CitSci, Rachit programmed code for the front and back end systems of CitSci while
              CitSci transitioned to an entirely new code base. Rachit is currently a Software Engineer at Goldman Sachs.
            </p>
            <!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
          </div>
        </div>
        <div class="card">
          <div class="student-top-img overflow-hidden">
            <img class="w-100" src="/assets/images/staff/IsabellaHarris.png" alt="Bella Harris"/>
          </div>
          <div class="card-body">
            <h4 class="card-title mt-2">Isabella Harris</h4>
            <p class="card-text">Bella received an M.S. in Environmental Leadership from Colorado State University in 2021.
              Bella volunteered with CitSci as a social media coordinator and content creator, as well as helping organize
              a panel event on participatory science. She is currently a Wyss Fellow and Wildlands Campaigner with the
              Colorado Wildlands Project.
            </p>
            <!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
          </div>
        </div>
      </div>
    </div>

    <div class="container pb-0" data-aos="fade-up">
      <div class="card-group">
        <div class="card">
          <div class="student-top-img overflow-hidden">
            <img class="w-100" src="/assets/images/staff/EllenEisenbach.png" alt="Ellen Eisenbeis"/>
          </div>
          <div class="card-body">
            <h4 class="card-title mt-2">Ellen Eisenbeis</h4>
            <p class="card-text">Ellen received an B.S. in the Department of Ecosystem Science and Sustainability from
              Colorado State University in 2018. As an intern with CitSci, she studied volunteer motivations. She
              currently works for the Center for the New Energy Economy helping advance clean energy alternatives.
            </p>
            <!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
          </div>
        </div>
        <div class="card">
          <div class="student-top-img overflow-hidden">
            <img class="w-100" src="/assets/images/staff/Pramod.png" alt="Pramod Shashidhara"/>
          </div>
          <div class="card-body">
            <h4 class="card-title mt-2">Pramod Shashidhara</h4>
            <p class="card-text">Pramod received an M.S. in Computer Science from Colorado State University in 2013.
              Promod was a software engineer at CitSci while completing his graduate degree. He has since worked for
              many well known companies, including PayPal and Atlassian. He is currently a senior engineering manager
              for Chainlink Labs.
            </p>
          </div>
        </div>
        <div class="card">
          <div class="student-top-img overflow-hidden">
            <img class="w-100" src="/assets/images/staff/DanielleBackman.png" alt="Danielle Backman"/>
          </div>
          <div class="card-body">
            <h4 class="card-title mt-2">Danielle Backman</h4>
            <p class="card-text">Danielle received her M.A in Anthropology from Colorado State University in
              2020. Danielle volunteered with CitSci as a social media coordinator and content creator. She helped
              us investigate GDPR requirements. Danielle is currently a Proposal Manager at EnviroIssues.
            </p>
            <!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
          </div>
        </div>
        <div class="card">
          <div class="student-top-img overflow-hidden">
            <img class="w-100" src="/assets/images/default_avatar.png" alt="Meghan Carp"/>
          </div>
          <div class="card-body">
            <h4 class="card-title mt-2">Meghan Carp</h4>
            <p class="card-text">Megan received her B.S. in Ecosystem Science and Sustainability from Colorado
              State University in 2020. Megan volunteered with CitSci as a social media coordinator and content
              creator. She's been part of a number of organizations over the years, including Women in Wildlands
              and Wildland Restoration Volunteers.
            </p>
            <!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
          </div>
        </div>
      </div>
    </div>

    <div class="container pb-0">
      <h2>Selected Project Impacts</h2>

      <p>Over 15,000 people have used CitSci to participate in 1,200+ participatory science projects since we launched
        in 2007. Here are <i><strong>just some</strong></i> of their impacts and inspiring stories. Maybe someday
        we'll have the time to write about them all!</p>

      <p class="p-down"><i>*Project impacts and stories are listed in order by date established. Many of these projects
        are still active today.</i></p>
    </div>

    <div v-if="impacts2024.length>0" class="container" data-aos="fade-up">
      <h3>2024</h3>
      <div v-for="story2024 in impacts2024" :key="story2024.Title">
        <StoryCard :story="story2024"></StoryCard>
      </div>
    </div>

    <div v-if="impacts2023.length>0" class="container" data-aos="fade-up">
      <h3>2023</h3>
      <div v-for="story2023 in impacts2023" :key="story2023.Title">
        <StoryCard :story="story2023"></StoryCard>
      </div>
    </div>

    <div v-if="impacts2022.length>0" class="container" data-aos="fade-up">
      <h3>2022</h3>
      <div v-for="story2022 in impacts2022" :key="story2022.Title">
        <StoryCard :story="story2022"></StoryCard>
      </div>
    </div>

    <div v-if="impacts2021.length>0" class="container" data-aos="fade-up">
      <h3>2021</h3>
      <div v-for="story2021 in impacts2021" :key="story2021.Title">
        <StoryCard :story="story2021"></StoryCard>
      </div>
    </div>

    <div v-if="impacts2020.length>0" class="container" data-aos="fade-up">
      <h3>2020</h3>
      <div v-for="story2020 in impacts2020" :key="story2020.Title">
        <StoryCard :story="story2020"></StoryCard>
      </div>
    </div>

    <div v-if="impacts2019.length>0" class="container" data-aos="fade-up">
      <h3>2019</h3>
      <div v-for="story2019 in impacts2019" :key="story2019.Title">
        <StoryCard :story="story2019"></StoryCard>
      </div>
    </div>

    <div v-if="impacts2018.length>0" class="container" data-aos="fade-up">
      <h3>2018</h3>
      <div v-for="story2018 in impacts2018" :key="story2018.Title">
        <StoryCard :story="story2018"></StoryCard>
      </div>
    </div>

    <div v-if="impacts2017.length>0" class="container" data-aos="fade-up">
      <h3>2017</h3>
      <div v-for="story2017 in impacts2017" :key="story2017.Title">
        <StoryCard :story="story2017"></StoryCard>
      </div>
    </div>

    <div v-if="impacts2016.length>0" class="container" data-aos="fade-up">
      <h3>2016</h3>
      <div v-for="story2016 in impacts2016" :key="story2016.Title">
        <StoryCard :story="story2016"></StoryCard>
      </div>
    </div>

    <div v-if="impacts2015.length>0" class="container" data-aos="fade-up">
      <h3>2015</h3>
      <div v-for="story2015 in impacts2015" :key="story2015.Title">
        <StoryCard :story="story2015"></StoryCard>
      </div>
    </div>

    <div v-if="impacts2014.length>0" class="container" data-aos="fade-up">
      <h3>2014</h3>
      <div v-for="story2014 in impacts2014" :key="story2014.Title">
        <StoryCard :story="story2014"></StoryCard>
      </div>
    </div>

    <div v-if="impacts2013.length>0" class="container" data-aos="fade-up">
      <h3>2013</h3>
      <div v-for="story2013 in impacts2013" :key="story2013.Title">
        <StoryCard :story="story2013"></StoryCard>
      </div>
    </div>

    <div v-if="impacts2012.length>0" class="container" data-aos="fade-up">
      <h3>2012</h3>
      <div v-for="story2012 in impacts2012" :key="story2012.Title">
        <StoryCard :story="story2012"></StoryCard>
      </div>
    </div>

    <div v-if="impacts2011.length>0" class="container" data-aos="fade-up">
      <h3>2011</h3>
      <div v-for="story2011 in impacts2011" :key="story2011.Title">
        <StoryCard :story="story2011"></StoryCard>
      </div>
    </div>

    <div v-if="impacts2010.length>0" class="container" data-aos="fade-up">
      <h3>2010</h3>
      <div v-for="story2010 in impacts2010" :key="story2010.Title">
        <StoryCard :story="story2010"></StoryCard>
      </div>
    </div>

    <div v-if="impacts2009.length>0" class="container" data-aos="fade-up">
      <h3>2009</h3>
      <div v-for="story2009 in impacts2009" :key="story2009.Title">
        <StoryCard :story="story2009"></StoryCard>
      </div>
    </div>

    <div v-if="impacts2008.length>0" class="container" data-aos="fade-up">
      <h3>2008</h3>
      <div v-for="story2008 in impacts2008" :key="story2008.Title">
        <StoryCard :story="story2008"></StoryCard>
      </div>
    </div>

    <div v-if="impacts2007.length>0" class="container" data-aos="fade-up">
      <h3>2007</h3>
      <div v-for="story2007 in impacts2007" :key="story2007.Title">
        <StoryCard :story="story2007"></StoryCard>
      </div>
    </div>

  </div>
</template>

<!-- Use preprocessors via the lang attribute! e.g. <style lang="scss"> -->
<style>
  .card{
    margin: 12px;
  }

  .student-top-img {
    max-height:240px;
  }

  .intro{
    display:flex;
    background: grey; /* #fff3e2 */
    justify-content: space-evenly; /* space-evenly*/
    padding-top:40px;
    height: 460px;
    background-image: url('/assets/images/impact/9.png');

    /*background-repeat: no-repeat;*/
    background-size: cover;
    /*background-attachment: fixed;
    //background-position: left top;*/
  }
  .introDescription{
    font-size:30px;
    color:#ffffff; /* #ffffff */
    padding-top:120px;
  }
  .introDescription p{
    font-size:60px;
    color:#ffffff; /* #ffffff */
    font-weight: 900;
  }

  /* responsiveness */
  @media screen and (max-width: 500px){
    .intro{
    display:block;
    padding-top:10px;
    height:auto;
    }
    .introImage{
      width:100%;
    }
    .introDescription{
      padding: 30px 0px 0px 20px;
    }
  }
</style>

<script>

  //import 'aos/dist/aos.css'
  import impactStories from "./impactStories.json";
  import StoryCard from "./StoryCard.vue";

  export default {
    components: { StoryCard },
    data() {
      return {
        impactStories: impactStories,
      };
    },
    computed: {
      impacts2024() {
        return this.impactStories.filter(story2024 => story2024.Year === 2024);
      },
      impacts2023() {
        return this.impactStories.filter(story2023 => story2023.Year === 2023);
      },
      impacts2022() {
        return this.impactStories.filter(story2022 => story2022.Year === 2022);
      },
      impacts2021() {
        return this.impactStories.filter(story2021 => story2021.Year === 2021);
      },
      impacts2020() {
        return this.impactStories.filter(story2020 => story2020.Year === 2020);
      },
      impacts2019() {
        return this.impactStories.filter(story2019 => story2019.Year === 2019);
      },
      impacts2018() {
        return this.impactStories.filter(story2018 => story2018.Year === 2018);
      },
      impacts2017() {
        return this.impactStories.filter(story2017 => story2017.Year === 2017);
      },
      impacts2016() {
        return this.impactStories.filter(story2016 => story2016.Year === 2016);
      },
      impacts2015() {
        return this.impactStories.filter(story2015 => story2015.Year === 2015);
      },
      impacts2014() {
        return this.impactStories.filter(story2014 => story2014.Year === 2014);
      },
      impacts2013() {
        return this.impactStories.filter(story2013 => story2013.Year === 2013);
      },
      impacts2012() {
        return this.impactStories.filter(story2012 => story2012.Year === 2012);
      },
      impacts2011() {
        return this.impactStories.filter(story2011 => story2011.Year === 2011);
      },
      impacts2010() {
        return this.impactStories.filter(story2010 => story2010.Year === 2010);
      },
      impacts2009() {
        return this.impactStories.filter(story2009 => story2009.Year === 2009);
      },
      impacts2008() {
        return this.impactStories.filter(story2008 => story2008.Year === 2008);
      },
      impacts2007() {
        return this.impactStories.filter(story2007 => story2007.Year === 2007);
      },
    }
  }

</script>
