<template>
  <b-modal :id="'projectJoinQuestionModal-'+this.projectId"
           ref="ProjectJoinQuestionModal"
           title-html="<b>A few quick questions before you get started</b>"
           size="lg"
           no-close-on-backdrop
           no-close-on-esc
           centered
           ok-title-html="<b>Join Project</b>"
           ok-variant="tertiary"
           @ok="handleSubmit">

    <b-container fluid>
      <b-row>
        <b-alert v-bind:show="error !== null"
                 dismissible
                 variant="danger"
                 @dismissed="resetError">
          {{ error }}
        </b-alert>
      </b-row>

      <b-row>
        <div v-if="selectionError" class="text-danger">
          {{ selectionError }}
        </div>
      </b-row>

      <b-row>
        <b-col lg="12">
          <b-form-group id="q1"
                        label-cols-lg="7"
                        content-cols-lg="4"
                        :label="q1"
                        label-class="font-weight-bold m-0 p-0"
                        label-for="input-q1"
                        placeholder="Pick an option">
            <b-form-select id="input-q1"
                           v-model="q1Selected"
                           :options="q1Options"
                           :disabled="isLoading">
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="12">
          <b-form-group id="q2"
                        label-cols-lg="7"
                        content-cols-lg="4"
                        :label="q2"
                        label-class="font-weight-bold m-0 p-0"
                        label-for="input-q1">
            <b-form-select id="input-q2"
                           v-model="q2Selected"
                           :options="q2Options"
                           :disabled="isLoading">
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>

    <template slot="modal-footer">
      <div v-if="isLoading">
        <font-awesome-icon v-if="isLoading" icon="spinner"/>
        &nbsp;Joining Project...
      </div>
    </template>

  </b-modal>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  props: {
    projectId: {
      required: true,
      type: String,
    },
  },
  components: {},
  data() {
    return {
      q1: 'How did you hear about this project?',
      q1Options: [
        {value: null, text: '-- Pick an option --', disabled: true},
        'From 2FP communication',
        'From SEED communication',
        'From SEED Instagram post',
        'From somewhere else',
      ],
      q1Selected: null,

      q2: 'What is your current opinion of microbes?',
      q2Options: [
        {value: null, text: '-- Pick an option --', disabled: true},
        "Ew, I don't like them",
        'They are great!',
        'Some are great but some are not',
      ],
      q2Selected: null,
      selectionError: null,
    }
  },
  watch: {
    q1Selected(newVal) {
      if (newVal) {
        if (this.isValid && this.selectionError) this.selectionError = null
      }
    },
    q2Selected(newVal) {
      if (newVal) {
        if (this.isValid && this.selectionError) this.selectionError = null
      }
    }
  },
  computed: {
    ...mapGetters({
      error: 'project/joinExtremophile/error',
      isLoading: 'project/joinExtremophile/isLoading',
    }),
    isValid() {
      return this.q1Selected && this.q2Selected
    }
  },
  methods: {
    ...mapActions({
      joinExtremophile: 'project/joinExtremophile/create',
      resetError: 'project/joinExtremophile/reset',
    }),
    handleSubmit(evt) {
      evt.preventDefault()
      // Validation
      if (this.isValid) {
        let answers = {'projectId': this.projectId, 'questionnaire': {[this.q1]: this.q1Selected, [this.q2]: this.q2Selected}}
        this.joinExtremophile(answers).then(data => {
          if (data) this.$refs.ProjectJoinQuestionModal.hide()
        })
      } else {
        this.selectionError = 'Please pick an option for both questions.'
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.custom-title {
  width: 100%;
  text-align: center;
}
</style>
