<template>
  <b-container>
    <h1>Meet Our Team</h1>
    <div style="width:75%;" class="mb-5 mx-auto">
      <p class="p-up">
        Our team is devoted to helping you do great citizen science and community science projects.
        We are passionate about supporting and amplifying the impacts and outcomes of your work.
        We have been fortunate to have had a great team of people help us make CitSci possible,
        many of whom are volunteers (<i class="fas fa-star"></i>) just like you!
      </p>

      <div style="text-align: center;">
        <p class="p-up" style="color: #849A39;">
          <i>to all - past and present - we are grateful</i>
        </p>
      </div>
    </div>

    <h2 class="text-center">Current Staff</h2>
    <div class="container">
      <b-row>
        <b-card-group deck>
          <b-card no-body class="overflow-hidden">
            <b-row no-gutters class="text-left align-items-start" align-v="center">
              <b-col md="4" class="text-center align-items-start" align-v="top">
                <b-img src="/assets/images/staff/StacyLynn.png" class="w-60 ml-4" style="width:60%;" rounded="circle"
                       alt="Circle image"></b-img>
              </b-col>
              <b-col md="8">
                <b-card-body title="Stacy Lynn">
                  <b-card-text>
                    Stacy is our <strong>director of research and education</strong>. She
                    integrates socio-ecological data and learning outcomes.
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>

          <b-card no-body class="overflow-hidden">
            <b-row no-gutters class="text-left align-items-start" align-v="center">
              <b-col md="4" class="text-center align-items-start" align-v="top">
                <b-img src="/assets/images/staff/Sarah_Newman.png" class="w-60 ml-4" style="width:60%;" rounded="circle"
                       alt="Circle image"></b-img>
              </b-col>
              <b-col md="8">
                <b-card-body title="Sarah Newman">
                  <b-card-text>
                    Sarah is our <strong>director of operations</strong>. She engages people
                    in science at local and global scales.
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-card-group>
      </b-row>

      <b-row class="mt-4 text-left" align-v="center">
        <b-card-group deck>
          <b-card no-body class="overflow-hidden">
            <b-row no-gutters class="text-left align-items-start" align-v="center">
              <b-col md="4" class="text-center align-items-start" align-v="top">
                <b-img src="/assets/images/staff/Gregory_Newman.png" class="w-60 ml-4" style="width:60%;"
                       rounded="circle" alt="Circle image"></b-img>
              </b-col>
              <b-col md="8">
                <b-card-body title="Greg Newman">
                  <b-card-text>
                    Greg is <strong>co-founder and director</strong> at CitSci. He helps engage communities,
                    co-develop CitSci, and co-create successful projects.
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>

          <b-card no-body class="overflow-hidden">
            <b-row no-gutters class="text-left align-items-start" align-v="center">
              <b-col md="4" class="text-center align-items-start" align-v="top">
                <b-img src="/assets/images/staff/Guhan_Sivakami.png" class="w-60 ml-4" style="width:60%;"
                       rounded="circle" alt="Circle image"></b-img>
              </b-col>
              <b-col md="8">
                <b-card-body title="Guhan Sivakami">
                  <b-card-text>
                    Guhan is our <strong>database manager</strong>. He manages the CitSci database and helps with
                    full stack development.
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-card-group>
      </b-row>

      <b-row class="mt-4 text-left" align-v="center">
        <b-card-group deck>
          <b-card no-body class="overflow-hidden">
            <b-row no-gutters class="text-left align-items-start" align-v="center">
              <b-col md="4" class="text-center align-items-start" align-v="top">
                <b-img src="/assets/images/staff/BrandonBudnicki.png" class="w-60 ml-4" style="width:60%;"
                       rounded="circle"></b-img>
              </b-col>
              <b-col md="8">
                <b-card-body title="Brandon Budnicki">
                  <b-card-text>
                    Brandon is our lead <strong>full-stack developer</strong>. He turns our community vision for a
                    platform
                    into reality.
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>

          <b-card no-body class="overflow-hidden">
            <b-row no-gutters class="text-left align-items-start" align-v="center">
              <b-col md="4" class="text-center align-items-start" align-v="top">
                <b-img src="/assets/images/staff/Lee.png" class="w-60 ml-4" style="width:60%;" rounded="circle"></b-img>
              </b-col>
              <b-col md="8">
                <b-card-body title="Lee Casuto">
                  <b-card-text>
                    Lee built our first mobile app for iOS and continues to serve as a
                    <strong>consultant</strong> with leeway software designs. (<i class="fas fa-star"></i> volunteer)
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-card-group>
      </b-row>

      <b-row class="mt-4 text-left" align-v="center">
        <b-card-group deck>
          <b-card no-body class="overflow-hidden">
            <b-row no-gutters class="text-left align-items-start" align-v="center">
              <b-col md="4" class="text-center align-items-start" align-v="top">
                <b-img src="/assets/images/staff/CarolineNickerson.png" class="w-80 ml-5 mr-4" style="width:60%;" rounded="circle" alt="Circle image"></b-img>
              </b-col>
              <b-col md="8">
                <b-card-body title="Caroline Nickerson">
                  <b-card-text>
                    Caroline is our <strong>communications lead</strong>. She manages social media, email outreach, and more.
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
          
          <b-card no-body class="overflow-hidden">
            <b-row no-gutters class="text-left align-items-start" align-v="center">
              <b-col md="4" class="text-center align-items-start" align-v="top">
                <b-img src="/assets/images/staff/Hennick.jpg" class="w-60 ml-4" style="width:60%;" rounded="circle"></b-img>
              </b-col>
              <b-col md="8">
                <b-card-body title="Hennick Yibra">
                  <b-card-text>
                    Hennick is our <strong>associate developer</strong>. He works with the development team to accelerate our objectives.
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-card-group>
      </b-row>
    </div>

    <h2 class="text-center">Graduate Students</h2>
    <div class="container">
      <b-row>
        <b-card-group deck>
          <b-card no-body class="overflow-hidden">
            <b-row no-gutters class="text-left align-items-start" align-v="center">
              <b-col md="4" class="text-center align-items-start" align-v="top">
                <b-img src="/assets/images/staff/DaniLinHunter.png" class="w-60 ml-4" style="width:60%;"
                       rounded="circle" alt="Circle image"></b-img>
              </b-col>
              <b-col md="8">
                <b-card-body title="Dani Lin Hunter">
                  <b-card-text>
                    Dani studies how communities talk about citizen science. She is interested in
                    agency and how design influences participation in projects. (<i class="fas fa-star"></i> volunteer)
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>

          <b-card no-body class="overflow-hidden">
            <b-row no-gutters class="text-left align-items-start" align-v="center">
              <b-col md="4" class="text-center align-items-start" align-v="top">
                <b-img src="/assets/images/staff/RinaHauptfeld.png" class="w-60 ml-4" style="width:60%;"
                       rounded="circle" alt="Circle image"></b-img>
              </b-col>
              <b-col md="8">
                <b-card-body title="Rina Hauptfeld">
                  <b-card-text>
                    Rina is interested in community capacity development and how
                    participatory natural resource management builds capacity in projects. (<i class="fas fa-star"></i>
                    volunteer)
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-card-group>
      </b-row>

      <b-row class="mt-4 text-left" align-v="center">
        <b-card-group deck>
          <b-card no-body class="overflow-hidden">
            <b-row no-gutters class="text-left align-items-start" align-v="center">
              <b-col md="4" class="text-center align-items-start" align-v="top">
                <b-img src="/assets/images/staff/Rachit1.png" class="w-60 ml-4" style="width:60%;"
                       rounded="circle"></b-img>
              </b-col>
              <b-col md="8">
                <b-card-body title="Rachit Dalal">
                  <b-card-text>
                    Rachit is in computer science and is
                    interested in Artificial Intelligence and Machine Learning in CitSci. (<i class="fas fa-star"></i>volunteer)
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>

          <b-card no-body class="overflow-hidden">
            <b-row no-gutters class="text-left align-items-start" align-v="center">
              <b-col md="4" class="text-center align-items-start" align-v="top">
                <b-img src="/assets/images/staff/IsabellaHarris.png" class="w-60 ml-4" style="width:60%;"
                       rounded="circle" alt="Circle image"></b-img>
              </b-col>
              <b-col md="8">
                <b-card-body title="Isabella Harris">
                  <b-card-text>
                    Bella is interested in how citizen science facilitates
                    effective virtual environmental participant experiences. (<i class="fas fa-star"></i> volunteer)
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-card-group>
      </b-row>
    </div>

    <h2 class="text-center">Past Team Members</h2>
    <div class="container">
      <b-row>
        <b-card-group deck>
          <b-card no-body class="overflow-hidden">
            <b-row no-gutters class="text-left align-items-start" align-v="center">
              <b-col md="4" class="text-center align-items-start" align-v="top">
                <b-img src="/assets/images/staff/Russell_Scarpino.png" class="w-60 ml-4" style="width:60%;"
                       rounded="circle" alt="Circle image"></b-img>
              </b-col>
              <b-col md="8">
                <b-card-body title="Russell Scarpino">
                  <b-card-text>
                    Russell was previously lead developer at CitSci.
                    He helped build and launch CitSci 2.0 and is a contractor for NOAA.
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>

          <b-card no-body class="overflow-hidden">
            <b-row no-gutters class="text-left align-items-start" align-v="center">
              <b-col md="4" class="text-center align-items-start" align-v="top">
                <b-img src="/assets/images/staff/AlyciaCrall.png" class="w-60 ml-4" style="width:60%;"
                       rounded="circle"></b-img>
              </b-col>
              <b-col md="8">
                <b-card-body title="Alycia Crall">
                  <b-card-text>
                    Alycia is a co-founder and visionary for CitSci. She led initial proposal
                    development and is now working at Battel on NEON.
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-card-group>
      </b-row>

      <b-row class="mt-4 text-left" align-v="center">
        <b-card-group deck>
          <b-card no-body class="overflow-hidden">
            <b-row no-gutters class="text-left align-items-start" align-v="center">
              <b-col md="4" class="text-center align-items-start" align-v="top">
                <b-img src="/assets/images/staff/DanielleBackman.png" class="w-60 ml-4" style="width:60%;"
                       rounded="circle"></b-img>
              </b-col>
              <b-col md="8">
                <b-card-body title="Danielle Backman">
                  <b-card-text>
                    Danielle served as our communications lead and was instrumental in helping us
                    work towards GDPR compliance.
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>

          <b-card no-body class="overflow-hidden">
            <b-row no-gutters class="text-left align-items-start" align-v="center">
              <b-col md="4" class="text-center align-items-start" align-v="top">
                <b-img src="/assets/images/staff/Manoj.png" class="w-60 ml-4" style="width:60%;" rounded="circle"
                       alt="Circle image"></b-img>
              </b-col>
              <b-col md="8">
                <b-card-body title="Manoj Sreekumar">
                  <b-card-text>
                    Manoj built our first Android app and worked with Lee on our apps.
                    He is a software engineer at Markit.
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-card-group>
      </b-row>

      <b-row class="mt-4 text-left" align-v="center">
        <b-card-group deck>
          <b-card no-body class="overflow-hidden">
            <b-row no-gutters class="text-left align-items-start" align-v="center">
              <b-col md="4" class="text-center align-items-start" align-v="top">
                <b-img src="/assets/images/staff/JimGraham.png" class="w-60 ml-4" style="width:60%;"
                       rounded="circle"></b-img>
              </b-col>
              <b-col md="8">
                <b-card-body title="Jim Graham">
                  <b-card-text>
                    Jim is a co-founder of CitSci and now is Assistant Professor at Humboldt State University
                    where he teaches GIS.
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>

          <b-card no-body class="overflow-hidden">
            <b-row no-gutters class="text-left align-items-start" align-v="center">
              <b-col md="4" class="text-center align-items-start" align-v="top">
                <b-img src="/assets/images/staff/Pramod.png" class="w-60 ml-4" style="width:60%;" rounded="circle"
                       alt="Circle image"></b-img>
              </b-col>
              <b-col md="8">
                <b-card-body title="Pramod Shashidhara">
                  <b-card-text>
                    Pramod was a software engineer at CitSci and now works as senior software engineer
                    at Atlassian.
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-card-group>
      </b-row>

      <b-row class="mt-4 text-left" align-v="center">
        <b-card-group deck>
          <b-card no-body class="overflow-hidden">
            <b-row no-gutters class="text-left align-items-start" align-v="center">
              <b-col md="4" class="text-center align-items-start" align-v="top">
                <b-img src="/assets/images/staff/BrianFauver.png" class="w-60 ml-4" style="width:60%;"
                       rounded="circle"></b-img>
              </b-col>
              <b-col md="8">
                <b-card-body title="Brian Fauver">
                  <b-card-text>
                    Brian was a graduate student at CitSci. He now woks at the Colorado
                    Department of Transportation.
                    <b-link
                      href="https://blog.citsci.org/2020/10/12/how-citizen-science-paved-the-way-for-this-students-career/"
                      target="NewWindow">Learn more
                    </b-link>
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>

          <b-card no-body class="overflow-hidden">
            <b-row no-gutters class="text-left align-items-start" align-v="center">
              <b-col md="4" class="text-center align-items-start" align-v="top">
                <b-img src="/assets/images/staff/EllenEisenbach.png" class="w-60 ml-4" style="width:60%;"
                       rounded="circle" alt="Circle image"></b-img>
              </b-col>
              <b-col md="8">
                <b-card-body title="Ellen Eisenbeis">
                  <b-card-text>
                    Ellen worked at CitSci as a ESS SUPER student. She helped us
                    better understand how project traits impact data usability.
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-card-group>
      </b-row>
    </div>

    <div class="mt-4">
      <p>
        Many other students at Colorado State University have helped make CitSci
        possible. These students have put enormous energy into making CitSci a
        success. To all - Danielle Bachman, Nick Young, Michelle Lee, and many
        others - we are grateful. Finally, we express our heartfelt gratitude to Dr.
        Tom Stohlgren who mentored our co-founders Drs. Newman, Graham, and Crall
        and who provided a lab and research team flexible enough to entertain citizen
        science and embrace its contributions to invasive species ecology.
      </p>
    </div>
    <VueLoader v-if="isLoading"/>
  </b-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import VueLoader from '../layout/Loader.vue'

export default {
  created() {

  },

  computed: {
    ...mapGetters({
      isLoading: 'staff/show/isLoading'
    })
  },

  methods: {
    ...mapActions({
      preparePage: 'staff/show/preparePage'
    })
  },

  components: {
    VueLoader
  }
}

</script>

<style lang="scss" scoped>

</style>
