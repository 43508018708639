<template>
  <div >
  <h4 class="title">
    Image(s)
  </h4>
  <div>Allows a volunteer to choose one or more images. <b>The maxium file size per image is 10mb.</b></div>
  <v-text-field
    class="my-2 p-0 outlined-text-field d-block"
    v-if="record.recordType !== 'organism'"
    v-model="record.label"
    placeholder="Enter your question"
    single-line
    outline
  >
    <template slot="prepend">Label</template>
  </v-text-field>

  <v-textarea
    v-if="record.recordType !== 'organism'"
    class="my-2 p-0 outlined-textarea-field d-block"
    v-model="record.description"
    placeholder="Provide a hint on how to answer this question"
    outline
    no-resize
    rows="2"
  >
    <template slot="prepend">Hint</template>
  </v-textarea>

  <div class="h5">Validation</div>

  <div class="">
    <div class="form-label font-weight-bold">Is this question required?</div>
      <b-form-radio v-model="isRequired" :value="false">No, observations can be submitted without an image</b-form-radio>
      <b-form-radio v-model="isRequired" :value="true">Yes, at least one image is required to submit an observation</b-form-radio>
  </div>

    <div class="mt-2">
      <div class="form-label font-weight-bold">Maximum Images: <span class='font-weight-bolder'>{{max}}</span></div>
      <div class=""> What is the most images that can be submitted? Slide to change number. Default value is 1 image. <span class='font-weight-bold' v-if="triedMax" >Contact CitSci if you need more than 8 images.</span></div>
      <div class='d-flex justify-content-between'>
        <div class="flex-shrink-1 flex-none pt-3 pr-3">
          <button class='btn btn-sm btn-secondary' @click="max = max -1"><i class="fas fa-minus"></i></button>
        </div>
        <div>
          <div class="d-flex flex-row  justify-content-between">
        <div  class="flex-shrink-1 flex-none">1</div>
        <div  class="flex-shrink-1 flex-none">8</div>
      </div>
      <b-form-input id="maxImages" class='range-slider' type="range" v-model="max" min="1" max="8" step="1"></b-form-input>  
    </div>
    <div class="flex-shrink-1 flex-none pt-3 pl-3">
          <button class='btn btn-sm btn-secondary' @click="max = max +1"><i class="fas fa-plus"></i></button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

export default {
  props: {
    record: {
      type: Object,
      required: true
    },
  },

  data() {
    // use existing validator IDs from DB if present
    // save IDs if they already exist on server
    const requiredValidator = this.record.validators.find((v) => {
          return v.validatorType === "isRequired";
        });
    const minValidator = this.record.validators.find((v) => {
          return v.validatorType === "min";
        });
    const maxValidator = this.record.validators.find((v) => {
      return v.validatorType === "max";
    });
    const requiredId = (requiredValidator && requiredValidator.id) ? requiredValidator['@id'] : null;
    const minId = (minValidator && minValidator.id) ? minValidator['@id'] : null;
    const maxId = (maxValidator && maxValidator.id) ? maxValidator['@id'] : null;
    return {
      requiredId: requiredId,
      minId: minId,
      maxId: maxId,
      triedMax: false,
    };
  },
  created() {
    // the max of one is implicit for existing image datasheet records, before multiple image were allowed
      if(!this.record.validators.some((v) => {
        return v.validatorType === "max";
      })){
        this.record.validators.push({
          validatorType: "max",
          value: 1,
        });
      }
      this.$emit("updateRecord", this.record);
  },
  computed: {
    isRequired: {
      get() {
        const isRequired = this.record.validators.some((v) => {
          return v.validatorType === "isRequired";
        });
        return isRequired;
      },
      set(value) {
        /// gather other validators
        const otherValidators = this.record.validators.filter((v) => {
          return v.validatorType !== "isRequired";
        });
        // remove validator entirely
        if(value === false){
          this.record.validators = otherValidators;
          this.$emit("updateRecord", this.record);
          return;
        }
        let requiredValidator = {
              validatorType: "isRequired",
              value: value,
            }
        if(this.requiredId){
          requiredValidator['@id']= this.requiredId
        }
        this.record.validators = [...otherValidators,requiredValidator];
        this.$emit("updateRecord", this.record);
      },
    },
    min: {
      get() {
        const validator = this.record.validators.find((v) => {
          return v.validatorType === "min";
        });
        return validator ? validator.value : "";
      },
      set(value) {
        /// gather other validators
        const otherValidators = this.record.validators.filter((v) => {
          return v.validatorType !== "min";
        });
        // remove validator entirely
        if(!Number.isInteger(value)){
          this.record.validators = otherValidators;
          this.$emit("updateRecord", this.record);
          return;
        }
        let minValidator = {
              validatorType: "min",
              value: value,
            }
        if(this.minId){
          minValidator['@id'] = this.minId
        }
        this.record.validators = [...otherValidators,minValidator];
        this.$emit("updateRecord", this.record);
      },
    },
    max: {
      get() {
        const validator = this.record.validators.find((v) => {
          return v.validatorType === "max";
        });
        return validator ? validator.value : "";
      },
      set(value) {
        /// gather other validators
       const otherValidators = this.record.validators.filter((v) => {
          return v.validatorType !== "max";
        });
        // remove validator entirely
        
        if(!Number.isInteger(parseInt(value))){
          console.log('not number')
          this.record.validators = otherValidators;
          this.$emit("updateRecord", this.record);
          return;
        }
        if(value < 1){
          value = 1;
        }
        if(value > 8){
          value = 8;
          this.triedMax = true;
        }
        let maxValidator = {
              validatorType: "max",
              value: value,
            }
        if(this.maxId){
          maxValidator['@id'] = this.maxId
        }
        this.record.validators = [...otherValidators,maxValidator];
        this.$emit("updateRecord", this.record);
      },
    },
  },
};
</script>
<style scoped>

</style>
