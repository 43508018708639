<template>
  <div v-if="projectVuex">
    <div v-if="isPublicOrMember" class="card-body">
      <div class="row mb-3">
        <div class="col-md-6 h3 mb-0">Forum Discussion</div>
      </div>
      <router-link :to="{name:'ProjectForum', params: { id: this.projectVuex.urlField }, replace: true }">
        <i class="fa fa-arrow-left mr-1"></i>Back To Forum
      </router-link>
      <div v-if="isLoading" style="min-height:200px;">
        <i class="fa fa-spin fa-spinner fa-3x mr-3"></i> Loading Forum Post's...
      </div>
      <div v-else>
        <div v-if="error"><span>You are not authorized to view this Forum Post</span></div>
        <div v-else-if="!isLoading && discussionLocal">
        <div class="mt-2">
          <div class="row">
            <div class="col">
              <Parent :post="discussionLocal"></Parent>
            </div>
          </div>
        </div>

        <div v-if="discussionLocal && discussionLocal.repliedPosts.length === 0 && !discussionLocal.isHidden">
          <p>Be the first to reply</p>
        </div>
        <div v-if="discussionLocal && discussionLocal.repliedPosts.length > 0"
             v-for="post in discussionLocal.repliedPosts" :key="post.id" class="ml-3">
          <Reply :post="post" :highlight="post.id === reply" @deleted-reply="deletedReply"></Reply>
        </div>
        <AddReply v-if="!discussionLocal.isHidden"
                  class="ml-3"
                  :discussionId="discussionLocal.id"
                  :projectId="projectId"
                  @replied='addReply'>
        </AddReply>
        </div>
      </div>
    </div>
    <RequiresMember v-else page="Forum Post"></RequiresMember>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import RequiresMember from "../../tabpermission/requiresMember.vue";
import Parent from "./parent.vue";
import Reply from "./reply.vue";
import AddReply from "../forumPostAddReply.vue";

export default {
  name: "index",
  components: {
    RequiresMember,
    Parent,
    Reply,
    AddReply,
  },
  props: {
    post: {
      required: true,
    },
    reply: {
      required: false,
    },
  },
  data() {
    return {
      discussionLocal: null,
    }
  },
  created() {
    if (this.projectVuex) {
      this.loadDiscussion()
    }
  },

  watch: {
    projectVuex(newVal) {
      if (newVal) {
        this.loadDiscussion()
      }
    },
    discussion(newVal) {
      if (newVal)
        this.discussionLocal = newVal
    }
  },

  computed: {
    ...mapGetters({
      projectVuex: "project/get/project",
      projectId: "project/get/id",
      isPublicOrMember: "project/get/isPublicOrMember",
      isMember: "project/get/isMember",

      isLoading: "project/forum/isLoading",
      discussion: "project/forum/singlePost",
      error: "project/forum/error",
    }),
  },

  methods: {
    ...mapActions({
      getSingleDiscussion: "project/forum/getSingle",
    }),
    loadDiscussion() {
      this.getSingleDiscussion({id: this.post})
    },
    addReply(newReply) {
      this.discussionLocal.repliedPosts.push(newReply)
    },
    deletedReply(id) {
      // Deleted reply is removed from array
      let deletedReplyIndex = -1
      let count = 0
      this.discussionLocal.repliedPosts.forEach(reply => {
        if (reply.id === id) {
          deletedReplyIndex = count
        }
        count += 1
      })
      this.discussionLocal.repliedPosts.splice(deletedReplyIndex, 1)
    },
  }
};
</script>

<style lang="scss" scoped>

</style>
