<template>
  <div v-if="!isLoading" class="">
    <div v-if="indicators.length > 0" class="row justify-content-center mb-2">
      <h3>Indicators</h3>
      <b-btn class="justify-end ml-3"
             variant="secondary"
             size="sm"
             alt="Reload Indicators" title="Reload Indicators"
             @click="getScorecardsData">
        <font-awesome-icon icon="sync"/>
      </b-btn>
    </div>
    <div v-if="indicators.length > 0">
      <div class="row text-center align-items-center justify-content-center">
        <div v-for="score in indicators" :key="score.id" class="col-lg-2">
          <div class="row">
            <div class="col-12">
              <p class="m-0 p-0">{{ score.title }}</p>
              <Donut :score-id="score.id"
                     :score-name="score.title"
                     :score-value="score.value"
                     :score-data="score.data"
                     @view="handleViewDonut"></Donut>
            </div>
          </div>
          <b-row class="text-center" align-v="center">
            <div class="col-12">
              <h6 v-if="score['error']" class="mt-1">{{ score.error }}</h6>
              <h5 v-else class="mt-1">{{ score.label + ': ' + score.value }}</h5>
              <font-awesome-icon id="score-info"
                                 icon="question-circle"/>
            </div>
          </b-row>
        </div>
      </div>
    </div>
    <ViewIndicatorModal :scorecard-data="viewScorecardData"></ViewIndicatorModal>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import Donut from "./Donut.vue";
import ViewIndicatorModal from "./ViewIndicatorModal.vue";

export default {
  components: {
    Donut,
    ViewIndicatorModal
  },
  data() {
    return {
      viewScorecardId: null,
    }
  },
  computed: {
    ...mapGetters({
      organizationVuex: "organization/get/organization",
      isLoading: "organization/scorecards/isLoading",
      scorecards: "organization/scorecards/scorecards",
      indicators: "organization/scorecards/indicators",
    }),
    viewScorecardData() {
      if (this.viewScorecardId && this.scorecards && this.indicators.length > 0) {
        let score = this.scorecards.find(s => s.id === this.viewScorecardId)
        let indicator = this.indicators.find(i => i.id === this.viewScorecardId)
        return {
          title: score.title,
          desc: score.description,
          project: score.datasheetRecord.datasheet.project.name,
          datasheet: score.datasheetRecord.datasheet.name,
          record: score.datasheetRecord.label,
          dataType: score.dataType,
          data: indicator.data,
          label: indicator.label,
          value: indicator.value,
          error: indicator.error,
        }
      } else {
        return null
      }
    },
  },
  methods: {
    ...mapActions({
      getScorecardsData: "organization/scorecards/get",
    }),
    handleViewDonut(id) {
      this.viewScorecardId = id
      this.$bvModal.show('view-scorecard')
    }
  }
};
</script>
