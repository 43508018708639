<template>
  <RecordOrganism v-if="record.recordType === 'organism'" :record="record"></RecordOrganism>
  <div v-else class="row observation-record-item border-bottom pb-2">
    <div class="col-12 pt-1" v-if="
        [
          'textarea',
          'text',
          'dropdown',
          'radio',
          'datetime',
          'multiple_choice',
        ].includes(record.recordType)
      ">
      <div class="font-weight-bold text-muted">
        {{ record.label }} <span v-if="false" class="text-danger">&#42;</span>
      </div>
      <div v-if="record.description">
        {{ record.description }}
      </div>
      <div v-if="!record.datasheetRecord || !record.datasheetRecord.isActive">
        <i class="fa fa-trash text-warning"></i>
        This question has been removed from the current datasheet
      </div>
      <div v-if="typeOriginal !== record.recordType">
        <i class="fa fa-warning text-danger"></i>
        This field has been modified from {{ typeOriginal }} to
        {{ record.recordType }} as the options were deleted
      </div>
      <div v-if="
          record.datasheetRecord &&
          record.datasheetRecord.label !== record.label
        ">
        <i class="fa fa-tag text-warning"></i>
        When this observation was created, the question was "{{
          record.label
        }}". Since then a project manager has modified the question to "{{
          record.datasheetRecord.label
        }}". Answer the Original question if possible.
      </div>
      <div v-if="
          record.datasheetRecord &&
          record.datasheetRecord.description !== record.description
        ">
        <i class="fa fa-align-left text-warning"></i>
        When this observation was created, the question description was "{{
          record.description
        }}". Since then a project manager has modified the question description
        to "{{ record.datasheetRecord.description }}"
      </div>
    </div>
    <div class="col-12 font-weight-bold pb-1" v-if="['textarea', 'text'].includes(record.recordType)">
      <b-form-input :id="`record-${record.id}`" v-model="record.value" type="text"></b-form-input>
      <div v-if="record.value !== valueOriginal" class="mt-1">
        Original value: {{ valueOriginal }}
        <button class="btn btn-sm py-0 btn-warning" @click="onUndoChange()" title="Undo changes to this data">
          <i class="fa fa-undo fa-sm"></i>
        </button>
      </div>
    </div>

    <RecordNumber v-if="['number'].includes(record.recordType)" :record="record"></RecordNumber>

    <div class="col-12 font-weight-bold pb-1" v-if="['dropdown'].includes(record.recordType)">
      <div v-if="record.value !== record.optionValue.value">
        <i class="fa fa-tag text-warning"></i>
        The datasheet record dropdown choice that was selected has been changed.
        The observation has a value of: "{{ record.value }}" since then a
        project manager has modified the dropdown value to "{{
          record.optionValue.value
        }}"
      </div>

      <b-form-select :id="`record-${record.id}`" v-model="record.optionValue['@id']"
                     :options="record.datasheetRecord.optionValues" :aria-describedby="`record-${record.id}`"
                     name="radio-options"></b-form-select>
      <div v-if="record.optionValue['@id'] !== optionOriginal['@id']" class="mt-1">
        Original value: {{ optionOriginal.value }}
        <button class="btn btn-sm py-0 btn-warning" @click="onUndoChange()">
          <i class="fa fa-undo fa-sm"></i>
        </button>
      </div>
    </div>

    <div class="col-12 pb-1" v-if="['multiple_choice'].includes(record.recordType)">
      <div v-if="!multiChoiceOptionsChanged">
        <i class="fa fa-tag text-warning"></i>
        The datasheet record dropdown - multiselect options that were selected have been changed.
        The observation has a value of: "{{ record.value }}" since then a
        project manager has modified the values to "{{
          multiSelectValues().join(', ')
        }}"
      </div>

      <v-select item-text="value"
                item-value="id"
                v-model="record.multiSelectOptionValues"
                :items="getMultiSelectOptionValues"
                color="rgba(50, 60, 71, .7)"
                persistent-hint
                label="- Please Select -"
                single-line
                outline
                multiple>
      </v-select>
      <div v-if="JSON.stringify(getCurrentMultiSelectOptionValues) !== JSON.stringify(multiOptionsOriginal.iri)"
           class="mt-1">
        Original value: {{ multiOptionsOriginal.value }}
        <button class="btn btn-sm py-0 btn-warning" @click="onUndoChange()">
          <i class="fa fa-undo fa-sm"></i>
        </button>
      </div>
    </div>

    <div class="col-12 font-weight-bold pb-1" v-if="['radio'].includes(record.recordType)">
      <b-form-radio-group :id="`record-${record.id}`" v-model="record.optionValue['@id']"
                          :options="record.datasheetRecord.optionValues" :aria-describedby="`record-${record.id}`"
                          name="radio-options"></b-form-radio-group>
      <div v-if="record.optionValue['@id'] !== optionOriginal['@id']" class="mt-1">
        Original value: {{ optionOriginal.value }}
        <button class="btn btn-sm py-0 btn-warning" @click="onUndoChange()">
          <i class="fa fa-undo fa-sm"></i>
        </button>
      </div>
    </div>
    <div class="col-12 font-weight-bold pb-1" v-if="['datetime'].includes(record.recordType)">
      <date-picker :id="`record-${record.id}`"
                   :class="[{ 'form-input': true, 'is-invalid': errors.has(record['@id']) }]"
                   placeholder="YYYY/MM/DD h:mm:ss A" v-model="record.value" :config="datetimePickerOptions"/>
      <div v-if="record.value !== valueOriginal" class="mt-1">
        Original value: {{ recordDate }}
        <button class="btn btn-sm py-0 btn-warning" @click="onUndoChange()">
          <i class="fa fa-undo fa-sm"></i>
        </button>
      </div>
    </div>
    <div class="col-12 py-2 font-weight-bold bg-dark" v-if="record.recordType === 'image'">
      <div class="text-white font-weight-bold">
        {{ record.label }} <span v-if="false" class="text-danger">&#42;</span>
      </div>
      <div class="text-white" v-if="!record.datasheetRecord || !record.datasheetRecord.isActive">
        <i class="fa fa-trash text-warning"></i>
        This question has been removed from the current datasheet
      </div>

      <div class="text-white" v-if="
          record.datasheetRecord &&
          record.datasheetRecord.label !== record.label
        ">
        <i class="fa fa-tag text-warning"></i>
        When this observation was created, the question was "{{
          record.label
        }}". Since then a project manager has modified the question to "{{
          record.datasheetRecord.label
        }}". Answer the Original question if possible.
      </div>
      <div class="text-white" v-if="
          record.datasheetRecord &&
          record.datasheetRecord.description !== record.description
        ">
        <i class="fa fa-align-left text-warning"></i>
        When this observation was created, the question description was "{{
          record.description
        }}". Since then a project manager has modified the question description
        to "{{ record.datasheetRecord.description }}"
      </div>
      <div class="text-white">
        <i class="fa fa-image text-warning"></i>
        Editing images by removing & replacing them is not supported at this time.
      </div>
      <b-img v-for="file in record.files" :key="file.id" fluid thumbnail style='max-height: 400px;' :src="file.path" left alt="`${record.label}. ${record.description}`" />
      <small class="text-white" v-if="record.description">{{ record.description }}
      </small>
    </div>
    <div class="col-12 font-weight-bold pb-1" v-if="['location'].includes(record.recordType)">
      <RecordLocation v-model="record.value"></RecordLocation>
    </div>
  </div>
</template>

<script>
import RecordOrganism from "./RecordOrganism.vue";
import RecordLocation from "./RecordLocation.vue";
import RecordNumber from "./RecordNumber.vue";
import DatePicker from "vue-bootstrap-datetimepicker";

export default {
  name: "observation-record-item-view",
  components: {
    RecordOrganism,
    DatePicker,
    RecordLocation,
    RecordNumber
  },
  data() {
    return {
      valueOriginal: this.record.value,
      typeOriginal: this.record.recordType,
      optionOriginal: {
        ...this.record.optionValue
      },
      multiSelectOriginal: null,
      multiOptionsOriginal: {
        value: null,
        iri: null,
      },
      datetimePickerOptions: {
        allowInputToggle: true,
        format: "YYYY/MM/DD h:mm:ss A",
        showClose: true,
        useStrict: true,
      },
    };
  },
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  created() {
    if (!this.record.datasheetRecord && ["radio", "dropdown", "mutliple_choice"].includes(this.record.recordType)) {
      return (this.record.recordType = "text");
    }
    if (this.record.recordType === 'multiple_choice') {
      this.saveOriginalOptions()
      this.record.multiSelectOptionValues = this.record.multiSelectOptionValues.map(val => {
        return {id: val['@id']}
      })
    }
  },
  watch: {
    record(newVal) {
      if (newVal) {
        if (newVal.recordType === 'multiple_choice') {
          this.saveOriginalOptions()
          newVal.multiSelectOptionValues = newVal.multiSelectOptionValues.map(val => {
            return {id: val['@id']}
          })
        }
      }
    }
  },
  computed: {
    recordDate() {
      return moment(this.valueOriginal.split("+")[0]).format(
        this.datetimePickerOptions.format
      );
    },
    multiChoiceOptionsChanged() {
      // Check if any of the selections have changed compared to the datasheet record option values
      let bRet = true
      let currentValues = this.record.value.split(",").map(val => {
        return val.trim()
      })
      let currentOptions = this.getMultiSelectOptionValues.map(opt => {
        return opt.value
      })
      // console.log(currentValues, currentOptions)
      currentValues.forEach(v => {
        if (currentOptions.includes(v)) {
        } else {
          bRet = bRet && false
        }
      })
      return bRet
    },
    getMultiSelectOptionValues() {
      return this.record.datasheetRecord.optionValues.map(val => {
        return {id: val.value, value: val.text}
      })
    },
    getCurrentMultiSelectOptionValues() {
      if (this.record.multiSelectOptionValues.some(obj => obj.hasOwnProperty('id'))) {
        return this.record.multiSelectOptionValues.map(val => val.id).sort()
      } else {
        return this.record.multiSelectOptionValues.sort()
      }
    },
  },
  methods: {

    numberInc() {
      const num = Number(this.record.value) + 1
      this.record.value = num.toString()
    },
    numberDec() {
      const num = Number(this.record.value) - 1
      this.record.value = num.toString()
    },

    saveOriginalOptions() {
      this.multiSelectOriginal = this.record.multiSelectOptionValues
      this.multiOptionsOriginal.value = this.record.multiSelectOptionValues.map(val => val.value).sort()
      this.multiOptionsOriginal.iri = this.record.multiSelectOptionValues.map(val => val['@id']).sort()
    },

    multiSelectValues() {
      return this.record.datasheetRecord.optionValues.map(val => val.text).sort()
    },

    onUndoChange() {
      if (["textarea", "text", "number", "datetime"].includes(this.record.recordType)) {
        this.record.value = this.valueOriginal;
      } else if (["radio", "dropdown"].includes(this.record.recordType)) {
        this.record.optionValue['@id'] = this.optionOriginal['@id'];
      } else if (["multiple_choice"].includes(this.record.recordType)) {
        this.record.multiSelectOptionValues = this.multiOptionsOriginal.iri
      }
    },
  },
};
</script>
